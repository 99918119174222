import './MetaverseItem.scss';
import cn from "classnames";

export default function MetaverseItem({item}) {

    return (
        <div className={cn('roadmap-item', { 'roadmap-item_active': item.active})}>
            <div className="roadmap-item__inner">
                <div className="roadmap-item__stage">{item.stage}</div>
                <div className="roadmap-item__title">{item.title}</div>
                {item.paragraph && <p className="roadmap-item__text">{item.paragraph}</p>}
            </div>
        </div>
    );
}