export const activeAugust = {
    name: 'activeAugust',
    type: 'blog',
    date: 'SEPT 2/2022',
    time: '6 min',
    authorAvatar: 'ann.png',
    authorName: 'Ann',
    authorPost: 'UX/UI designer',
    mainImage: 'devblog_post1.jpg',
    title: 'Active August - news on the development of the Raritygram platform and the metaverse, as well as NFTRarity Mint',
    shortDesc: 'Raritygram updates for August 2022: the beta version of the platform is available to every user. Latest NFTRarity Mint updates. News of the metaverse.',
    content: [
        {
            video: 'https://www.youtube.com/embed/57__4luI05Y'
        },
        {
            h2: 'Mint up to 20 NFTRarity at a time and other news about the NFTRarity mint',
            p: 'We continue to improve the capabilities of NFTRarity mint. Now each user can mint NFTRarity from both mobile and desktop devices and become the owner of up to 20 NFTs at a time!'
        },
        {
            img: 'raritygram-gradient.jpg',
            p: 'A new version of the mint report has also been added - its participants can not only see how many NFTs are still available, but also get information about the remaining BNB. Viewing those NFTs that have already been minted has become available to every user of the Raritygram platform - you can check it right now.'
        },
        {
            h3: 'Take advantage of the beta version of Raritygram - now it is available to every user',
            p: 'The new features of the beta version of Raritygram allow each user to create their own personal account through Metamask and even customize it.'
        },
        {
            p: 'You can get authorized in just 2 simple steps:',
            ul: [
                <>Choose a wallet</>,
                <>Click the "Connect wallet" button</>,
            ]
        },
        {
            p: 'You are wonderful!',
        },
        {
            p: <>
                Now you can go to your personal account and start editing your profile. Come up with a nickname, choose an avatar, change your profile header and use other features of the beta version of Raritygram! For example, you can switch your feed tabs from "My profile" to "My NFTs"<br/><br/>
                By the way, those NFTRaritys that you managed to mint are already available in the “My NFTs” section.
            </>,
        },
        {
            h3: 'Mint up to 20 NFTRarity at a time and other news about the NFTRarity mint',
            p: 'We continue to improve the capabilities of NFTRarity mint. Now each user can mint NFTRarity from both mobile and desktop devices and become the owner of up to 20 NFTs at a time!'
        },
        {
            h3: 'Metaverse News:Better Quality and New Objects',
            p: 'What updates are already waiting for you in the Raritygram metaverse? We have created a new Nadya avatar. At the same time, the quality of avatars has improved significantly - the graphics, hair and integrity of the character have become even closer to reality.'
        },
        {
            img: 'active-august/nadya.jpg',
            p: <>
                The new facility is the Raritygram Museum. Here, each user will be able to place their NFTs. And also you can go to the Museum as a regular visitor to admire the art objects that are already there.<br/><br/>
                Another object added to the Raritygram metaverse is our updated studio! Now it is bigger, wider, better and is almost an exact copy of the place where we implement our creative ideas.
            </>
        }
    ]
};