import './Socials.scss';
import Button from "../../../components/Button/Button";

export default function Socials() {
    return (
        <section className="socials-section section">
            <div className="container">
                <div className="socials-section__content content">
                    <h2 className="socials-section__title section__title">Social</h2>
                    <div className="socials-section__socials">
                        <ul>
                            <li>
                                <div className="social-card">
                                    <div className="social-card__content">
                                        <div className="social-card__icon">
                                            <svg className="social-card__inner-icon" width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M6 19.734C6 14.6935 6 12.1733 6.98094 10.2481C7.8438 8.55461 9.22063 7.17779 10.9141 6.31493C12.8393 5.33398 15.3595 5.33398 20.4 5.33398H28.2667C33.3071 5.33398 35.8274 5.33398 37.7526 6.31493C39.446 7.17779 40.8229 8.55461 41.6857 10.2481C42.6667 12.1733 42.6667 14.6935 42.6667 19.734V27.6006C42.6667 32.6411 42.6667 35.1614 41.6857 37.0866C40.8229 38.78 39.446 40.1568 37.7526 41.0197C35.8274 42.0007 33.3071 42.0007 28.2667 42.0007H20.4C15.3595 42.0007 12.8393 42.0007 10.9141 41.0197C9.22063 40.1568 7.8438 38.78 6.98094 37.0866C6 35.1614 6 32.6411 6 27.6007V19.734ZM14.5 24C14.5 18.7533 18.7533 14.5 24 14.5C29.2466 14.5 33.5 18.7533 33.5 24C33.5 29.2466 29.2466 33.5 24 33.5C18.7533 33.5 14.5 29.2466 14.5 24ZM24 17.5C20.4101 17.5 17.5 20.4101 17.5 24C17.5 27.5898 20.4102 30.5 24 30.5C27.5898 30.5 30.5 27.5898 30.5 24C30.5 20.4102 27.5898 17.5 24 17.5ZM37.2494 15.005C38.3578 13.7735 38.258 11.8766 37.0265 10.7682C35.795 9.6598 33.8981 9.75961 32.7897 10.9911L32.7701 11.0129C31.6618 12.2444 31.7616 14.1413 32.9931 15.2496C34.2246 16.358 36.1215 16.2582 37.2299 15.0267L37.2494 15.005Z" fill="#041527"/>
                                            </svg>
                                        </div>
                                        <div className="social-card__text content">
                                            <p className="social-card__title">
                                                Instagram
                                            </p>
                                            <p>
                                                Follow us on Instagram to see the latest updates and
                                                stay in touch with the community
                                            </p>
                                        </div>
                                        <div className="social-card__control">
                                            <Button
                                                href={'https://instagram.com/raritygram_io?igshid=YmMyMTA2M2Y='}
                                                className={'button_purple instagram__button'}>
                                                Instagram
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="social-card">
                                    <div className="social-card__content">
                                        <div className="social-card__icon">
                                            <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M37.2973 11.8095C34.7904 10.6591 32.102 9.81165 29.2912 9.32625C29.24 9.31689 29.1888 9.3403 29.1625 9.38712C28.8167 10.0021 28.4338 10.8043 28.1656 11.4348C25.1423 10.9822 22.1346 10.9822 19.1734 11.4348C18.9051 10.7903 18.5082 10.0021 18.1609 9.38712C18.1346 9.34186 18.0834 9.31845 18.0323 9.32625C15.223 9.8101 12.5346 10.6576 10.0261 11.8095C10.0044 11.8188 9.98576 11.8344 9.9734 11.8547C4.87417 19.4729 3.47728 26.9038 4.16255 34.2425C4.16565 34.2784 4.1858 34.3128 4.21371 34.3346C7.57804 36.8053 10.837 38.3052 14.0354 39.2994C14.0866 39.315 14.1408 39.2963 14.1734 39.2541C14.93 38.221 15.6044 37.1315 16.1826 35.9859C16.2168 35.9188 16.1842 35.8392 16.1144 35.8126C15.0447 35.4068 14.0261 34.9121 13.0462 34.3502C12.9687 34.305 12.9625 34.1941 13.0338 34.141C13.24 33.9865 13.4463 33.8258 13.6431 33.6634C13.6788 33.6338 13.7284 33.6275 13.7703 33.6463C20.2074 36.5852 27.1764 36.5852 33.5376 33.6463C33.5795 33.626 33.6291 33.6322 33.6663 33.6619C33.8633 33.8242 34.0695 33.9865 34.2772 34.141C34.3485 34.1941 34.3438 34.305 34.2663 34.3502C33.2865 34.923 32.2679 35.4068 31.1966 35.8111C31.1268 35.8376 31.0958 35.9188 31.1299 35.9859C31.7206 37.1299 32.395 38.2193 33.1376 39.2526C33.1687 39.2963 33.2244 39.315 33.2756 39.2994C36.4896 38.3052 39.7485 36.8053 43.1128 34.3346C43.1423 34.3128 43.1609 34.28 43.164 34.2441C43.9841 25.7597 41.7903 18.3897 37.3485 11.8563C37.3376 11.8344 37.319 11.8188 37.2973 11.8095ZM17.1439 29.774C15.2059 29.774 13.609 27.9947 13.609 25.8096C13.609 23.6245 15.1749 21.8453 17.1439 21.8453C19.1284 21.8453 20.7098 23.6401 20.6788 25.8096C20.6788 27.9947 19.1129 29.774 17.1439 29.774ZM30.2136 29.774C28.2756 29.774 26.6788 27.9947 26.6788 25.8096C26.6788 23.6245 28.2446 21.8453 30.2136 21.8453C32.1981 21.8453 33.7795 23.6401 33.7485 25.8096C33.7485 27.9947 32.1981 29.774 30.2136 29.774Z" fill="#041527"/>
                                            </svg>
                                        </div>
                                        <div className="social-card__text content">
                                            <p className="social-card__title">
                                                Discord
                                            </p>
                                            <p>
                                                Join and discuss the latest news with members of our community and become one of us!
                                            </p>
                                        </div>
                                        <div className="social-card__control">
                                            <Button
                                                href={'https://discord.com/invite/Raritygram'}
                                                className={'button_purple instagram__button'}>
                                                Discord
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="social-card">
                                    <div className="social-card__content">
                                        <div className="social-card__icon">
                                            <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M46 10.8073C44.3811 11.4868 42.6413 11.9461 40.8152 12.1526C42.6791 11.0952 44.1104 9.42073 44.7844 7.42565C43.0403 8.40468 41.1083 9.11577 39.0521 9.49903C37.4054 7.83846 35.0593 6.80078 32.463 6.80078C27.4775 6.80078 23.4356 10.6263 23.4356 15.3448C23.4356 16.0144 23.5156 16.6665 23.6694 17.2919C16.1669 16.9356 9.51536 13.534 5.06305 8.36477C4.28602 9.62669 3.84086 11.0944 3.84086 12.6602C3.84086 15.6244 5.43467 18.2397 7.85685 19.7718C6.37699 19.7275 4.98516 19.3432 3.76795 18.7032C3.76731 18.7389 3.76731 18.7747 3.76731 18.8106C3.76731 22.9504 6.87896 26.4037 11.0086 27.1885C10.251 27.3838 9.45356 27.4882 8.63018 27.4882C8.04853 27.4882 7.48298 27.4347 6.93191 27.3349C8.08056 30.7295 11.4143 33.1997 15.3645 33.2687C12.275 35.5602 8.38265 36.9262 4.15341 36.9262C3.42482 36.9262 2.70621 36.8858 2 36.8069C5.99491 39.2311 10.74 40.6455 15.8378 40.6455C32.4419 40.6455 41.5216 27.6262 41.5216 16.3355C41.5216 15.9651 41.5129 15.5966 41.4955 15.2302C43.2592 14.0256 44.7897 12.5207 46 10.8073Z" fill="#041527"/>
                                            </svg>
                                        </div>
                                        <div className="social-card__text content">
                                            <p className="social-card__title">
                                                Twitter
                                            </p>
                                            <p>
                                                Follow our Twitter page if you want the quickest and specific updates
                                            </p>
                                        </div>
                                        <div className="social-card__control">
                                            <Button
                                                href={'https://twitter.com/raritygram'}
                                                className={'button_purple instagram__button'}>
                                                Twitter
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="social-card">
                                    <div className="social-card__content">
                                        <div className="social-card__icon">
                                            <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.64558 23.9956C19.2108 19.3477 32.8831 13.6809 34.7704 12.8966C39.721 10.8437 41.24 11.2371 40.4826 15.7833C39.9387 19.0509 38.3692 29.8692 37.1183 36.6028C36.376 40.5959 34.7107 41.0692 32.0921 39.3416C30.8328 38.5102 24.4767 34.307 23.0972 33.3201C21.8379 32.4206 20.1011 31.3387 22.2792 29.2076C23.0543 28.4485 28.1352 23.5972 32.0938 19.821C32.6125 19.325 31.961 18.5104 31.3624 18.908C26.0268 22.4463 18.6291 27.3574 17.6876 27.9971C16.2652 28.963 14.8992 29.406 12.447 28.7016C10.5942 28.1694 8.78429 27.5347 8.07983 27.2926C5.36706 26.3612 6.01099 25.1549 8.64558 23.9956Z" fill="#041527" />
                                            </svg>
                                        </div>
                                        <div className="social-card__text content">
                                            <p className="social-card__title">
                                                Telegram
                                            </p>
                                            <p>
                                                Share knowledge, get help from the support team, and offer your ideas for our development in the Telegram chat
                                            </p>
                                        </div>
                                        <div className="social-card__control">
                                            <Button
                                                href={'https://t.me/raritygram_eng'}
                                                className={'button_purple instagram__button'}>
                                                Telegram
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}