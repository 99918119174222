import './Employee.scss';

export default function Employee(props) {
    return (
        <div className={'employee flex'}>
            <div className="employee__avatar">
                <img src={`img/mainPage/our-team/${props.avatar}`} alt="Avatar"/>
            </div>
            <h3>{props.name}</h3>
            <div className={'employee__post'}>{props.post}</div>
        </div>
    );
}