import './RoadMap.scss';
import RoadmapItem from "./RoadmapItem/RoadmapItem";
import MetaverseItem from "./MetaverseItem/MetaverseItem";

export default function RoadMap({virtualPage}) {

    const stages = [
        {
            stage: 'stage 0',
            design: '95',
            development: '95',
            list: [
                'NFTRarity',
                'MVP Raritygram',
                'User profile',
                'Wallet connection',
                'Publish, like, and comment on posts'
            ],
            active: true
        },
        {
            stage: 'stage 1',
            design: '55',
            development: '60',
            list: [
                'MVP RarityMeta',
                'RarityMarketplace',
                'NFTcreate',
                'RarityToken',
                'RarityChat'
            ]
        },
        {
            stage: 'stage 2',
            design: '45',
            development: '55',
            list: [
                'RarityRent',
                'RarityGoverment',
                'RarityGame',
                'Customization of a personal page'
            ]
        },
        {
            stage: 'stage 3',
            design: '0',
            development: '0',
            list: [
                'RarityID',
                'Blockchain',
                'RarityDAO'
            ]
        },
    ];

    const virtualLifeStages = [
        {
            stage: 'Stage 1',
            title: 'Digital avatar development',
            paragraph: 'A neural network scans uploaded photo and create an avatar looking like a real person on the picture',
            active: true
        },
        {
            stage: 'Stage 2',
            title: 'Development of Raritygram Metaverse',
            paragraph: '3D models of the world environment: buildings, lands, parks, roads and other objects',
        },
        {
            stage: 'Stage 3',
            title: 'Multiplayer development',
            paragraph: 'Feature that allows online users interact with each other',
        },
        {
            stage: 'Stage 4',
            title: 'Smart contract development',
        },
        {
            stage: 'Stage 5',
            title: 'Integrating mini-games into our metaverse',
        }
    ];

    const roadmapItems = stages.map((item)=> {
       return(
         <RoadmapItem item={item} list={item.list} key={item.stage}/>
       );
    });

    const virtualPageItems = virtualLifeStages.map((item)=> {
        return(
            <MetaverseItem item={item} key={item.title}/>
        );
    });

    return (
        <section className="roadmap">
            <div className="container">
                <h2 className="roadmap__title">Raritygram roadmap</h2>
                <div className="roadmap__map">
                    <div className={'roadmap__list flex'}>
                        {virtualPage ? virtualPageItems : roadmapItems}
                    </div>
                </div>
            </div>
        </section>
    );
}