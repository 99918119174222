import './Ecosystem.scss';
import MainCard from "../../../components/MainCard/MainCard";
import {useRef} from "react";
import Slider from "react-slick";
import {ecosystem} from "./ecosystemItems";

export default function Ecosystem() {


    const SliderRef = useRef(null);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        appendDots: dots => (
            <div className={'slider__nav-wrap'}>
                <ul>{dots}</ul>
            </div>
        )
    };

    const ecosystemList = ecosystem.map((item)=> {
       return (
         <MainCard className={'ecosystem__card'} item={item} button={item.button} key={item.title}/>
       );
    });

    const ecosystemSliderList = ecosystem.map((item)=> {
        return (
            <div className="slider__item" key={item.title}>
                <MainCard className={'ecosystem__card'} item={item} button={item.button}/>
            </div>
        );
    });

    return (
      <section className={'ecosystem'}>
          <div className="container">
              <h2 className="ecosystem__title">
                  The Raritygram Ecosystem
              </h2>
              <ul className={'ecosystem__list'}>
                  {ecosystemList}
              </ul>

              {/*Slider place section*/}
              <div className="ecosystem__slider">
                  <Slider ref={SliderRef} {...settings}>
                      {ecosystemSliderList}
                  </Slider>
              </div>

          </div>
      </section>
    );
}