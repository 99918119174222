export const tabs = [
    'Diamond',
    'Sapphire',
    'Ruby',
    'Emerald'
];

export const tabContents = [
    {
        title: 'diamond',
        colorClass: 'purple-text',
        color: 'button_purple',
        traits: '6',
        categories: '3 best',
        list: [
            {
                icon: 'padlock.svg',
                name: 'Early access to beta',
                desc: 'Be the first to explore Raritygram',
                tooltip:
                    (
                        <>
                            <li><strong>Open lock</strong> - get access to beta raritygram.com</li>
                            <li><strong>Close lock</strong> - without access to the beta raritygram.com</li>
                        </>
                    )
            },
            {
                icon: 'cristal.svg',
                name: 'Secret features'
            },
            {
                icon: 'timer.svg',
                name: 'Vesting',
                lockUpIcon: 'lockup.svg',
                lockUp: 'Initial Unlock',
                tooltip:
                    (
                        <>
                            <li><strong>No timer</strong> - without character intial unlock</li>
                            <li><strong>White timer</strong> - previously for 10 days</li>
                            <li><strong>Green timer</strong> - previously for 20 days</li>
                            <li><strong>Yellow timer</strong> - previously for 30 days</li>
                            <li><strong>Blue timer</strong> - previously for 45 days</li>
                        </>
                    )
            },
            {
                icon: 'ball.svg',
                name: 'Character customization',
                desc: 'Clothing and accessories',
                tooltip:
                    (
                        <>
                            <li><strong>No cap</strong> - no without character accessories</li>
                            <li><strong>White cap</strong> - additional hats</li>
                            <li><strong>Green cap</strong> - additional clothing on the torso</li>
                            <li><strong>Yellow cap</strong> - additional pants</li>
                            <li><strong>Blue cap</strong> - additional accessories</li>
                            <li><strong>Dark blue cap</strong> - additional background</li>
                            <li><strong>Pink cap</strong> - additional GIF background</li>
                        </>
                    )
            },
            {
                icon: 'custom.svg',
                name: 'Page customization',
                desc: 'backgrounds and borders',
                tooltip:
                    (
                        <>
                            <li><strong>No rectangle</strong> - without page customization</li>
                            <li><strong>White rectangle</strong> - border for profile image</li>
                            <li><strong>Green rectangle</strong> - GIF border for profile image</li>
                            <li><strong>Yellow rectangle</strong> - profile background</li>
                            <li><strong>Blue rectangle</strong> - GIF profile background</li>
                            <li><strong>Dark blue rectangle</strong> - emojis</li>
                            <li><strong>Pink rectangle</strong> - GIF emojis</li>
                        </>
                    )
            },
            {
                icon: 'wallet.svg',
                name: 'Bonus tokens',
                desc: '10-20%',
                tooltip:
                    (
                        <>
                            <li><strong>No wallet</strong> - without increase coins</li>
                            <li><strong>White wallet</strong> - 5% increase coins</li>
                            <li><strong>Green wallet</strong> - 10% increase coins</li>
                            <li><strong>Yellow wallet</strong>  - 15% increase coins</li>
                            <li><strong>Blue wallet</strong> - 20% increase coins</li>
                        </>
                    )
            },
            {
                icon: 'rari.svg',
                name: 'Bonus stars',
                desc: '15, 40, 60, 100, 150',
                tooltip:
                    (
                        <>
                            <li><strong>No logo</strong> - no stars</li>
                            <li><strong>White logo</strong> - 15 stars</li>
                            <li><strong>Green logo</strong> - 40 stars</li>
                            <li><strong>Yellow logo</strong> - 60 stars</li>
                            <li><strong>Blue logo</strong> - 100 stars</li>
                            <li><strong>Dark blue logo</strong> - 150 stars</li>
                        </>
                    )
            },
            {
                icon: 'meta.svg',
                name: 'Metaverse plots',
            }
        ],
        video: 'Diamond.mp4',
        maxMint: '10',
        tokensLeft: '84',
        tokensMinted: '16'
    },
    {
        title: 'sapphire',
        colorClass: 'blue-text',
        color: 'button_sapphire',
        traits: '4 to 6',
        categories: '4 best',
        list: [
            {
                icon: 'padlock.svg',
                name: 'Early access to beta',
                desc: 'Be the first to explore Raritygram',
                tooltip:
                    (
                        <>
                            <li><strong>Open lock</strong> - get access to beta raritygram.com</li>
                            <li><strong>Close lock</strong> - without access to the beta raritygram.com</li>
                        </>
                    )
            },
            {
                icon: 'cristal.svg',
                name: 'Secret features'
            },
            {
                icon: 'timer.svg',
                name: 'Vesting',
                lockUpIcon: 'lockup.svg',
                lockUp: 'Initial Unlock',
                tooltip:
                    (
                        <>
                            <li><strong>No timer</strong> - without character intial unlock</li>
                            <li><strong>White timer</strong> - previously for 10 days</li>
                            <li><strong>Green timer</strong> - previously for 20 days</li>
                            <li><strong>Yellow timer</strong> - previously for 30 days</li>
                            <li><strong>Blue timer</strong> - previously for 45 days</li>
                        </>
                    )
            },
            {
                icon: 'ball.svg',
                name: 'Character customization',
                desc: 'Clothing and accessories',
                tooltip:
                    (
                        <>
                            <li><strong>No cap</strong> - no without character accessories</li>
                            <li><strong>White cap</strong> - additional hats</li>
                            <li><strong>Green cap</strong> - additional clothing on the torso</li>
                            <li><strong>Yellow cap</strong> - additional pants</li>
                            <li><strong>Blue cap</strong> - additional accessories</li>
                            <li><strong>Dark blue cap</strong> - additional background</li>
                            <li><strong>Pink cap</strong> - additional GIF background</li>
                        </>
                    )
            },
            {
                icon: 'custom.svg',
                name: 'Page customization',
                desc: 'backgrounds and borders',
                tooltip:
                    (
                        <>
                            <li><strong>No rectangle</strong> - without page customization</li>
                            <li><strong>White rectangle</strong> - border for profile image</li>
                            <li><strong>Green rectangle</strong> - GIF border for profile image</li>
                            <li><strong>Yellow rectangle</strong> - profile background</li>
                            <li><strong>Blue rectangle</strong> - GIF profile background</li>
                            <li><strong>Dark blue rectangle</strong> - emojis</li>
                            <li><strong>Pink rectangle</strong> - GIF emojis</li>
                        </>
                    )
            },
            {
                icon: 'wallet.svg',
                name: 'Bonus tokens',
                desc: '10-20%',
                tooltip:
                    (
                        <>
                            <li><strong>No wallet</strong> - without increase coins</li>
                            <li><strong>White wallet</strong> - 5% increase coins</li>
                            <li><strong>Green wallet</strong> - 10% increase coins</li>
                            <li><strong>Yellow wallet</strong>  - 15% increase coins</li>
                            <li><strong>Blue wallet</strong> - 20% increase coins</li>
                        </>
                    )
            },
            {
                icon: 'rari.svg',
                name: 'Bonus stars',
                desc: '15, 40, 60, 100, 150',
                tooltip:
                    (
                        <>
                            <li><strong>No logo</strong> - no stars</li>
                            <li><strong>White logo</strong> - 15 stars</li>
                            <li><strong>Green logo</strong> - 40 stars</li>
                            <li><strong>Yellow logo</strong> - 60 stars</li>
                            <li><strong>Blue logo</strong> - 100 stars</li>
                            <li><strong>Dark blue logo</strong> - 150 stars</li>
                        </>
                    )
            },
            {
                icon: 'meta.svg',
                name: 'Metaverse plots',
            }
        ],
        video: 'Sapfir.mp4',
        maxMint: '100',
        tokensLeft: '148',
        tokensMinted: '52'
    },
    {
        title: 'ruby',
        colorClass: 'red-text',
        color: 'button_ruby',
        traits: '3 to 6',
        categories: 'all',
        list: [
            {
                icon: 'padlock.svg',
                name: 'Early access to beta',
                desc: 'Be the first to explore Raritygram',
                tooltip:
                    (
                        <>
                            <li><strong>Open lock</strong> - get access to beta raritygram.com</li>
                            <li><strong>Close lock</strong> - without access to the beta raritygram.com</li>
                        </>
                    )
            },
            {
                icon: 'cristal.svg',
                name: 'Secret features'
            },
            {
                icon: 'timer.svg',
                name: 'Vesting',
                lockUpIcon: 'lockup.svg',
                lockUp: 'Initial Unlock',
                tooltip:
                    (
                        <>
                            <li><strong>No timer</strong> - without character intial unlock</li>
                            <li><strong>White timer</strong> - previously for 10 days</li>
                            <li><strong>Green timer</strong> - previously for 20 days</li>
                            <li><strong>Yellow timer</strong> - previously for 30 days</li>
                            <li><strong>Blue timer</strong> - previously for 45 days</li>
                        </>
                    )
            },
            {
                icon: 'ball.svg',
                name: 'Character customization',
                desc: 'Clothing and accessories',
                tooltip:
                    (
                        <>
                            <li><strong>No cap</strong> - no without character accessories</li>
                            <li><strong>White cap</strong> - additional hats</li>
                            <li><strong>Green cap</strong> - additional clothing on the torso</li>
                            <li><strong>Yellow cap</strong> - additional pants</li>
                            <li><strong>Blue cap</strong> - additional accessories</li>
                            <li><strong>Dark blue cap</strong> - additional background</li>
                            <li><strong>Pink cap</strong> - additional GIF background</li>
                        </>
                    )
            },
            {
                icon: 'custom.svg',
                name: 'Page customization',
                desc: 'backgrounds and borders',
                tooltip:
                    (
                        <>
                            <li><strong>No rectangle</strong> - without page customization</li>
                            <li><strong>White rectangle</strong> - border for profile image</li>
                            <li><strong>Green rectangle</strong> - GIF border for profile image</li>
                            <li><strong>Yellow rectangle</strong> - profile background</li>
                            <li><strong>Blue rectangle</strong> - GIF profile background</li>
                            <li><strong>Dark blue rectangle</strong> - emojis</li>
                            <li><strong>Pink rectangle</strong> - GIF emojis</li>
                        </>
                    )
            },
            {
                icon: 'wallet.svg',
                name: 'Bonus tokens',
                desc: '10-20%',
                tooltip:
                    (
                        <>
                            <li><strong>No wallet</strong> - without increase coins</li>
                            <li><strong>White wallet</strong> - 5% increase coins</li>
                            <li><strong>Green wallet</strong> - 10% increase coins</li>
                            <li><strong>Yellow wallet</strong>  - 15% increase coins</li>
                            <li><strong>Blue wallet</strong> - 20% increase coins</li>
                        </>
                    )
            },
            {
                icon: 'rari.svg',
                name: 'Bonus stars',
                desc: '15, 40, 60, 100, 150',
                tooltip:
                    (
                        <>
                            <li><strong>No logo</strong> - no stars</li>
                            <li><strong>White logo</strong> - 15 stars</li>
                            <li><strong>Green logo</strong> - 40 stars</li>
                            <li><strong>Yellow logo</strong> - 60 stars</li>
                            <li><strong>Blue logo</strong> - 100 stars</li>
                            <li><strong>Dark blue logo</strong> - 150 stars</li>
                        </>
                    )
            },
            {
                icon: 'meta.svg',
                name: 'Metaverse plots',
            }
        ],
        video: 'Rube.mp4',
        maxMint: '500',
        tokensLeft: '777',
        tokensMinted: '123'
    },
    {
        title: 'emerald',
        colorClass: 'emerald-text',
        color: 'button_emerald',
        traits: '2 to 6',
        categories: 'all',
        list: [
            {
                icon: 'padlock.svg',
                name: 'Early access to beta',
                desc: 'Be the first to explore Raritygram',
                tooltip:
                    (
                        <>
                            <li><strong>Open lock</strong> - get access to beta raritygram.com</li>
                            <li><strong>Close lock</strong> - without access to the beta raritygram.com</li>
                        </>
                    )
            },
            {
                icon: 'cristal.svg',
                name: 'Secret features'
            },
            {
                icon: 'timer.svg',
                name: 'Vesting',
                lockUpIcon: 'lockup.svg',
                lockUp: 'Initial Unlock',
                tooltip:
                    (
                        <>
                            <li><strong>No timer</strong> - without character intial unlock</li>
                            <li><strong>White timer</strong> - previously for 10 days</li>
                            <li><strong>Green timer</strong> - previously for 20 days</li>
                            <li><strong>Yellow timer</strong> - previously for 30 days</li>
                            <li><strong>Blue timer</strong> - previously for 45 days</li>
                        </>
                    )
            },
            {
                icon: 'ball.svg',
                name: 'Character customization',
                desc: 'Clothing and accessories',
                tooltip:
                    (
                        <>
                            <li><strong>No cap</strong> - no without character accessories</li>
                            <li><strong>White cap</strong> - additional hats</li>
                            <li><strong>Green cap</strong> - additional clothing on the torso</li>
                            <li><strong>Yellow cap</strong> - additional pants</li>
                            <li><strong>Blue cap</strong> - additional accessories</li>
                            <li><strong>Dark blue cap</strong> - additional background</li>
                            <li><strong>Pink cap</strong> - additional GIF background</li>
                        </>
                    )
            },
            {
                icon: 'custom.svg',
                name: 'Page customization',
                desc: 'backgrounds and borders',
                tooltip:
                    (
                        <>
                            <li><strong>No rectangle</strong> - without page customization</li>
                            <li><strong>White rectangle</strong> - border for profile image</li>
                            <li><strong>Green rectangle</strong> - GIF border for profile image</li>
                            <li><strong>Yellow rectangle</strong> - profile background</li>
                            <li><strong>Blue rectangle</strong> - GIF profile background</li>
                            <li><strong>Dark blue rectangle</strong> - emojis</li>
                            <li><strong>Pink rectangle</strong> - GIF emojis</li>
                        </>
                    )
            },
            {
                icon: 'wallet.svg',
                name: 'Bonus tokens',
                desc: '10-20%',
                tooltip:
                    (
                        <>
                            <li><strong>No wallet</strong> - without increase coins</li>
                            <li><strong>White wallet</strong> - 5% increase coins</li>
                            <li><strong>Green wallet</strong> - 10% increase coins</li>
                            <li><strong>Yellow wallet</strong>  - 15% increase coins</li>
                            <li><strong>Blue wallet</strong> - 20% increase coins</li>
                        </>
                    )
            },
            {
                icon: 'rari.svg',
                name: 'Bonus stars',
                desc: '15, 40, 60, 100, 150',
                tooltip:
                    (
                        <>
                            <li><strong>No logo</strong> - no stars</li>
                            <li><strong>White logo</strong> - 15 stars</li>
                            <li><strong>Green logo</strong> - 40 stars</li>
                            <li><strong>Yellow logo</strong> - 60 stars</li>
                            <li><strong>Blue logo</strong> - 100 stars</li>
                            <li><strong>Dark blue logo</strong> - 150 stars</li>
                        </>
                    )
            },
            {
                icon: 'meta.svg',
                name: 'Metaverse plots',
            }
        ],
        video: 'Izumrud.mp4',
        maxMint: '1000',
        tokensLeft: '1081',
        tokensMinted: '219'
    }
]