import './MoreAbout.scss';
import MoreCard from "./MoreCard/MoreCard";
import {useRef} from "react";
import Slider from "react-slick";

export default function MoreAbout({cards}) {

    const SliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1240,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
        appendDots: dots => (
            <div className={'slider__nav-wrap'}>
                <ul>{dots}</ul>
            </div>
        )
    };

    const blogCards = cards.map(({name, date, time, title, desc, mainImage, authorAvatar, authorName, authorPost}) => {
        return (
            <div className="slider__item" key={title}>
                <MoreCard
                    to={name}
                    date={date}
                    time={time}
                    title={title}
                    desc={desc}
                    img={mainImage}
                    authorAvatar={authorAvatar}
                    authorName={authorName}
                    authorPost={authorPost}
                    column={true}
                />
            </div>
        );
    })

    return (
        <section className="more-about section">
            <div className="container">
                <div className="more-about__title">
                    More about raritygram
                </div>
                {/*<ul className="more-about__cards flex">*/}
                {/*    {blogCards}*/}
                {/*</ul>*/}

                {/*  slider  */}
                <div className="more-about__slider">
                    <Slider ref={SliderRef} {...settings}>
                        {blogCards}
                    </Slider>
                </div>


            </div>
        </section>
    );
}