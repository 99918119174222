import './ProjectBy.scss';

export default function ProjectBy() {
    return (
        <section className="project-by-section section">
            <div className="container">
                <div className="project-by-section__content content">
                    <h2 className="project-by-section__title section__title">
                        produced by <a
                            className="accent-text"
                            href="https://nftworkin.com"
                            target="_blank"
                            rel={'noreferrer'}>
                            NFTworkin.com
                        </a>
                    </h2>
                </div>
            </div>
        </section>
    );
}