export const raritygramAndMetaverse = {
    name: 'raritygramandmetaverse',
    type: 'blog',
    date: 'JULY 31/2022',
    time: '4 min',
    authorAvatar: 'alex.png',
    authorName: 'Alex',
    authorPost: 'Lead developer',
    mainImage: 'devblog_post2.jpg',
    title: 'Raritygram and Metaverse Development: VR Museum, Web 3.0 Social Media and More',
    shortDesc: 'Our team has been working on improving the Raritygram website’s layout and our metaverse project - now, we have great updates to share with you.',
    content: [
        {
            video: 'https://www.youtube.com/embed/q3qYjn60TJk'
        },
        {
            h2: 'What does Raritygram have to offer?',
            p: 'We want the Raritygram platform to be a great place for everybody — that is why our team is working hard on providing all the future members, let it be single creators, project teams, or NFT collectors, with the best experience possible. Do you know how the website will look and what opportunities it will bring? Let’s discover all the Rarity features together.'
        },
        {
            h3: 'New Logotype',
            p: 'The very first page of the website enables you to log in to your account with the help of your wallet. On the stage 0 of Raritygram development, you will only be able to access the platform if you are a holder of our NFT (in the near future, however, anyone could be a Raritygram member). To create an account and log in to it, you’ll have to connect a wallet to your Rarity profile.'
        },
        {
            h3: 'Main Page',
            p: 'When you successfully log in, you’ll see the main page of our Web 3.0 social media platform — Raritygram. You’ll be able to see other users’ posts, ideas, and art pieces. Each action performed on the website, including liking, sharing, or commenting, is, in fact, a blockchain transaction. Under each post, there is a hash link that gives you additional information about all the transactions.'
        },
        {
            h3: 'Top Projects',
            p: 'When you scroll the main page a bit down, you can see the section called Top Projects. This is where Raritygram features the most prominent digital art creators so that every user can see what they’ve published. The top projects are chosen based on how popular they are on the platform — that is, how frequently other Raritygram members see, like, and share them.'
        },
        {
            h3: 'Smart Feed',
            p: <>Under the Top Projects section, you’ll find smart feed divided into three tabs: Project, Users, and
                Collections.<br/>
                The first tab shows business accounts of crypto projects (made by teams or single creators). Note that a
                business account is not the same thing as a regular user account and is built in an entirely different
                way. Business accounts have various widgets that help others get full information about what a
                particular project is all about — such widgets include project roadmaps, development blogs, and
                more.<br/>
                The Users tab shows other accounts that you can follow. Here, you see regular users that also can like,
                comment, or share any post they see on Raritygram. The users are able to show NFTs that they own on
                their profile page and write posts in their blogs — that’s why it’s a good idea to follow somebody
                you’re interested in.<br/>
                The tab called Collections is a section designed specifically for creators — 2D/3D artists, musicians,
                and bloggers. This tab allows any person who has something to share with the world to show it to others.
                Raritygram is a place where you and your team can create, publish, and sell your art pieces with the
                help of an innovative marketplace feature we’re going to launch in the future.
            </>
        },
        {
            h3: 'Smart Search',
            p: 'Above the feed section, you can find the smart search engine. With the help of convenient symbols and filters, you’ll be able to find exactly what you’re looking for in a matter of seconds.'
        },
        {
            h2: 'Take a walk on the meta side',
            p: <>Here’s the most exciting part for most of our users — the metaverse. As we have already created the
                demo version of what the Raritygram world will look like, we have much to show to you.<br/>
                When you enter the metaverse, you can start exploring it in any way you like. Take a walk, go into a
                building that interests you, get familiar with the main Raritygram concepts such as Rarity Cars and
                Rarity Meta. In the future, we’re planning to open a VR museum — a place where digital creators can show
                their works to others. Any user will be able to show their NFT in the museum — others will see it as if
                they were in a real-life museum.<br/>
                Another of our plans is giving users an opportunity to buy and sell real estate in the metaverse. Once
                you’ve bought a piece of land, you’ll be able to change and improve it, implement your project or simply
                show your NFT collection there.<br/>
                When you enter the Raritygram building, you see a small recording studio and Presidents art pieces. You
                can interact with each NFT you see — it will open a website with all the info you want to know. As a
                matter of fact, Presidents is one of the most prominent and exciting collections we have created, as we
                are planning to launch a 3D card game based on this project. Having an NFT from this collection, you’ll
                be able to encounter in a game that has hundreds of fascinating features and opportunities.<br/><br/>
                Thank you for supporting us on each level of Raritygram development — we’re always happy to share our
                project’s updates with you. Stay tuned for more, and see you soon!
            </>
        }
    ]
};