import Button from "../../../components/Button/Button";
import {Link as ScrollLink} from  "react-scroll";

export const identity = [
    {
        svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={52} height={52} rx={8} fill="#EBF1F5" />
            <path d="M27.9099 12.641L12.2356 10.8536C12.1092 10.8392 12.0302 10.9868 12.1123 11.084L21.2896 21.9494C21.3042 21.9668 21.3025 21.9921 21.2845 22.006L21.2753 22.0132C20.5655 22.5631 18.5471 24.127 17.9207 27.5722C17.3256 32.4979 20.6441 35.3535 22.2522 36.1142L39.6653 43.7039C39.7473 43.7397 39.8212 43.64 39.7632 43.5719L29.4633 31.4752C29.4442 31.4528 29.4548 31.4182 29.4832 31.4103C33.0323 30.4268 36.2295 26.7017 36.2295 22.3685C36.2295 16.198 31.1613 13.012 27.9099 12.641Z" stroke="#041527" strokeWidth={3} />
        </svg>,
        stage: 'stage 0',
        title: 'NFTRarity',
        text: 'Owning NFTRarity\'s will not only expand your crypto portfolio with utility tokens, but also grant you access to unique features of the platform.',
        button: <Button to={'/mint'} className={'button_purple'}>Mint</Button>
    },
    {
        svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={52} height={52} rx={8} fill="#EBF1F5" />
            <rect x="8.5" y="12.5" width={35} height="26.3636" rx="13.1818" stroke="#041527" strokeWidth={3} />
            <path d="M22.4086 33.8924C21.9802 33.8924 21.6486 33.7749 21.4137 33.54C21.1926 33.2913 21.0821 32.9527 21.0821 32.5244V20.4611C21.0821 20.0189 21.1995 19.6873 21.4344 19.4662C21.6693 19.2313 22.001 19.1138 22.4293 19.1138H27.4246C29.0275 19.1138 30.2642 19.5007 31.1348 20.2746C32.0053 21.0346 32.4406 22.1055 32.4406 23.4873C32.4406 24.3855 32.2402 25.1593 31.8395 25.8087C31.4526 26.4582 30.8861 26.9557 30.1399 27.3011C29.3937 27.6466 28.4886 27.8193 27.4246 27.8193L27.6112 27.5084H28.2952C28.8617 27.5084 29.3522 27.6466 29.7668 27.9229C30.1952 28.1993 30.5682 28.6346 30.8861 29.2287L32.4406 32.1098C32.6064 32.4 32.6824 32.6833 32.6686 32.9597C32.6548 33.236 32.5512 33.464 32.3577 33.6437C32.1642 33.8095 31.881 33.8924 31.5079 33.8924C31.1348 33.8924 30.8308 33.8164 30.5959 33.6644C30.361 33.4986 30.1537 33.2498 29.9741 32.9182L27.8806 29.0629C27.6319 28.5931 27.3348 28.2822 26.9893 28.1302C26.6577 27.9644 26.2293 27.8815 25.7042 27.8815H23.7352V32.5244C23.7352 32.9527 23.6246 33.2913 23.4035 33.54C23.1824 33.7749 22.8508 33.8924 22.4086 33.8924ZM23.7352 25.9124H26.9686C27.9221 25.9124 28.6406 25.7189 29.1242 25.332C29.6217 24.9313 29.8704 24.3371 29.8704 23.5495C29.8704 22.7757 29.6217 22.1953 29.1242 21.8084C28.6406 21.4077 27.9221 21.2073 26.9686 21.2073H23.7352V25.9124Z" fill="#041527" />
        </svg>,
        stage: 'stage 1',
        title: 'Rarity token',
        text: 'We’ve made our own token for Raritygram users — you’ll be able to use it to conveniently pay for online services and much more.',
        button: <ScrollLink className={'button-gray'} to={'token'} smooth={true} offset={-96}>More</ScrollLink>
    },
    {
        svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={52} height={52} rx={8} fill="#EBF1F5"/>
            <path
                d="M40.3996 20.9596V17.9996C40.3996 14.465 37.5342 11.5996 33.9996 11.5996H31.0396M40.3996 31.0396V33.9996C40.3996 37.5342 37.5342 40.3996 33.9996 40.3996H31.0396M20.9596 40.3996H17.9996C14.465 40.3996 11.5996 37.5342 11.5996 33.9996V31.0396M11.5996 20.9596V17.9996C11.5996 14.465 14.465 11.5996 17.9996 11.5996H20.9596"
                stroke="#041527" strokeWidth={3} strokeLinecap="round"/>
            <path d="M42 26L10 26" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        stage: 'stage 2',
        title: 'RarityID',
        text: 'RarityID is a convenient way to save your personal data and use all Raritygram services without the need to undergo the verification process on other platforms.',
        button: <Button to={'/services/virtualcard'} className={'button-gray'}>More</Button>
    }
];