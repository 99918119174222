export const raritygramupdate = {
    name: 'raritygramupdate',
    type: 'blog',
    date: 'JUNE 22/2022',
    time: '4 min',
    authorAvatar: 'alex.png',
    authorName: 'Alex',
    authorPost: 'Lead developer',
    mainImage: 'devblog_post1.jpg',
    title: 'Raritygram Update',
    shortDesc: 'The past month has been quite fruitful for our team – we have plenty of updates to share with you.',
    content: [
        // {
        //     h2: 'Brand-new Website for an Innovative Project',
        //     h3: 'New Logotype',
        //     p: <></>,
        //     ul: [
        //         <><span>Creole Shamrock,</span> which represents our friendliness to any new user on the platform. We are always happy to see new faces!</>,
        //         <><span>Electrolime,</span> reflecting our team’s infinite energy spent on creating new projects. We are a young team of professionals, and what makes us unique is willingness to do something new and great all the time.</>,
        //     ],
        //     img: 'devblog_post_img.jpg'
        // }
        {
            video: 'https://www.youtube.com/embed/I-GcK8jMDOg'
        },
        {
            h2: 'Brand-new Website for an Innovative Project',
            p: <>The previous Raritygram website did not tick all of the boxes for us and our customers.
                That’s why we decided to redesign it and create a page that would make our users fall in
                love with it at first glance.<br/><br/>
                Entering the market is easy as a pie – all one needs for starters is a genius product, a
                team of seasoned professionals, almighty marketing specialists, and a simple website. At the
                very beginning of our path, we didn’t consider building a unique brand and style of our
                company. We created a catchy name and a nice logo, chose an attractive font and a safe color
                palette. Suffice it to say, it was a great starter pack for a new IT company, but this was
                not enough to make the establishment stand out from the competitors in the industry. Today,
                more and more entrepreneurs realize just how important a brand is, and it makes reaching new
                heights without seeing where you are going much more difficult.<br/><br/>
                We understood that we needed to rebrand Raritygram as soon as we released our first product.
                When you constantly need to provide both users with new content to keep them interested, there
                is too little time left for creativity and innovation. Being caught up in the turmoil of
                creating products without a clear understanding of how they should look like, we figured out
                that we needed to create a new logo and brand – a brand that will perfectly reflect our concept
                and values.
            </>
        },
        {
            h3: 'New Logotype',
            img: 'raritygram-gradient.jpg',
            p: 'New Raritygram logo looks nothing like the previous one. It merges two elements – the first letter of the product’s name and a lightning. The lightning represents our team’s infinite energy and constant bursts of innovative ideas. The logotype shows how fast we complete our projects, however big and complicated they are, and how high their quality is. We do what we deeply care about, and this is where the concept of the new logo appeared.'
        },
        {
            h3: 'Color Palette',
            img: 'raritygram-colors.jpg',
            p: 'Let’s talk about the choice of colors for the improved version of the website. We have chosen the colors that are quite uncommon in the crypto sphere, and this decision was not taken randomly. The design of every website about cryptocurrencies is either dark purple or blue and white, which is a bit disappointing. Our team decided to take a step further from the classics and choose unique colors, not used by anyone else in our industry. Here they are:',
            ul: [
                <><span>Creole Shamrock,</span> which represents our friendliness to any new user on the platform. We
                    are always happy to see new faces!</>,
                <><span>Electrolime,</span> reflecting our team’s infinite energy spent on creating new projects. We are
                    a young team of professionals, and what makes us unique is willingness to do something new and great
                    all the time.</>,
                <><span>Dark night,</span> representing state-of-the-art technologies that we use and create. Above
                    anything else, IT is about innovation.</>,
                <><span>Clouds over the sea</span> because the quality of our product is over the moon, and we’re not
                    going to act shy about it.</>,
            ]
        },
        {
            h3: 'Website Rebranding',
            img: 'raritygram-timer.png',
            p: <>Let’s talk about the choice of colors for the improved version of the website. We have chosen
                the colors that are quite uncommon in the crypto sphere, and this decision was not taken
                randomly. The design of every website about cryptocurrencies is either dark purple or blue and
                white, which is a bit disappointing. Our team decided to take a step further from the classics
                and choose unique colors, not used by anyone else in our industry.<br/><br/>
                We changed everything about the website, and now it’s a lot better. The first page of the new
                website makes users stay on it and read every other page there is, which is an enormous
                improvement from the initial version that we had created. It was not corresponding to our goals
                and values at all, and frankly speaking, its design couldn’t attract too many people either.
                Now, it features a clear and concise structure, great fonts, and all the right emphases, which
                makes us – and hopefully, you as well – much more delighted.
            </>
        },
        {
            h3: 'Social Media',
            img: 'raritygram-background.png',
            p: <>We couldn’t forget about the social media pages of our platform – after all, they are what
                most users
                see first. We have made every post reflect our brand and design. Instead of a bunch of blue
                images that
                were impossible to navigate, we now provide exciting and interesting content to our users.
                Hopefully,
                you enjoy reading and watching what we post as much as we do.<br/><br/>Thank you for
                supporting us! We put
                a lot of time and effort into redesigning the Raritygram website, and we know that now it is
                better than
                ever. We strive to give you the best experience, and rebranding was one more step to making
                our content
                even more amazing.
            </>
        },
        {
            h2: 'Creating a Virtual World',
            p: 'RarityMeta is the biggest project of our team that merges reality and technologies. We’ve been working on creating our own special world for a while, and we’re ready to share what we’ve achieved with you.'
        },
        {
            h3: 'Virtual Life on a New Level',
            p: 'Let’s start by discussing what the concept of a metaverse is. RarityMeta will be a brave new virtual world where all users can freely interact with each other, build anything their imagination is capable of, create NFT galleries, make deals – just like in real life, only better and much more exciting. Sure, it might seem like science fiction to some people, but we are going to show you that the future has already begun. Anyone who is ready to explore endless possibilities of technology and innovation is our friend – and a much-awaited guest of our amazing world that is yet to come.'
        },
        {
            h3: 'Explore All the Metaverse Opportunities',
            p: <>
                <span>Create a digital copy of yourself.</span> A neural network enables all users of the
                metaverse to create lifelike avatars by simply uploading a photo of themselves.<br/>
                <span>Make offers and transactions thanks to the Rarity Government and Rarity ID tools.</span> They
                allow users to make international deals within the metaverse – this feature saves tons of time
                and money.<br/>
                <span>Participate in economic processes.</span> Economics in the metaverse function just like in
                real life, but its processes are much more convenient and faster. A metaverse user can sell and
                buy real estate, sign contracts, and conduct any other financial procedure in a matter of
                minutes.<br/>
                <span>Release your artistic side.</span> RarityMeta is a place for any creator. If you are
                already an illustrator or are aspiring to become one, metaverse can offer you plenty of
                self-expression options. In a virtual world, you can monetize your works and publish them for
                everyone else to see and appreciate.
            </>
        },
        {
            h3: 'Development Roadmap',
            p: 'Creating a whole metaverse from scratch is not an easy task. We have divided our work into five main stages, each of which represents a new level of the virtual world’s development. Currently, we are working on finishing the first stage – soon enough, we will have something great to show you!'
        },
        {
            h3: 'Stage One (Where We are Now) – Digital Avatar Development',
            p: 'Creating a whole metaverse from scratch is not an easy task. We have divided our work into five main stages, each of which represents a new level of the virtual world’s development. Currently, we are working on finishing the first stage – soon enough, we will have something great to show you!'
        },
        {
            h3: 'Stage One (Where We are Now) – Digital Avatar Development',
            p: <>
                Right now, our team is working on developing a neural network that will scan any uploaded photo
                of a person and create a digital avatar that looks just like this person in real life. This is
                probably one of the most essential stages, as it sets a core for future development of the
                project.<br/><br/>
                We have already created the first avatar. It is a digital copy of the NFT Workin Youtube Channel
                host – and this is how she became the first citizen of RarityMeta. We have tried to make an
                avatar that looks as close to her real-life self as possible.<br/><br/>
                But creating a network that will transform photos to 3D avatars is not enough. We are working on
                developing the mechanics of each character, figuring out how they will communicate with their
                surroundings in the metaverse. 3D animation of each avatar is just as important. We are
                developing programs that will let each character walk, jump, run, and so on. The most important
                feature of the Metaverse is that its users can do everything they are used to doing – and of
                course, it starts with physical abilities that anyone can’t imagine one’s life without.
            </>
        },
        {
            h3: 'Stage Two – Developing Raritygram Metaverse',
            p: 'Next, our team will start developing 3D objects that will gradually fill all of the metaverse. These objects include buildings, landscape, roads, and much more. We have a lot of work ahead of us, and we can’t wait to see how all of this will unfold.'
        },
        {
            h3: 'Stage Three – Development of the Multiplayer Features',
            p: 'The metaverse will allow each of the users to interact with each other. The third stage of the RarityMeta development will include building communication features.'
        },
        {
            h3: 'Stage Four – Smart Contract Development',
            p: 'A vital part of Raritygram Metaverse will be an opportunity to participate in economic processes. At this stage, we will work on developing the features that will allow users to make offers and transactions between each other.'
        },
        {
            h3: 'Stage Five – Integrating Mini Games into Our Metavers',
            p: 'Metaverse should be fun – we cannot turn a blind eye on recreational needs of the users. We will develop and implement many exciting mini games that will make your experience in RarityMeta much more fun.'
        },
        {
            h3: 'How to Join the Metaverse?',
            p: 'Anyone who has bought at least one NFT Rarity card will have access to RarityMeta. The higher the level of the card one has, the more perks one will get when becoming a member of the metaverse community. Such benefits include more lands and tokens one receives after completing the registration. We hope that you are excited about exploring all the opportunities of Raritygram Metaverse! This is one of the biggest and most time-consuming projects our team has worked on, and we know it is worth all the effort we have put into it. Keep up with the updates not to miss on anything important and thank you for your support.'
        },
        {
            h3: 'How to Join the Metaverse?',
            p: 'Metaverse should be fun – we cannot turn a blind eye on recreational needs of the users. We will develop and implement many exciting mini games that will make your experience in RarityMeta much more fun.'
        }
    ]
};