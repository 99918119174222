export const virtualLife = {
    name: 'virtuallife',
    mainTitle: 'New level of virtual life',
    subtitle: 'Raritygram metaverse is a virtual world where users can interact with each other, make deals and various operations, buy land and build any objects, integrate one\'s own mini-games, create NFT art galleries and much more',
    mainImage: 'ksenia.jpg',
    mainImagePosition: 'center bottom',
    mainImageSize: 'auto',
    mobileMainImagePosition: '-876px bottom',
    rightContent: <div className="model">
            <model-viewer src="/nadya 3.glb" alt="Ksenia" auto-rotate camera-controls/>
        </div>,
    model: true,
    firstCards: {
        title: 'Feel all metaverse possibilities',
        cards: [
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 36V34C14 28.4772 18.4772 24 24 24C29.5228 24 34 28.4772 34 34V36" stroke="black" strokeWidth={3} strokeLinecap="round" />
                    <path d="M24 24C27.3138 24 30 21.3138 30 18C30 14.6863 27.3138 12 24 12C20.6862 12 18 14.6863 18 18C18 21.3138 20.6862 24 24 24Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M42 7.2V40.8C42 41.4628 41.4628 42 40.8 42H7.2C6.53726 42 6 41.4628 6 40.8V7.2C6 6.53726 6.53726 6 7.2 6H40.8C41.4628 6 42 6.53726 42 7.2Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: 'Personal digital copy',
                text: 'Create an avatar thanks to a neural network. Each NFT Rarity owner can upload a photo to their personal account to get an avatar that will look just like a digital copy of a person'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 32C28.4182 32 32 28.4182 32 24C32 19.5817 28.4182 16 24 16C19.5817 16 16 19.5817 16 24C16 28.4182 19.5817 32 24 32Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M42 14.7052V33.2931C42 33.7289 41.7638 34.1303 41.3828 34.3421L24.5828 43.6753C24.2204 43.8767 23.7796 43.8767 23.4172 43.6753L6.61726 34.3421C6.23632 34.1303 6.00004 33.7289 6.00004 33.2931L6 14.7052C6 14.2694 6.23628 13.8679 6.61724 13.6562L23.4172 4.32288C23.7796 4.12154 24.2204 4.12154 24.5828 4.32288L41.3828 13.6562C41.7636 13.8679 42 14.2694 42 14.7052Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.05664 14.5863L16.7998 19.9992M40.9998 14.5547L31.1998 19.9992" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M24 42V32" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: 'International deals on metaverse',
                text: 'Rarity Government and Rarity ID tools offer users to make international transactions and deals within the metaverse, which saves time and money.'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40 40H8V8" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8 33L24 18L30 24L39 15" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: 'Acceleration of economic processes',
                text: 'Buy estate, intellectual and physical property, conclude contracts and conduct other economic procedures in a simpler and more convenient format'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 4C12.9543 4 4 12.9543 4 24C4 35.0456 12.9543 44 24 44C35.0456 44 44 35.0456 44 24C44 12.9543 35.0456 4 24 4Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M16 42.3358V28L24 14L32 28V42.3358" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M16 28C16 28 18.2535 30 20 30C21.7464 30 24 28 24 28C24 28 26.2536 30 28 30C29.7464 30 32 28 32 28" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: 'Platform for artists',
                text: 'Artists, illustrators and other creative people can find their vocation on Raritygram metaverse or evolve in their field by creating, publishing, monetising their works of art and sharing them with other users.'
            }
        ]
    },
    roadmap: true,
    banner: {
        dark: true,
        title: 'Join the metaverse',
        subtitle: 'The metaverse can be entered by owners of NFT Rarity cards of any level. The higher the level of the card, the more benefits the user gets, including the number of lands and tokens received',
        image: 'ksenia-baner.jpg',
        imagePosition: 'left top',
        mobileImage: 'ksenia-baner.jpg',
        mobileImagePosition: 'right -145px',
    }
};