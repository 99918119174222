import './AuthorWrap.scss';

export default function AuthorWrap({authorAvatar, authorName, authorPost}) {

    return (
        <div className="devs-item__author-wrap flex">
            <img src={`/img/mainPage/our-team/${authorAvatar}`} alt="Avatar" className="devs-item__author-avatar"/>
            <div className="devs-item__author-name">
                {authorName}
                <div className="devs-item__author-post">
                    {authorPost}
                </div>
            </div>
        </div>
    );
}