export const navItems = [
    {
        name: 'whitepaper',
        href: 'https://raritygram.gitbook.io/raritygram',
    },
    {
        name: 'devblog',
        to: '/blog/devblog',
    },
    {
        name: 'Mint NFTRarity',
        to: '/mint',
    },
    {
        name: 'raritygram',
        href: 'https://raritygram.com/',
    }
];