export  const blockchain = [
    {
        svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={52} height={52} rx={8} fill="#EBF1F5"/>
            <path d="M36.4995 38.9609C37.6593 38.9609 38.5996 38.0206 38.5996 36.8608C38.5996 35.701 37.6593 34.7607 36.4995 34.7607C35.3397 34.7607 34.3994 35.701 34.3994 36.8608C34.3994 38.0206 35.3397 38.9609 36.4995 38.9609Z" fill="#0C2A3E" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.5014 38.9609C23.6612 38.9609 24.6015 38.0206 24.6015 36.8608C24.6015 35.701 23.6612 34.7607 22.5014 34.7607C21.3416 34.7607 20.4014 35.701 20.4014 36.8608C20.4014 38.0206 21.3416 38.9609 22.5014 38.9609Z" fill="#0C2A3E" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M39.9991 13.7607H32.2988H24.2485H16.1982L18.9983 29.1613H25.2986H30.8988H37.199L39.9991 13.7607Z" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.2021 13.7576C15.9688 12.8243 14.8021 10.9575 12.002 10.9575" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M37.2001 29.1592H18.9995H16.5224C14.0243 29.1592 12.6992 30.2529 12.6992 31.9593C12.6992 33.6657 14.0243 34.7594 16.5224 34.7594H36.5001" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        title: 'Merchandising',
        text: 'Take advantages of creating and using your own merchandise for a business to protect your  intellectual properties, and solve the problem of selling counterfeit goods.'
    },
    {
        svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={52} height={52} rx={8} fill="#EBF1F5"/>
            <path d="M19 22.5H33" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.25 29.5H19" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M33 29.5H34.75" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.25 36.5H34.75M10.25 36.5V24.9684C10.25 24.4945 10.3463 24.0253 10.533 23.5897L14.5909 14.1213C15.1424 12.8344 16.4078 12 17.8079 12H34.1921C35.5923 12 36.8575 12.8344 37.4091 14.1213L41.467 23.5897C41.6538 24.0253 41.75 24.4945 41.75 24.9684V36.5H10.25ZM10.25 36.5V40.7C10.25 41.2799 10.7201 41.75 11.3 41.75H16.2C16.7799 41.75 17.25 41.2799 17.25 40.7V36.5H10.25ZM10.25 36.5H17.25H10.25ZM41.75 36.5V40.7C41.75 41.2799 41.2799 41.75 40.7 41.75H35.8C35.2201 41.75 34.75 41.2799 34.75 40.7V36.5H41.75ZM41.75 36.5H34.75H41.75Z" stroke="#0C2A3E" strokeWidth={3}/>
        </svg>,
        title: 'Vehicles',
        text: 'Use of the blockchain in the transportation system is possible thanks to new technologies of optimizing operations, introducing new features to users and driving revenue.'
    },
    {
        svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={52} height={52} rx={8} fill="#EBF1F5"/>
            <path d="M26 10C17.1634 10 10 17.1634 10 26C10 34.8365 17.1634 42 26 42C34.8365 42 42 34.8365 42 26C42 17.1634 34.8365 10 26 10Z" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20 41V29.3637L26 18L32 29.3637V41" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20 29C20 29 21.6901 31 23 31C24.3098 31 26 29 26 29C26 29 27.6902 31 29 31C30.3098 31 32 29 32 29" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        title: 'Lending',
        text: 'Use your NFTs as collateral for a loan, with attractive interest rates and loan durations, which are discussed with the lender. Transaction is secured in the blockchain.'
    },
    {
        svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={52} height={52} rx={8} fill="#EBF1F5" />
            <path d="M27.0846 29.294V39.9646H12.2386C11.2137 39.9646 10.3828 39.1337 10.3828 38.1088V29.294L18.7337 22.335L27.0846 29.294Z" stroke="#0C2A3E" strokeWidth={3} />
            <path d="M38.2164 14.4462L39.3186 13.4288L39.2983 13.4068L39.2771 13.3856L38.2164 14.4462ZM27.9878 31.845C28.629 32.3696 29.574 32.275 30.0986 31.6339C30.6232 30.9927 30.5287 30.0477 29.8875 29.5231L27.9878 31.845ZM7.57456 29.5231C6.93339 30.0477 6.83889 30.9927 7.36348 31.6339C7.88807 32.275 8.8331 32.3696 9.47427 31.845L7.57456 29.5231ZM35.4328 11.6626L36.4934 10.6019L36.0541 10.1626H35.4328V11.6626ZM41 17.4618H42.5V16.8753L42.1022 16.4444L41 17.4618ZM27.0819 41.4628H39.1443V38.4628H27.0819V41.4628ZM17.231 13.5183V22.3332H20.231V13.5183H17.231ZM17.7812 23.4941L27.9878 31.845L29.8875 29.5231L19.6809 21.1722L17.7812 23.4941ZM17.7812 21.1722L7.57456 29.5231L9.47427 31.845L19.6809 23.4941L17.7812 21.1722ZM39.2771 13.3856L36.4934 10.6019L34.3721 12.7233L37.1557 15.5069L39.2771 13.3856ZM35.4328 10.1626H20.5868V13.1626H35.4328V10.1626ZM42.5 38.107V17.4618H39.5V38.107H42.5ZM42.1022 16.4444L39.3186 13.4288L37.1142 15.4636L39.8978 18.4792L42.1022 16.4444ZM20.231 13.5183C20.231 13.3219 20.3903 13.1626 20.5868 13.1626V10.1626C18.7335 10.1626 17.231 11.665 17.231 13.5183H20.231ZM39.1443 41.4628C40.9976 41.4628 42.5 39.9604 42.5 38.107H39.5C39.5 38.3035 39.3407 38.4628 39.1443 38.4628V41.4628Z" fill="#0C2A3E" />
        </svg>,
        title: 'Estate',
        text: <>Feel all of the benefits of the blockchain from ownership transfers to price negotiations. The technology offers a simple and secure way to perform complex transactions of buying and selling estate.<br/><br/>Complete transactions at an incredible speed using the Raritygram blockchain. Sell and buy items on the platform with minimal restrictions.</>
    },
    {
        svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={52} height={52} rx={8} fill="#EBF1F5" />
            <path d="M40.3971 14.1341C40.3971 16.9243 34.0638 19.1862 26.2513 19.1862C18.4388 19.1862 12.1055 16.9243 12.1055 14.1341M40.3971 14.1341C40.3971 11.3439 34.0638 9.08203 26.2513 9.08203C18.4388 9.08203 12.1055 11.3439 12.1055 14.1341M40.3971 14.1341V38.3841C40.3971 41.1743 34.0638 43.4362 26.2513 43.4362C18.4388 43.4362 12.1055 41.1743 12.1055 38.3841V14.1341M40.3971 22.2174C40.3971 25.0076 34.0638 27.2695 26.2513 27.2695C18.4388 27.2695 12.1055 25.0076 12.1055 22.2174M40.3971 30.3008C40.3971 33.091 34.0638 35.3529 26.2513 35.3529C18.4388 35.3529 12.1055 33.091 12.1055 30.3008" stroke="#0C2A3E" strokeWidth={3} />
        </svg>,
        title: 'Convenient Exchange',
        text: 'Transactions on the Raritygram blockchain can take place even with small sums. Conduct instant transactions at any price convenient for you.'
    },
    {
        svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={52} height={52} rx={8} fill="#EBF1F5"/>
            <path d="M32.3434 28.8788L21.6301 34.4905L16.6803 29.5407L22.292 18.8274C23.8923 15.7725 26.8905 13.6981 30.3135 13.2777L37.6202 12.3804C38.298 12.2972 38.8736 12.8728 38.7904 13.5505L37.893 20.8573C37.4727 24.2802 35.3983 27.2785 32.3434 28.8788Z" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.9678 33.2534C10.4929 35.7283 11.7303 39.4406 11.7303 39.4406C11.7303 39.4406 15.4426 40.678 17.9175 38.2032" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.3922 22.1163C12.9675 20.8788 10.4927 28.3035 10.4927 28.3035L16.6798 29.5409" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M29.5886 31.3127C30.826 38.7373 23.4014 41.2122 23.4014 41.2122L22.164 35.025" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26.5798 24.5916C25.2129 23.2247 25.2129 21.0087 26.5798 19.6419C27.9466 18.275 30.1627 18.275 31.5296 19.6419C32.8964 21.0087 32.8964 23.2248 31.5296 24.5916C30.1627 25.9585 27.9467 25.9585 26.5798 24.5916Z" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        title: 'Fast Payouts',
        text: 'Make transactions with an incredible speed using the Raritygram blockchain. Sell and buy items on the platform at almost any cost.'
    }
];