import './Grade.scss';
import {useState} from "react";
import {tabContents, tabs} from "./grades";
import GradeTrait from "./GradeTrait/GradeTrait";
import Button from "../../../components/Button/Button";
import cn from "classnames";
// import {useWeb3Enabled} from "../../../hooks/useWeb3Enabled";
import {useEffect} from "react";
import {useMoralis, useMoralisWeb3Api} from "react-moralis";
import {useRecoilState} from "recoil";
import {currentBalanceState} from "../../../balance";
import Moralis from "moralis";
import {contractABI, contractAddress} from "../../../contract";
import {confirmedState} from "../../MainPage/confirmedState";
import MetamaskButton from "../../../components/MetamaskButton/MetamaskButton";
import {leftTokensInCollection} from "../../../functions";
import {nftCards} from "../../../data/nfts";

export default function Grade() {

    const Web3Api = useMoralisWeb3Api();
    // const isWeb3Enabled = useWeb3Enabled();
    const {isAuthenticated, user, isWeb3Enabled, enableWeb3} = useMoralis();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [currentBalance, setCurrentBalance] = useRecoilState(currentBalanceState);
    const [currentMintValue, setCurrentMintValue] = useState(nftCards[0].value);
    const [mintCollectionId, setMintCollectionId] = useState(0);
    const [tokensLeft, setTokensLeft] = useState(null);
    const [tokensMinted, setTokensMinted] = useState([]);
    const [isConfirmed, setIsConfirmed] = useRecoilState(confirmedState);
    const [loading, setLoading] = useState(false);
    const noMoney = currentMintValue > currentBalance;

    const isMetamaskInstall = window.ethereum && window.ethereum.isMetaMask;

    useEffect(() => {
        const f = async () => {
            if (!isWeb3Enabled) await enableWeb3()
            if (isAuthenticated) {
                await getNativeBalance();
            }
        }
        f();

        Promise.all([
            leftTokensInCollection(0),
            leftTokensInCollection(1),
            leftTokensInCollection(2),
            leftTokensInCollection(3)
        ]).then(tokensMintedArr => {
            const tokensLeft = tokensMintedArr.map((tokensMinted, index) => {
                return nftCards[index].maxTokens - tokensMinted;
            });

            setTokensMinted(tokensMintedArr)
            setTokensLeft(tokensLeft)
        })

    }, [isWeb3Enabled]);

    // getBalance('0xb9de2650164B6490C479366B2dD79eF11432168F')

    useEffect(() => {
        setIsConfirmed(false);
    }, [mintCollectionId]);

    const getNativeBalance = async () => {
        const balance = await Web3Api.account.getNativeBalance({chain: process.env.REACT_APP_PUBLIC_CHAIN});
        const balanceNumber = Number(balance.balance)
        setCurrentBalance(balanceNumber)
    };

    const onMintButton = async (e) => {
        e.preventDefault();

        try {
            const transaction = await mintNft(user.get("ethAddress"), 1, mintCollectionId, currentMintValue);
            setLoading(true)
            const check = await checkTransaction(transaction)
            if (check.status) {
                // setTransaction(check.transaction)
            } else {
                // setTransaction(check.transaction)
            }
            setLoading(false)

        } catch (error) {
            setLoading(false)
        }
    };

    const mintNft = async (address, amount, collectionId) => {
        return await Moralis.executeFunction({
            contractAddress: contractAddress,
            functionName: "mint",
            abi: contractABI,
            msgValue: currentMintValue * amount,
            params: {
                _to: address,
                _amount: amount,
                _collectionId: collectionId
            }
        });
    };

    const checkTransaction = async (transaction) => {
        const check = await transaction.wait(3);

        if (check.status) {
            setIsConfirmed(true);

            return {
                status: true,
                transaction: check.transactionHash
            }
        } else return {
            status: false,
            transaction: check.transactionHash
        }
    };


    const tabList = tabs.map((item, index) => {

        const onTabClick = async () => {
            if (isAuthenticated) {
                await getNativeBalance();
            }
            setCurrentMintValue(nftCards[index].value);
            setMintCollectionId(index)
            setActiveTabIndex(index)
        };

        return (
            <li className={`grade-section__tab ${activeTabIndex === index && 'grade-section__tab_active'}`}
                onClick={onTabClick}
                key={item}
            >
                <div className="grade-section__tab-inner flex">
                    {item}
                </div>
            </li>
        );
    });
    const contentList = tabContents.map((item, index) => {
        const traitsList = item.list.map((item) => {
            return (
                <GradeTrait item={item} key={item.name}/>
            );
        });
        return (
            <li className={`grade-section__item grade-item flex ${index === activeTabIndex && 'grade-item_active'}`}
                key={index}
            >
                <div className="grade-item__content flex">
                    <h2 className="grade-item__title">Raritygram <span className={item.colorClass}>{item.title}</span>
                    </h2>
                    <ul className="grade-item__access-list flex">
                        <li className="grade-item__access-item">
                            {item.traits} traits
                        </li>
                        <li className="grade-item__access-item">
                            {item.categories} categories
                        </li>
                    </ul>
                    <ul className="grade-item__list flex">
                        {traitsList}
                    </ul>

                    <div className="grade-item__buttons flex">
                        {isAuthenticated ? (
                            <>
                                {isConfirmed ? (
                                    <Button
                                        to={'/my-collection'}
                                        className={cn('button_purple grade-item__mint-button', {
                                            'main-mint__mint-button_disabled': !isWeb3Enabled
                                        })}
                                        icon={'img/mainPage/check.svg'}
                                    >
                                        Confirmed
                                    </Button>
                                ) : (
                                    <Button
                                        href={'#'}
                                        className={cn('button_purple main-mint__mint-button main-mint__mint-button_disabled')}
                                    >
                                        Soon
                                    </Button>

                                    // <Button
                                    //     href={'#'}
                                    //     onClick={onMintButton}
                                    //     style={{background: item.color}}
                                    //     className={cn(`button_purple grade-item__mint-button ${item.color}`, {
                                    //         'main-mint__mint-button_disabled': noMoney || !isWeb3Enabled,
                                    //         'main-mint__mint-button_pending': loading
                                    //     })}
                                    // >
                                    //     {noMoney ? ('Not enough money, fill up your wallet') : (loading ? 'Pending' : 'Mint')}
                                    //     {loading ? <div className={'pending-bar'}/> : null}
                                    // </Button>
                                )}
                            </>
                        ) : (
                            <MetamaskButton/>
                            // <Button
                            // to={'/connect'}
                            // className={'button-connect button-connect__header'}
                            // >
                            // <div className={'button-connect__inner'}>
                            // Connect wallet
                            // </div>
                            // </Button>
                        )}

                        <Button
                            href={'https://www.youtube.com/watch?v=l5U4DnhqBnw'}
                            className={'grade-item__how-button'}
                            icon={'img/mintPage/grade/arrow.svg'}
                        >
                            How to mint
                        </Button>
                    </div>
                </div>
                <div className="grade-item__video-wrap flex">
                    <video className="grade-item__video" autoPlay muted loop playsInline>
                        <source src={`img/mintPage/videos/${item.video}`} type="video/mp4"/>
                    </video>
                    <div className="grade-item__video-card video-card">
                        <div className="video-card__info flex">
                            <div className="video-card__name">
                                Mint now
                            </div>
                            <div className="video-card__left">
                                {isMetamaskInstall ?
                                    `left ${tokensLeft && tokensLeft[index]} cards`
                                    :
                                    `left ${item.tokensLeft} cards`
                                }
                            </div>
                        </div>
                        <div className="video-card__bar-wrap">
                            <div className="video-card__bar-numbers flex">
                                <span>{nftCards[index].maxTokens}</span>
                            </div>
                            <div className="video-card__progress-bar">


                                {isMetamaskInstall ? (
                                    <>
                                        <span
                                            style={{left: `${(tokensMinted[index] * 100) / nftCards[index].maxTokens}%`}}
                                            className="video-card__current-result">{tokensMinted[index]}
                                        </span>
                                        <div
                                            style={{width: `${(tokensMinted[index] * 100) / nftCards[index].maxTokens}%`}}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <span
                                            style={{left: `${(item.tokensMinted * 100) / nftCards[index].maxTokens}%`}}
                                            className="video-card__current-result">{item.tokensMinted}
                                        </span>
                                        <div
                                            style={{width: `${(item.tokensMinted * 100) / nftCards[index].maxTokens}%`}}
                                        />
                                    </>
                                )}

                                <img src="img/mintPage/grade/bar-lines.png" alt="Bar"
                                     className="video-card__bar-lines"/>


                            </div>
                        </div>
                    </div>
                </div>

                <div className="grade-item__buttons grade-item__buttons-mobile flex">
                    {/*<Button*/}
                    {/*    href={'#'}*/}
                    {/*    onClick={onMintButton}*/}
                    {/*    style={{background: item.color}}*/}
                    {/*    className={cn(`button_purple grade-item__mint-button ${item.color}`, {*/}
                    {/*        'main-mint__mint-button_disabled': noMoney || !isWeb3Enabled,*/}
                    {/*        'main-mint__mint-button_pending': loading*/}
                    {/*    })}*/}
                    {/*>*/}
                    {/*    {noMoney ? ('Not enough money, fill up your wallet') : (loading ? 'Pending' : 'Mint')}*/}
                    {/*    {loading ? <div className={'pending-bar'}/> : null}*/}
                    {/*</Button>*/}

                    <Button
                        href={'#'}
                        className={cn('button_purple main-mint__mint-button main-mint__mint-button_disabled')}
                    >
                        Soon
                    </Button>

                    <div className="button grade-item__how-button flex">
                        How to mint
                        <svg width={25} height={24} viewBox="0 0 25 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.40588 4.53592C7.00592 4.2989 6.5 4.58718 6.5 5.05209V18.9461C6.5 19.411 7.00592 19.6993 7.40588 19.4623L19.129 12.5153C19.5211 12.2829 19.5211 11.7153 19.129 11.4829L7.40588 4.53592Z"
                                fill="#ffffff"/>
                        </svg>
                    </div>
                </div>
            </li>
        );
    });


    return (
        <section id={'grade'} className="grade-section section">
            <div className="container">
                <div className="grade-section__wrap">
                    <div className="grade-section__tabs-wrap">
                        <ul className="grade-section__tabs flex">
                            {tabList}
                        </ul>
                    </div>
                    <div className="grade-section__content">
                        <ul className="grade-section__list">
                            {contentList}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}