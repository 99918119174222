import './MetamaskButton.scss';
import {useMoralis} from "react-moralis";
import cn from "classnames";
import {useEffect, useState} from "react";
import {checkChainId} from "../../functions";
import metamaskIcon from "../../icons/metamask-icon.svg"

export default function MetamaskButton(props) {

    const {isAuthenticated, authenticate} = useMoralis();
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobileDevice(true)
        } else {
            setIsMobileDevice(false)
        }
    }, []);

    const onAuthButtonClick = async () => {

        if (!isAuthenticated) {
            await checkChainId()

            await authenticate({signingMessage: "Connect to Raritygram.io"})
                .then(function (user) {
                    console.log("logged in user:", user);
                    console.log(user.get("ethAddress"));
                })
                .catch(function (error) {
                    console.log(error);
                });

        }
    };


    return (
        <>
            {
                isMobileDevice ? (
                    window.ethereum && window.ethereum.isMetaMask ? (
                        <div
                            onClick={onAuthButtonClick}
                            className={cn('metamask-button flex', {'metamask-button_header': props.header})}
                        >
                            <img src={metamaskIcon} alt="Meatamask"/>
                            Connect Metamask
                        </div>
                    ) : (
                        <a
                            href="https://metamask.app.link/dapp/raritygram.io"
                            target="_blank"
                            rel="noreferrer"
                            className={cn('metamask-button flex', {'metamask-button_header': props.header})}
                        >
                            <img src={metamaskIcon} alt="Meatamask"/>
                            Connect Metamask
                        </a>
                    )

                ) : (
                    <div
                        onClick={onAuthButtonClick}
                        className={cn('metamask-button flex', {'metamask-button_header': props.header})}
                    >
                        <img src={metamaskIcon} alt="Meatamask"/>
                        Connect Metamask
                    </div>
                )
            }
        </>
    );
}