import './Identity.scss';
import MainCard from "../../../components/MainCard/MainCard";
import {identity} from "./identityItems";
import Slider from "react-slick";
import {useRef} from "react";

export default function Identity() {

    const SliderRef = useRef(null);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        appendDots: dots => (
            <div className={'slider__nav-wrap'}>
                <ul>{dots}</ul>
            </div>
        )
    };

    const identityList = identity.map((item)=> {
        return (
            <MainCard className={'identity__card'} item={item} button={item.button} key={item.title}/>
        );
    });

    const identitySliderList = identity.map((item)=> {
        return (
            <div className="slider__item" key={item.title}>
                <MainCard className={'identity__card'} item={item} button={item.button}/>
            </div>
        );
    });

    return (
      <section className={'identity'}>
          <div className="container">
              <h2 className="identity__title">
                  Raritygram Identity
              </h2>
              <ul className={'identity__list'}>
                  {identityList}
              </ul>

              <div className="identity__slider">
                  <Slider ref={SliderRef} {...settings}>
                      {identitySliderList}
                  </Slider>
              </div>
          </div>
      </section>
    );
}