import './DevBlogDevItem.scss';
import AuthorWrap from "../../../components/AuthorWrap/AuthorWrap";
import ItemDate from "../../../components/ItemDate/ItemDate";
import {Link} from "react-router-dom";

export default function DevBlogDevItem({item}) {

    return (
        <li className="devblog-devs__item devs-item">
            <Link to={`/devblog/${item.name}`} className="devs-item__link devs-item__inner flex">
                <div className="devs-item__content flex">
                    <div className="devs-item__desc-wrap">
                        <ItemDate date={item.date} time={item.time}/>
                        <h2 className="devs-item__title">

                            {item.title}
                        </h2>
                        <div className="devs-item__paragraph">
                            {item.shortDesc}
                        </div>
                    </div>
                    <AuthorWrap authorAvatar={item.authorAvatar} authorName={item.authorName} authorPost={item.authorPost}/>
                </div>
                <div className="devs-item__img">
                    <img src={`/img/articles/${item.mainImage}`} alt="#"/>
                </div>
            </Link>
        </li>
    );
}