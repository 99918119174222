import './ConnectPage.scss';
import {Link, useNavigate} from "react-router-dom";
import Button from "../../components/Button/Button";
import {useMoralis} from "react-moralis";
import {useEffect, useState} from "react";
import {checkChainId} from "../../functions";

export default function ConnectPage() {

    let navigate = useNavigate();
    const {isAuthenticated, authenticate} = useMoralis();
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobileDevice(true)
        } else {
            setIsMobileDevice(false)
        }
    }, []);

    const onMetamaskButtonClick = async () => {
        if (!isAuthenticated) {
            await checkChainId()
            await authenticate({signingMessage: "Connect to Raritygram.io"})
                .then(function (user) {
                    console.log("logged in user:", user);
                    console.log(user?.get("ethAddress"));
                    if (user) {
                        navigate("/", {replace: true});
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };

    const onWalletConnectButtonClick = async () => {

        if (!isAuthenticated) {
            // await checkChainId()
            await authenticate({provider: "walletconnect", chainId: 56 })
                .then(function (user) {
                    console.log(user.get("ethAddress"));
                    if (user) {
                        navigate("/", {replace: true});
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }
    };


    return (
        <section className={'connect-page'}>
            <div className="container flex">
                <div className="connect-page__main">
                    <Link to={'/'}>
                        <img src="img/mainPage/logo-raritygram.svg" alt="Raritygram.io"/>
                    </Link>
                    <div className="connect-page__connection main-connect">
                        <h1>Choose wallet</h1>
                        <p>if you own our nft, log in from the wallet from which you purchased it</p>
                        <div className="main-connect__wallets">

                            <div className="main-connect__wallet-buttons flex">
                                {
                                    isMobileDevice ? (
                                        window.ethereum && window.ethereum.isMetaMask ? (
                                            <Button
                                                onClick={onMetamaskButtonClick}
                                                icon={'img/mainPage/metamask-icon.svg'}
                                                to={'#'}
                                                className={'connect-page__wallet-button'}
                                            >
                                                Metamask
                                            </Button>
                                        ) : (
                                            <Button
                                                href="https://metamask.app.link/dapp/raritygram.io"
                                                icon={'img/mainPage/metamask-icon.svg'}
                                                to={'#'}
                                                className={'connect-page__wallet-button'}
                                            >
                                                Metamask
                                            </Button>
                                        )
                                    ) : (
                                        <Button
                                            onClick={onMetamaskButtonClick}
                                            icon={'img/mainPage/metamask-icon.svg'}
                                            to={'#'}
                                            className={'connect-page__wallet-button'}
                                        >
                                            Metamask
                                        </Button>
                                    )
                                }

                                <Button
                                    onClick={onWalletConnectButtonClick}
                                    icon={'img/mainPage/walletconnect-icon.svg'}
                                    to={'#'}
                                    className={'connect-page__wallet-button'}
                                >
                                    WalletConnect
                                </Button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}