export const getCurrentStage = (totalBnb) => {
    if(totalBnb <= 300) return {stage: 1, maxBnb: 300}
    if(totalBnb > 300 && totalBnb < 600) return {stage: 2, maxBnb: 600}
    if(totalBnb > 600 && totalBnb < 900) return {stage: 3, maxBnb: 900}
    if(totalBnb > 900 && totalBnb < 1200) return {stage: 4, maxBnb: 1200}
    if(totalBnb > 1200 && totalBnb < 1500) return {stage: 5, maxBnb: 1500}
    if(totalBnb > 1500 && totalBnb < 1800) return {stage: 6, maxBnb: 1800}
    if(totalBnb > 1800 && totalBnb < 2100) return {stage: 7, maxBnb: 2100}
    if(totalBnb > 2100 && totalBnb < 2400) return {stage: 8, maxBnb: 2400}
    if(totalBnb > 2400 && totalBnb < 2700) return {stage: 9, maxBnb: 2700}
    if(totalBnb > 2700 && totalBnb < 3000) return {stage: 10, maxBnb: 3000}
    if(totalBnb > 3000 && totalBnb < 3300) return {stage: 11, maxBnb: 3300}
    if(totalBnb > 3300 && totalBnb < 3600) return {stage: 12, maxBnb: 3600}
    if(totalBnb > 3600 && totalBnb < 3900) return {stage: 13, maxBnb: 3900}
    if(totalBnb > 3900 && totalBnb < 4200) return {stage: 14, maxBnb: 4200}
    if(totalBnb > 4200) return {stage: 15, maxBnb: 4500}
};