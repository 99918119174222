import './Videos.scss';
import cn from "classnames";
import {useEffect, useRef, useState} from "react";

export default function Videos() {

    const videoRef = useRef();

    const [isCoverVisible, setIsCoverVisible] = useState(true);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const tabNames = ['Mint', 'About raritygram'];
    // const videos = [
    //     {
    //         cover: 'img/mainPage/videos/blue-cover.png',
    //         video: 'https://www.youtube.com/watch?v=dj9HHg2lIJQ'
    //     },
    //     {
    //         cover: 'img/mainPage/videos/green-cover.png',
    //         video: 'https://www.youtube.com/embed/TYasvgD5-Hg'
    //     }
    // ];
    const videos = [
        {
            cover: 'img/mainPage/videos/blue-cover.png',
            video: "https://www.youtube.com/embed/TYasvgD5-Hg"
        },
        {
            cover: 'img/mainPage/videos/green-cover.png',
            video: "https://www.youtube.com/embed/dj9HHg2lIJQ"
        }
    ];

    const activeVideo = videos[activeTabIndex];

    useEffect(()=> {
        videoRef.current?.load();
    },[activeTabIndex])

    const tabList = tabNames.map((item, index) => {
        return (
            <div
                className={cn("new-level__tab flex", {'new-level__tab_active': activeTabIndex === index})}
                onClick={() => setActiveTabIndex(index)}
                key={item}>{item}
            </div>
        );
    });

    return (
        <section className={'video-section'}>
            <div className="container">
                <div className="new-level__tabs flex">
                    <div
                        className="new-level__tab-back"
                        style={activeTabIndex === 0 ? {top: '6px', left: '6px'} : {top: '6px', left: 'calc(50% + 6px)'}}
                    />
                    {tabList}
                </div>
                <div className="video-section__videos">
                    {/*<video*/}
                    {/*    ref={videoRef}*/}
                    {/*    className={'video-section__video'}*/}
                    {/*    poster={activeVideo.cover}*/}
                    {/*    preload="auto"*/}
                    {/*    controls*/}
                    {/*    playsInline*/}
                    {/*>*/}
                    {/*    */}
                    {/*    <source src={activeVideo.video} type="video/mp4"/>*/}
                    {/*</video>*/}
                    <img style={isCoverVisible ? {display: 'block'} : {display: 'none'}} onClick={() => setIsCoverVisible(!isCoverVisible)} src={activeVideo.cover} alt="Cover"/>
                    <iframe src={activeVideo.video}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
            </div>
        </section>
    );
}