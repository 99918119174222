import './WriteAbout.scss';

export default function WriteAbout() {

    const companyImages = [
        {
            name: 'yahoo.svg',
            href: 'https://finance.yahoo.com/news/raritygram-whitelist-draws-100-000-110000865.html'
        },
        {
            name: 'benzinga.svg',
            href: 'https://www.benzinga.com/pressreleases/22/08/g28665016/raritygram-whitelist-draws-100-000-in-24-hours-for-access-to-web-3-0-network'
        },
        {
            name: 'cc-logo.png',
            href: 'https://coinchapter.com/raritygram-whitelist-draws-100000-in-24-hours-for-access-to-web-3-0-network'
        },
        {
            name: 'Global-FinTech-Series.png',
            href: 'https://globalfintechseries.com/blockchain/raritygram-whitelist-draws-100000-in-24-hours-for-access-to-web-3-0-network'
        },
        {
            name: 'newsbtc.png',
            href: '#'
        },
        {
            name: 'bitcoinist.svg',
            href: '#'
        },
        {
            name: 'bit-news.png',
            href: '#'
        },
        {
            name: 'coin-idol.svg',
            href: '#'
        },
        {
            name: 'coinspeaker.svg',
            href: '#'
        }

    ];

    const companies = companyImages.map((item) => {

        return (
            <a style={item.href === '#' ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}} href={item.href} className={'write-about__partner flex'} key={item.name}>
                <img src={`img/mainPage/write-about/${item.name}`} alt="Company"/>
            </a>
        );
    });

    return (
        <section className={'write-about'}>
            <div className="container">
                <h2>Write about us</h2>
                <div className="write-about__companies flex">
                    {companies}
                </div>
            </div>
        </section>
    );
}