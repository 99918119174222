export const voting = {
    name: 'voting',
    mainTitle: 'Voting service for social and commercial projects',
    subtitle: 'Hold processes without commissions with Raritygram smartchain blockchain, conduct surveys and voting to increase the effectiveness of the project',
    mainImage: 'voting-main.jpg',
    mainImagePosition: 'center center',
    rightContent: <img src="/img/opportunitiesPage/first-screen/voting-check.png" alt="Check" className=""/>,
    firstCards: {
        title: 'Fast and safe services',
        cards: [
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 40V38C4 30.268 10.268 24 18 24" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M31.608 24.6274C32.8916 23.218 35.1092 23.218 36.3928 24.6274C37.0416 25.3398 37.974 25.726 38.9364 25.681C40.8406 25.592 42.4088 27.1602 42.3198 29.0644C42.2748 30.0268 42.661 30.9592 43.3734 31.608C44.7828 32.8916 44.7828 35.1092 43.3734 36.3928C42.661 37.0416 42.2748 37.974 42.3198 38.9364C42.4088 40.8406 40.8406 42.4088 38.9364 42.3198C37.974 42.2748 37.0416 42.661 36.3928 43.3734C35.1092 44.7828 32.8916 44.7828 31.608 43.3734C30.9592 42.661 30.0268 42.2748 29.0644 42.3198C27.1602 42.4088 25.592 40.8406 25.681 38.9364C25.726 37.974 25.3398 37.0416 24.6274 36.3928C23.218 35.1092 23.218 32.8916 24.6274 31.608C25.3398 30.9592 25.726 30.0268 25.681 29.0644C25.592 27.1602 27.1602 25.592 29.0644 25.681C30.0268 25.726 30.9592 25.3398 31.608 24.6274Z" stroke="black" strokeWidth={3} />
                    <path d="M30.7266 34.0002L32.9086 36.182L37.2722 31.8184" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18 24C22.4182 24 26 20.4182 26 16C26 11.5817 22.4182 8 18 8C13.5817 8 10 11.5817 10 16C10 20.4182 13.5817 24 18 24Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: 'no fake votes',
                text: 'RarityID feature allows each user to have their own profile safely, which helps to increase voting honesty up to 100%'
            },
            {
                svg: <svg width={42} height={41} viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.99772 26.3327L14.665 39L39.9997 13.6654" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: 'processes go simple',
                text: 'Our blockchain allows Raritygram users to implement the infrastructure and availability of any action and simplify it'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 44C35.0456 44 44 35.0456 44 24C44 12.9543 35.0456 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0456 12.9543 44 24 44Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M30 17C28.63 15.63 26.2174 14.6771 24 14.6174M18 30C19.289 31.7186 21.6856 32.6988 24 32.782M24 14.6174C21.3618 14.5464 19 15.74 19 19C19 25 30 22 30 28C30 31.422 27.0724 32.8924 24 32.782M24 14.6174V11M24 32.782V37" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: 'cost-effective',
                text: 'All votes take place within Raritygram, which significantly reduces costs of holding every event'
            }
        ]
    },

    //...
    events: true,
    banner: {
        dark: true,
        title: 'Get started',
        subtitle: 'Hold voting events of any scale and direction for commercial, state and social organisations. All representatives of such organisations can use Rarity Vote feature.',
        image: 'voting-banner.jpg',
        imagePosition: '-60px center',
        mobileImage: 'voting-banner.jpg',
        mobileImagePosition: '-680px -660px',
    }
};