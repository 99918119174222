import './Collection.scss';
import NftCard from "./NftCard/NftCard";
import {useEffect} from "react";
import Button from "../../../components/Button/Button";

export default function Collection(props) {

    useEffect(() => {
        console.log('array', props.array)
    }, [])

    const nftCards = props.array.map(nft => {
        return <NftCard
            image={nft.image}
            name={nft.name}
            desc={nft.description}
            id={nft.token_id}
            score={nft.RarityScore}
            rank={nft.RarityRank}
            key={nft.name}
        />;
    });

    return (
        <section className={'collection'}>
            <div className="container">
                <h1 className={'collection__title'}>My collection</h1>
                <div className="collection__grid">
                    {props.array.length ?
                        nftCards
                        : (
                            <div className="collection__example-nft">
                                <div className={'collection__example-video'}>
                                    <ul>
                                        <li>access to beta test</li>
                                        <li>chat with developers</li>
                                        <li>other benefits</li>
                                    </ul>
                                    <video autoPlay muted loop playsInline>
                                        <source src="img/mintPage/videos/mint-back.mp4" type="video/mp4"/>
                                    </video>
                                </div>
                                <Button
                                    to={'/mint'}
                                    className={'button_purple collection__button'}
                                >
                                    Mint
                                </Button>
                            </div>
                        )}
                </div>
            </div>
        </section>
    );
}