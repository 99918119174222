import './MainCard.scss';
import cn from "classnames";

export default function MainCard({item, button = false, className}) {
    return (
        <div className={cn('main-card flex', { [`${className}`]: className })}>
            <div className="main-card__inner-main">
                <div className="main-card__inner-head flex">
                    <div className="main-card__inner-icon">
                        {item.svg}
                    </div>
                    <div>
                        {item.stage ?
                            <div className="main-card__inner-stage">
                                {item.stage}
                            </div> :
                            null
                        }
                        {item.substage ?
                            <div
                                style={{
                                    background: item.substageBackground && `${item.substageBackground}`,
                                    color: item.substageColor
                                }}
                                className="main-card__inner-stage">
                                {item.substage}
                            </div> :
                            null
                        }
                    </div>
                </div>
                <h3 className="main-card__inner-title" style={{ margin: `${item.svgMarginBottom ? `${item.svgMarginBottom}px` : '32px'} 0 12px`}}>
                    {item.title}
                </h3>
                <p className="main-card__inner-paragraph">
                    {item.text}
                </p>
            </div>
            {button ?
                <div className="main-card__inner-button">
                    {button}
                </div> :
                null
            }
        </div>
    );
}