import {raritygramupdate} from "./raritymetaupdates";
import {raritygramAndMetaverse} from "./raritygramAndMetaverse";
import {activeAugust} from "./activeAugust";
import {septemberUpdates} from "./septemberUpdates";
import {novemberUpdates} from "./novemberUpdates";

export const articles = [
    novemberUpdates,
    septemberUpdates,
    activeAugust,
    raritygramupdate,
    raritygramAndMetaverse,
];