import './Mint.scss';
import {Link} from "react-router-dom";
import {Link as ScrollLink} from "react-scroll";

export default function Mint() {
    return (
        <section className="section mint-section">
            <Link to={'/'} className="mint-section__mobile-logo">
                <img src="img/mainPage/logo-raritygram-white2.svg" alt="Rariatygram"/>
            </Link>
            <div className="container">
                <video className="mint-section__back-video" autoPlay muted loop playsInline>
                    <source src="img/mintPage/videos/mint-back.mp4" type="video/mp4"/>
                </video>
                <div className="mint-section__wrap">
                    <h1 className="mint-section__title">
                        NFTRarity your access to the ecosystem
                    </h1>
                    <p className="mint-section__subtitle">
                        Raritygram is a place for NFT collectors to join, show the NFT digital art they own to the
                        world, and share their journey in the NFT space with the world
                    </p>
                    <ScrollLink
                        className={'button button_purple mint-section__button'}
                        to={'grade'}
                        smooth={true}
                        offset={-96}
                    >
                        Mint
                    </ScrollLink>
                </div>
            </div>
        </section>
    );
}