import './MainFeatures.scss';
import {useEffect, useRef, useState} from "react";
import Lottie from "lottie-web";
import animationSearch from './search-body.json'

export default function MainFeatures() {

    const searchAnimationRef = useRef();
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        Lottie.loadAnimation({
            container: searchAnimationRef.current,
            animationData: animationSearch,
            renderer: 'svg',
        });
    }, []);

    return (
        <section className={'main-features'}>
            <div className="container">
                <h2 className="main-features__title">Our unique features</h2>
                <div className="main-features__content-wrap flex">

                    {/* first column */}
                    <div className="main-features__inner-content main-features__inner-content_first-column flex">
                        {/* 1 */}
                        <div
                            onMouseEnter={() => setIsShow(true)}
                            onMouseLeave={() => setIsShow(false)}
                            className="main-features__item flex"
                        >
                            <div className="main-features__item-inner">
                                <div className="main-features__bonus">
                                    <div className="main-features__bonus-inner">bonuses for NFTRarity holders</div>
                                </div>
                                <h3>page customization</h3>
                                <p>Customize your profile page based on your needs and design requirements.</p>
                                <img style={{bottom: isShow ? '0px' : '-40px'}}
                                     src="img/mainPage/featuresForYou/main-page.gif" alt="Main-page"/>
                            </div>
                        </div>
                        {/* 2 */}
                        <div className="main-features__item flex">
                            <div className="main-features__item-inner">
                                <h3>internal economy</h3>
                                <p>Raritygram allows its users to obtain and rent commodities, straight through the platform.</p>
                            </div>
                            <img src="img/mainPage/featuresForYou/dollar.png" alt="Dollar"/>
                        </div>
                        {/* 3 */}
                        <div className="main-features__item flex">
                            <div className="main-features__item-inner">
                                <h3>your activities hold value</h3>
                                <p>To like or comment on a post, each user is faced with a minimal gas fee. This way, Raritygram avoids spammers and rewards the most active users with special bonuses.</p>
                            </div>
                            <img src="img/mainPage/featuresForYou/comments.jpg" alt="Comments"/>
                        </div>
                    </div>

                    {/* second column */}
                    <div className="main-features__inner-content main-features__inner-content_second-column flex">
                        {/* 4 */}
                        <div className="main-features__item flex">
                            <div className="main-features__item-inner">

                                <h3>smart search</h3>
                                <p>Finding what you need has never been easier — use our convenient search filters to
                                    enhance your experience.</p>
                                <div className={'anim'} ref={searchAnimationRef}/>
                            </div>
                        </div>
                        {/* 5 */}
                        <div className="main-features__item flex">
                            <div className="main-features__item-inner">
                                <h3>property rights</h3>
                                <p>Your digital art is safe with us. We make sure NFTs are not copied and remain with its rightful user.</p>
                            </div>
                            <img src="img/mainPage/featuresForYou/check.png" alt="Check"/>
                        </div>
                        {/* 6 */}
                        <div className="main-features__item flex">
                            <div className="main-features__item-inner">
                                <h3>show your collections</h3>
                                <p>Share and show off your owned digital art on your profile and with others.</p>
                                <img src="img/mainPage/featuresForYou/collections.png" alt="Collections"/>
                            </div>
                        </div>
                        {/* 7 */}
                        <div className="main-features__item flex">
                            <div className="main-features__item-inner">
                                <h3>decentralized</h3>
                                <p>All your transactions and activities remain decentralized and not controlled by intermediaries.</p>
                            </div>
                            <img src="img/mainPage/featuresForYou/dao.png" alt="DAO"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}