import './OpportunitiesPage.scss';
import ProjectBy from "../MainPage/ProjectBy/ProjectBy";
import Socials from "../MainPage/Socials/Socials";
import Banner from "./Banner/Banner";
import SecondCards from "./SecondCards/SecondCards";
import FirstCards from "./FirstCards/FirstCards";
import FirstScreen from "./FistScreen/FirstScreen";
import {services} from "../../content/services";
import {useParams} from "react-router-dom";
import RoadMap from "../MainPage/RoadMap/RoadMap";
import Events from "./Events/Events";

export default function OpportunitiesPage() {

    const params = useParams();
    const service = services.find(service => service.name === params.name)

    return (
        <main>
            <FirstScreen item={service}/>
            {service.name === 'rent' ?
                <>
                    <FirstCards
                        title={service.yacht.firstCards.title}
                        cards={service.yacht.firstCards.cards}
                    />
                    <Banner item={service.yacht.banner}/>
                    <FirstCards
                        title={service.cars.firstCards.title}
                        cards={service.cars.firstCards.cards}
                    />
                    <Banner item={service.cars.banner}/>
                    <FirstCards
                        title={service.sky.firstCards.title}
                        cards={service.sky.firstCards.cards}
                    />
                    <Banner item={service.sky.banner}/>
                    <FirstCards
                        title={service.estate.firstCards.title}
                        cards={service.estate.firstCards.cards}
                    />
                    <Banner item={service.estate.banner}/>
                </> :
                <>
                    <FirstCards
                        title={service.firstCards.title}
                        cards={service.firstCards.cards}
                    />
                    {service.secondCards &&
                    <SecondCards
                        title={service.secondCards.title}
                        subtitle={service.secondCards.subtitle}
                        cards={service.secondCards.cards}
                    />
                    }
                    {service.roadmap && <RoadMap virtualPage={service.name === 'virtuallife'}/>}
                    {service.events && <Events/>}
                    <Banner item={service.banner}/>
                </>
            }
            <Socials/>
            <ProjectBy/>
        </main>
    );
}