import './Tooltip.scss';
import cn from "classnames";

export default function Tooltip(props) {

    return (
      <div className={cn('tooltip', {'tooltip_showed': props.isShowed})}>
          <div className="tooltip__inner">
              <div className={'tooltip__title'}>Traits rarity</div>
              <div className={'tooltip__gradient'}/>
              <div className={'tooltip__wrap flex'}>
                  default bonuses
                  <span>epic bonuses</span>
              </div>
          </div>
      </div>
    );
}