import './Crosschain.scss';
import Button from "../../../components/Button/Button";

export default function Crosschain() {
    return (
      <section className={'crosschain'}>
          <div className="container flex">
              <div className="crosschain__wrap">
                  <h2>Crosschain</h2>
                  <p>The Crosschain is a unique smart contract, which allows you to swap your RarityNFTs from one network to another, such as from BSC to ETH.</p>
                    <Button to={'#'} className={'crosschain__button'}>Crosschain NFT bridge</Button>
              </div>
              <img src="img/mainPage/crosschain-back.png" alt="Crosschain"/>
          </div>
      </section>
    );
}