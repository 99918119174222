export const ourTeam = [
    {
        avatar: 'kate.png',
        name: 'Kate',
        post: 'Creactive director',
    },
    {
        avatar: 'tati.png',
        name: 'Tati',
        post: 'Production director',
    },
    {
        avatar: 'mary.png',
        name: 'Mary',
        post: 'Content Manager',
    },
    {
        avatar: 'gleb.png',
        name: 'Gleb',
        post: 'Founder',
    },
    {
        avatar: 'ann.png',
        name: 'Ann',
        post: 'UX/UI designer',
    },
    {
        avatar: 'alex.png',
        name: 'Alex',
        post: 'Lead developer',
    },
    {
        avatar: 'thomas.png',
        name: 'Thomas',
        post: 'Chief Design officer',
    },
    {
        avatar: 'george.png',
        name: 'George',
        post: 'UX/UI designer',
    },
    {
        avatar: 'arya.png',
        name: 'Arya',
        post: 'Marketer',
    },
    {
        avatar: 'akmal.png',
        name: 'Akmal',
        post: 'Product manager',
    },
    {
        avatar: 'caroline.png',
        name: 'Caroline',
        post: 'Frontend developer',
    },
    {
        avatar: 'adrian.png',
        name: 'Adrian',
        post: 'Backend developer',
    }
];