import './Banner.scss';
import cn from "classnames";
import {useEffect, useState} from "react";

export default function Banner({item}) {


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
    }, [window.innerWidth]);

    return (
        <section className={cn('banner', {'banner_dark': item.dark})}>
            <div className="container">
                {/*<div*/}
                {/*    className={'banner__gradient'}*/}
                {/*    style={{*/}
                {/*        background: `linear-gradient(180deg, #253870 0%, #254C9C 47.92%, #265DBA 100%)`,*/}
                {/*    }}*/}
                {/*/>*/}
                <div className="banner__inner flex"
                     style={windowWidth >= 576 ?
                         {
                             backgroundImage: `url("../img/opportunitiesPage/banner/${item.image}")`,
                             backgroundPosition: `${item.imagePosition}`
                         } :
                         {
                             backgroundImage: `url("../img/opportunitiesPage/banner/${item.mobileImage}")`,
                             backgroundPosition: `${item.mobileImagePosition}`
                         }
                     }
                >
                    <div className="banner__inner-content">
                        <h2>{item.title}</h2>
                        <p>{item.subtitle}</p>
                    </div>
                    {item.notice &&
                        <div className="banner__inner-notice">
                            for NFT holders only
                        </div>
                    }
                </div>
            </div>
        </section>
    );
}