import './Events.scss';

export default function Events() {

    const items = [
      'Commercial elections',
      'Rating votes',
      'Social polls',
      'Commercial surveys',
      'Other voting/surveys of all kinds',
      'State elections'
    ];

    const itemList = items.map((item)=> {
       return (
           <div className="events__item flex" key={item}>
               <img src="../img/mainPage/token/purple-check.svg" alt="Check"/>
               <div className="events__item-name">{item}</div>
           </div>
       );
    });

    return (
      <section className={'events'}>
        <div className="container flex">
            <div className="events__content">
                <h2>Hold voting events</h2>
                <p>On Raritygram it’s convenient to hold diverse types of voting events within one platform, simplifying the process of finding target audiences and connecting them</p>
            </div>
            <div className="events__items flex">
                {itemList}
                <div className="events__item flex">
                    <img src="../img/mainPage/token/purple-check.svg" alt="Check"/>
                    <div className="events__item-name">Commercial elections</div>
                </div>
            </div>
        </div>
      </section>
    );
}