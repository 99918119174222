import './Footer.scss';
import {Link, useLocation} from "react-router-dom";
import {navItems} from "../../../data/navItems";
import Button from "../../Button/Button";
import {socialIcons} from "../../../data/socialIcons";

export default function Footer() {

    const {pathname} = useLocation();

    const navLinks = navItems.map((item) => {
        return (
            <li className="footer-nav__item" key={item.name}>
                {item.to ?
                    <Link to={item.to} className="footer-nav__link">{item.name}</Link>
                    :
                    <a href={item.href} target="_blank" rel={'noreferrer'}
                       className="footer-nav__link">{item.name}
                    </a>
                }
            </li>
        );
    });

    if (pathname === '/connect') {
        return false
    }

    return (
        <footer className="footer">
            <div className="container flex">
                <div className="footer__up footer-up flex">
                    <Link to={"/"} className="footer-up__logo">
                        <img src="/img/mainPage/footer-logo.svg" alt="Logo white"/>
                    </Link>

                    <ul className="footer-up__list footer-nav flex">
                        <li className="footer-nav__item">
                            <a
                                href={'https://bscscan.com/address/0xf995EFaBe838125f1320B4F537f1E1462fB11779'}
                                target="_blank"
                                rel={'noreferrer'}
                                className="footer-nav__link"
                            >
                                smartcontract
                            </a>
                        </li>

                        {navLinks}
                    </ul>
                </div>

                <div className="footer__down footer-down flex">
                    <ul className="footer-down__socials footer-socials flex">
                        <li className="footer-socials__item">
                            <a className="footer-socials__icon flex"
                               href="https://t.me/raritygram_news">
                                <svg width={16} height={12} viewBox="0 0 16 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.4875 0.0789028L1.20041 4.96642C0.293616 5.31384 0.298862 5.79638 1.03404 6.01155L4.44537 7.02665L12.3382 2.27639C12.7114 2.05979 13.0524 2.17631 12.7721 2.41364L6.37736 7.9188H6.37586L6.37736 7.91952L6.14205 11.2737C6.48678 11.2737 6.63891 11.1228 6.83226 10.9448L8.48921 9.40786L11.9358 11.8363C12.5713 12.1701 13.0277 11.9985 13.1858 11.2751L15.4483 1.10402C15.6798 0.218301 15.0938 -0.182737 14.4875 0.0789028Z"
                                        fill="#fff"/>
                                </svg>
                            </a>
                        </li>
                        <li className="footer-socials__item">
                            <a className="footer-socials__icon flex"
                               href="https://instagram.com/raritygram_io?igshid=YmMyMTA2M2Y=">
                                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.00203 5.91687C6.85515 5.91687 5.91922 6.85281 5.91922 7.99968C5.91922 9.14656 6.85515 10.0825 8.00203 10.0825C9.1489 10.0825 10.0848 9.14656 10.0848 7.99968C10.0848 6.85281 9.1489 5.91687 8.00203 5.91687ZM14.2489 7.99968C14.2489 7.13718 14.2567 6.2825 14.2083 5.42156C14.1598 4.42156 13.9317 3.53406 13.2005 2.80281C12.4677 2.07 11.5817 1.84343 10.5817 1.795C9.71922 1.74656 8.86453 1.75437 8.00359 1.75437C7.14109 1.75437 6.2864 1.74656 5.42547 1.795C4.42547 1.84343 3.53797 2.07156 2.80672 2.80281C2.0739 3.53562 1.84734 4.42156 1.7989 5.42156C1.75046 6.28406 1.75828 7.13874 1.75828 7.99968C1.75828 8.86062 1.75046 9.71687 1.7989 10.5778C1.84734 11.5778 2.07546 12.4653 2.80672 13.1966C3.53953 13.9294 4.42547 14.1559 5.42547 14.2044C6.28797 14.2528 7.14265 14.245 8.00359 14.245C8.86609 14.245 9.72078 14.2528 10.5817 14.2044C11.5817 14.1559 12.4692 13.9278 13.2005 13.1966C13.9333 12.4637 14.1598 11.5778 14.2083 10.5778C14.2583 9.71687 14.2489 8.86218 14.2489 7.99968ZM8.00203 11.2044C6.22859 11.2044 4.79734 9.77312 4.79734 7.99968C4.79734 6.22624 6.22859 4.795 8.00203 4.795C9.77547 4.795 11.2067 6.22624 11.2067 7.99968C11.2067 9.77312 9.77547 11.2044 8.00203 11.2044ZM11.338 5.41218C10.9239 5.41218 10.5895 5.07781 10.5895 4.66375C10.5895 4.24968 10.9239 3.91531 11.338 3.91531C11.752 3.91531 12.0864 4.24968 12.0864 4.66375C12.0865 4.76207 12.0673 4.85945 12.0297 4.95031C11.9921 5.04117 11.937 5.12372 11.8675 5.19325C11.7979 5.26277 11.7154 5.31789 11.6245 5.35546C11.5337 5.39303 11.4363 5.41231 11.338 5.41218Z" fill="#F6F8F9" />
                                </svg>
                            </a>
                        </li>
                        <li className="footer-socials__item">
                            <a className="footer-socials__icon flex"
                               href="https://discord.com/invite/Raritygram">
                                <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M37.2973 11.8095C34.7904 10.6591 32.102 9.81165 29.2912 9.32625C29.24 9.31689 29.1888 9.3403 29.1625 9.38712C28.8167 10.0021 28.4338 10.8043 28.1656 11.4348C25.1423 10.9822 22.1346 10.9822 19.1734 11.4348C18.9051 10.7903 18.5082 10.0021 18.1609 9.38712C18.1346 9.34186 18.0834 9.31845 18.0323 9.32625C15.223 9.8101 12.5346 10.6576 10.0261 11.8095C10.0044 11.8188 9.98576 11.8344 9.9734 11.8547C4.87417 19.4729 3.47728 26.9038 4.16255 34.2425C4.16565 34.2784 4.1858 34.3128 4.21371 34.3346C7.57804 36.8053 10.837 38.3052 14.0354 39.2994C14.0866 39.315 14.1408 39.2963 14.1734 39.2541C14.93 38.221 15.6044 37.1315 16.1826 35.9859C16.2168 35.9188 16.1842 35.8392 16.1144 35.8126C15.0447 35.4068 14.0261 34.9121 13.0462 34.3502C12.9687 34.305 12.9625 34.1941 13.0338 34.141C13.24 33.9865 13.4463 33.8258 13.6431 33.6634C13.6788 33.6338 13.7284 33.6275 13.7703 33.6463C20.2074 36.5852 27.1764 36.5852 33.5376 33.6463C33.5795 33.626 33.6291 33.6322 33.6663 33.6619C33.8633 33.8242 34.0695 33.9865 34.2772 34.141C34.3485 34.1941 34.3438 34.305 34.2663 34.3502C33.2865 34.923 32.2679 35.4068 31.1966 35.8111C31.1268 35.8376 31.0958 35.9188 31.1299 35.9859C31.7206 37.1299 32.395 38.2193 33.1376 39.2526C33.1687 39.2963 33.2244 39.315 33.2756 39.2994C36.4896 38.3052 39.7485 36.8053 43.1128 34.3346C43.1423 34.3128 43.1609 34.28 43.164 34.2441C43.9841 25.7597 41.7903 18.3897 37.3485 11.8563C37.3376 11.8344 37.319 11.8188 37.2973 11.8095ZM17.1439 29.774C15.2059 29.774 13.609 27.9947 13.609 25.8096C13.609 23.6245 15.1749 21.8453 17.1439 21.8453C19.1284 21.8453 20.7098 23.6401 20.6788 25.8096C20.6788 27.9947 19.1129 29.774 17.1439 29.774ZM30.2136 29.774C28.2756 29.774 26.6788 27.9947 26.6788 25.8096C26.6788 23.6245 28.2446 21.8453 30.2136 21.8453C32.1981 21.8453 33.7795 23.6401 33.7485 25.8096C33.7485 27.9947 32.1981 29.774 30.2136 29.774Z" fill="#041527"/>
                                </svg>
                            </a>
                        </li>
                        <li className="footer-socials__item">
                            <a className="footer-socials__icon flex"
                               href="https://twitter.com/raritygram">
                                <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M46 10.8073C44.3811 11.4868 42.6413 11.9461 40.8152 12.1526C42.6791 11.0952 44.1104 9.42073 44.7844 7.42565C43.0403 8.40468 41.1083 9.11577 39.0521 9.49903C37.4054 7.83846 35.0593 6.80078 32.463 6.80078C27.4775 6.80078 23.4356 10.6263 23.4356 15.3448C23.4356 16.0144 23.5156 16.6665 23.6694 17.2919C16.1669 16.9356 9.51536 13.534 5.06305 8.36477C4.28602 9.62669 3.84086 11.0944 3.84086 12.6602C3.84086 15.6244 5.43467 18.2397 7.85685 19.7718C6.37699 19.7275 4.98516 19.3432 3.76795 18.7032C3.76731 18.7389 3.76731 18.7747 3.76731 18.8106C3.76731 22.9504 6.87896 26.4037 11.0086 27.1885C10.251 27.3838 9.45356 27.4882 8.63018 27.4882C8.04853 27.4882 7.48298 27.4347 6.93191 27.3349C8.08056 30.7295 11.4143 33.1997 15.3645 33.2687C12.275 35.5602 8.38265 36.9262 4.15341 36.9262C3.42482 36.9262 2.70621 36.8858 2 36.8069C5.99491 39.2311 10.74 40.6455 15.8378 40.6455C32.4419 40.6455 41.5216 27.6262 41.5216 16.3355C41.5216 15.9651 41.5129 15.5966 41.4955 15.2302C43.2592 14.0256 44.7897 12.5207 46 10.8073Z" fill="#041527"/>
                                </svg>
                            </a>
                        </li>
                    </ul>

                    <Button
                        to={'https://discord.com/invite/Raritygram'}
                        className={'footer-down__button'}
                    >
                        <div className="footer-down__button_inner">
                            Join the community
                        </div>
                    </Button>
                </div>

            </div>
        </footer>
    );
}