import './MintServices.scss';
import MainCard from "../../../components/MainCard/MainCard";
import {useRef} from "react";
import Slider from "react-slick";
import {mintServiceItems} from "./mintServiceItems";


export default function MintServices() {

    const SliderRef = useRef(null);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        appendDots: dots => (
            <div className={'slider__nav-wrap'}>
                <ul>{dots}</ul>
            </div>
        )
    };

    const ecosystemList = mintServiceItems.map((item)=> {
        return (
            <MainCard className={'mint-services__card'} item={item} button={item.button} key={item.title}/>
        );
    });

    const ecosystemSliderList = mintServiceItems.map((item)=> {
        return (
            <div className="slider__item" key={item.title}>
                <MainCard className={'mint-services__card'} item={item} button={item.button}/>
            </div>
        );
    });

    return (
        <section className={'mint-services'}>
            <div className="container">
                <h2 className="mint-services__title">
                    Raritygram Ecosystem
                </h2>
                <ul className={'mint-services__list'}>
                    {ecosystemList}
                </ul>
                {/*Slider place section*/}
                <div className="mint-services__slider">
                    <Slider ref={SliderRef} {...settings}>
                        {ecosystemSliderList}
                    </Slider>
                </div>
            </div>
        </section>
    );
}