export const wanttogetyouripadpro = {
    name: 'wanttogetyouripadpro',
    type: 'news',
    date: '23.08.22',
    authorAvatar: 'avatar.png',
    authorName: 'Egor Philippov',
    authorPost: 'UX/UI designer',
    title: 'Want to get your Ipad Pro? It’s simple!',
    desc: <>
        <p>It’s the news you’ve been waiting for: today our Ipad Pro Draw has started! Everybody can participate by just
            following a couple of steps.</p>
        <ul>
            <li>Step 1: go to our Gleam</li>
            <li>Step 2: complete tasks</li>
            <li>Step 3: become a participant</li>
        </ul>
        <p>Those who complete all the tasks will be able to claim the position of the winner and finally own the
            cherished prize. Make sure to regularly check the news. That’s how you won’t miss the Draw results. Maybe
            you’ll become the winner who gets an Ipad Pro!</p>
    </>
};