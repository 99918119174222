export const septemberUpdates = {
    name: 'septemberUpdates',
    type: 'blog',
    date: 'SEPT 28/2022',
    time: '4 min',
    authorAvatar: 'alex.png',
    authorName: 'Alex',
    authorPost: 'Lead developer',
    mainImage: 'devblog_post1.jpg',
    title: 'New updates of September are waiting for you on Raritygram.',
    shortDesc: 'Fast and easy authorization; ability to follow users, comment and post; keyword search. New metaverse features and other updates of September.',
    content: [
        {
            video: 'https://www.youtube.com/embed/8st-3ip3xOc'
        },
        {
            h2: 'The latest updates of authorization on the Raritygram platform.',
            p: <>
                We continue to improve Raritygram from the very first step — authorization. This process has become much easier
                and more informative.<br/><br/>
                When going to the raritygram.com, each user sees the “Connect MetaMask” button. Just by clicking it, they are
                authorized on the Raritygram platform. This is done with the MetaMask crypto wallet, which is the basis for our
                dApp.<br/><br/>
                Authorized users not only get easy access to the Raritygram beta-version, but also can see their own avatar and
                nickname.<br/><br/>
                And that’s not it yet as the address wallet is also displayed next to the profile icon. This address can be
                easily copied to the clickboard without any additional actions. All the user needs to do is to click on it — and
                the process of copying is being done automatically.<br/><br/>
                Check this out now and keep getting to know new features of Raritygram.
            </>
        },
        {
            h2: 'New beta-version advantages: follow users, create comments and put “likes”',
            p: <>
                New updates of the raritygram.com cover both informative interface and capability of cooperation among users.<br/><br/>
                In the “Top projects” section, Top-10 projects of Raritygram are shown. They include those ones that have the largest number of likes+comments+transactions. Maybe someday yours will be one of them or even will become the first!<br/><br/>
                It became possible to follow users from the list of the “Users” section. Their updates will be shown in the user’s feed. By the way, soon we’ll implement a similar feature in the metaverse, and all the followed users will automatically appear on one’s personal page.<br/><br/>
                Another useful update: now each user is able to see comments from other users in the “Collections” 'section. Like some of
                them? Not a problem — just put a “Like” button and respond, if you will. Publishing one’s own comment is also possible.<br/><br/>
                By clicking on the user profile or on the project card, user can easily go to the personal profile.<br/><br/>
                One more important update: we have added a keyword search for all raritigram users. It’s available by tag @. When a symbol is being entered, a list of all users, whose nickname matches the letters, is shown. Recommended friends and the ability to follow or unfollow them have also been implemented. All followed users are displayed in the personal account as well as users who follow you.<br/><br/>
                The first steps to improve user interaction have already been taken. The more — the bigger!
            </>
        },
        {
            h2: 'Change bio, nickname, background. Posts and comments — user personal page updates',
            p: <>
                When Raritygram users are authorized, they get access to many new features of their personal pages.<br/><br/>
                Clicking on the avatar, users can see "My profile" and "Log out" buttons. They now can change their nickname, avatar, edit profile bio and page’s background thanks to a special input added. All these changes are automatically transferred to the metaverse as all of these belong to one ecosystem. This means, if a user changes, for example, a nickname, it will appear in the Raritygram metaverse as well.<br/><br/>
                <em>Important note: it’s necessary to click “Save” button to save all the changes.</em><br/><br/>
                Create posts, comments and put “likes” — now this feature works for each authorized user. It’s possible to publish NFTs and text messages in the personal profile. When the post is shown on the profile page, the caption "posted “ appears. All the media posted is published in IPES format.<br/><br/>
                When adding a comment, the date of publication appears. It’s also possible to like posts and comments. The "like" button is added, the number of likes is shown for each user.

            </>
        },
        {
            h2: 'Switch between “My profile” and “My NFTs” to see the information they include.',
            p: <>
                Each Raritygram user now can switch between “My profile” / “My NFTs” sections and interact with available features.<br/><br/>
                In the “My profile” section, recommended profiles with avatars, nicknames and a “Follow” button are shown. By clicking it, it becomes possible to start following the exact person the user is interested in.
                The number of followed users, followers and transactions is displayed on the top right of the profile page.<br/><br/>
                Those ones who’ve participated in our NFTRarity mint, have a special bestseller status “Early adopter”. It’s shown on the right side in the “My NFTs” section.<br/><br/>
                The "Publicated" and "Unpublished" buttons are located at the top left. They display all NFTs in one’s user profile. When NFT is "Unpublished", it can not be seen by the profile visitors. And vice versa, if an NFT is “Published”, everyone can see it in the user profile.<br/><br/>
                Want to try this out? It’s the very time — go to raritygram.com and explore all the features we have already developed!
            </>
        },
        {
            h2: 'Digital avatar customization and other updates of Raritygram Metaverse',
            p: <>
                Immerse yourself in the Raritygram metaverse — now it's even more exciting: from authorization to customization. Let’s dive into it!<br/><br/>
                Registration has become much easier as Multiplayer is technically implemented into the metaverse. Now, when connecting MetaMask, the user's nickname, avatar and crypto wallet address appears — just like on the raritygram.com platform.<br/><br/>
                As we’ve already mentioned, large work has been done on the user's cabinet in the metaverse. Soon it will be possible to customize a user's personal digital avatar, changing each little thing: hair colour, hairstyle, and any facial features, bringing the avatar to a character that looks just like the user who created it.<br/><br/>
                Visiting Raritygram VR museum is already available for authorized users, and soon everyone will be able to show their NFTs or entire collections in it — we’re in the process of developing such a feature. We’re also implementing a big cybersports arena. All our tournaments will be held there for both teams and individual players.<br/><br/>
                We’d like to remind you that the Raritygram platform and the metaverse belong to one ecosystem. Thanks to this, all changes, made on the metaverse, are automatically displayed on the raritygram.com, and vice versa.
            </>
        },
    ]
};