export const virtualCard = {
    name: 'virtualcard',
    mainTitle: 'Identification in a virtual card',
    subtitle: 'Take the chance to get a Rarity ID — a blockchain document, similar to one\'s passport or physical ID card, that keeps personal data in encrypted form. Use Raritygram’s social services without passing verification on third-party resources and keep your information safe',
    mainImage: 'main-identification.jpg',
    mainImagePosition: 'center center',
    mobileMainImagePosition: '-876px bottom',
    firstCards: {
        title: 'All, legal and economic processes within the blockchain',
        cards: [
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 6H6V12" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4 24H24H44" stroke="#041527" strokeWidth={3} strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M18 38V34V30" stroke="#041527" strokeWidth={3} strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M24 32V31V30" stroke="#041527" strokeWidth={3} strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M30 34V32V30" stroke="#041527" strokeWidth={3} strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M24 42V39V36" stroke="#041527" strokeWidth={3} strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M36 6H42V12" stroke="#041527" strokeWidth={3} strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M12 42H6V36" stroke="#041527" strokeWidth={3} strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M36 42H42V36" stroke="#041527" strokeWidth={3} strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>,
                title: 'Easy identification',
                text: 'Rarity ID helps to pass registrations, verification and prove identity automatically. It also contains information about the user\'s length of service, labour book, resume and portfolio.'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M38 40H10C7.79086 40 6 38.2092 6 36V18C6 15.7909 7.79086 14 10 14H38C40.2092 14 42 15.7909 42 18V36C42 38.2092 40.2092 40 38 40Z"
                        stroke="#041527" strokeWidth={3}/>
                    <path
                        d="M33 28C32.4478 28 32 27.5522 32 27C32 26.4478 32.4478 26 33 26C33.5522 26 34 26.4478 34 27C34 27.5522 33.5522 28 33 28Z"
                        fill="#041527" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M36 14.0008V11.2073C36 8.57914 33.5088 6.66516 30.9694 7.34232L8.96934 13.209C7.21834 13.6759 6 15.2617 6 17.0739V18.0008"
                        stroke="#041527" strokeWidth={3}/>
                </svg>,
                title: 'Get or/and sell property and estate',
                text: 'Make transaction on the property easily without bunch of documents and unnecessary issues'
            },
            {
                svg: <svg width={44} height={44} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x={2} y={2} width={40} height={40} rx={10} stroke="#041527" strokeWidth={3}/>
                    <path d="M12.9995 21.9995L19 28L31.001 15.999" stroke="#041527" strokeWidth={3}
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>,
                title: 'Voting possibility',
                text: 'Users can hold their own elections, take part in it on Raritygram and Get all kinds of social and commercial surveys and research'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18 42H30M18 42V32M18 42H7.2C6.53726 42 6 41.4628 6 40.8V33.2C6 32.5372 6.53726 32 7.2 32H18M30 42V18M30 42H40.8C41.4628 42 42 41.4628 42 40.8V7.2C42 6.53726 41.4628 6 40.8 6H31.2C30.5372 6 30 6.53726 30 7.2V18M30 18H19.2C18.5373 18 18 18.5373 18 19.2V32"
                        stroke="#041527" strokeWidth={3}/>
                </svg>,
                title: 'Take part in bonus promotions',
                text: 'Achieve bonuses from Raritygram creators and developers thanks to a personal virtual ID card'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M44 28V12C44 8.6863 41.3138 6 38 6H18C14.6863 6 12 8.6863 12 12V26" stroke="#041527"
                          strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M24 42H12C7.58172 42 4 38.4182 4 34C4 29.5818 7.58172 26 12 26H34H36C31.5818 26 28 29.5818 28 34C28 38.4182 31.5818 42 36 42C40.4182 42 44 38.4182 44 34V28"
                        stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                </svg>,
                title: 'Rent any actives in diverse countries',
                text: 'The ability to conduct international business is greatly simplified by this function: rent actives the easiest way'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8 42.8V5.2C8 4.53726 8.53726 4 9.2 4H32.503C32.8212 4 33.1264 4.12642 33.3514 4.35148L39.6486 10.6485C39.8736 10.8736 40 11.1788 40 11.4971V42.8C40 43.4628 39.4628 44 38.8 44H9.2C8.53726 44 8 43.4628 8 42.8Z"
                        stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M32 10.8V4.7071C32 4.31658 32.3166 4 32.7072 4C32.8946 4 33.0744 4.0745 33.2072 4.2071L39.7928 10.7929C39.9256 10.9255 40 11.1054 40 11.2929C40 11.6834 39.6834 12 39.2928 12H33.2C32.5372 12 32 11.4627 32 10.8Z"
                        fill="#041527" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M21.2698 20.8302L23.348 16.4242C23.6146 15.8586 24.3854 15.8586 24.652 16.4242L26.7302 20.8302L31.3772 21.5412C31.9734 21.6324 32.211 22.3994 31.7794 22.8396L28.4174 26.2668L29.2108 31.1086C29.3128 31.7304 28.6892 32.2044 28.1556 31.9108L24 29.6236L19.8444 31.9108C19.3109 32.2044 18.6873 31.7304 18.7892 31.1086L19.5826 26.2668L16.2206 22.8396C15.7889 22.3994 16.0266 21.6324 16.6228 21.5412L21.2698 20.8302Z"
                        stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                </svg>,
                title: 'Get visa to reach any region',
                text: 'The ability to conduct international business is greatly simplified by this function: rent actives the easiest way'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.20106 14.6659C6.61527 15.2517 6.61527 16.2014 7.20106 16.7872C7.78685 17.373 8.73659 17.373 9.32238 16.7872L7.20106 14.6659ZM22.1313 3.97832C22.7171 3.39254 22.7171 2.44279 22.1313 1.857C21.5455 1.27122 20.5957 1.27122 20.01 1.857L22.1313 3.97832ZM9.32238 16.7872L22.1313 3.97832L20.01 1.857L7.20106 14.6659L9.32238 16.7872Z"
                        fill="#041527"/>
                    <path
                        d="M25.1307 32.5975C24.545 33.1833 24.545 34.1331 25.1307 34.7189C25.7165 35.3046 26.6663 35.3046 27.2521 34.7189L25.1307 32.5975ZM40.061 21.91C40.6468 21.3242 40.6468 20.3744 40.061 19.7886C39.4752 19.2029 38.5254 19.2029 37.9396 19.7886L40.061 21.91ZM27.2521 34.7189L40.061 21.91L37.9396 19.7886L25.1307 32.5975L27.2521 34.7189Z"
                        fill="#041527"/>
                    <path
                        d="M20 40.5C19.1716 40.5 18.5 41.1716 18.5 42C18.5 42.8284 19.1716 43.5 20 43.5V40.5ZM43 43.5C43.8284 43.5 44.5 42.8284 44.5 42C44.5 41.1716 43.8284 40.5 43 40.5V43.5ZM20 43.5L43 43.5V40.5L20 40.5V43.5Z"
                        fill="#041527"/>
                    <path
                        d="M11.8829 13.8124C11.2971 13.2266 10.3474 13.2266 9.76161 13.8124C9.17582 14.3982 9.17582 15.3479 9.76161 15.9337L11.8829 13.8124ZM26.8401 33.0122C27.4259 33.598 28.3757 33.598 28.9615 33.0122C29.5472 32.4265 29.5472 31.4767 28.9615 30.8909L26.8401 33.0122ZM9.76161 15.9337L26.8401 33.0122L28.9615 30.8909L11.8829 13.8124L9.76161 15.9337Z"
                        fill="#041527"/>
                    <path
                        d="M20.422 5.27137C19.8362 4.68558 18.8865 4.68558 18.3007 5.27137C17.7149 5.85716 17.7149 6.8069 18.3007 7.39269L20.422 5.27137ZM35.3792 24.4712C35.965 25.057 36.9147 25.057 37.5005 24.4712C38.0863 23.8854 38.0863 22.9357 37.5005 22.3499L35.3792 24.4712ZM18.3007 7.39269L35.3792 24.4712L37.5005 22.3499L20.422 5.27137L18.3007 7.39269Z"
                        fill="#041527"/>
                    <path
                        d="M30.67 14.2228C31.2558 13.637 31.2558 12.6872 30.67 12.1014C30.0842 11.5157 29.1345 11.5157 28.5487 12.1014L30.67 14.2228ZM26.8409 13.8093C26.2551 14.3951 26.2551 15.3448 26.8409 15.9306C27.4266 16.5164 28.3764 16.5164 28.9622 15.9306L26.8409 13.8093ZM28.5487 12.1014L26.8409 13.8093L28.9622 15.9306L30.67 14.2228L28.5487 12.1014Z"
                        fill="#041527"/>
                    <path
                        d="M20.422 24.4728C21.0078 23.887 21.0078 22.9372 20.422 22.3514C19.8362 21.7657 18.8865 21.7657 18.3007 22.3514L20.422 24.4728ZM2.92999 37.7221C2.3442 38.3079 2.3442 39.2577 2.92998 39.8434C3.51577 40.4292 4.46552 40.4292 5.05131 39.8434L2.92999 37.7221ZM18.3007 22.3514L2.92999 37.7221L5.05131 39.8434L20.422 24.4728L18.3007 22.3514Z"
                        fill="#041527"/>
                </svg>,
                title: 'Purchase tokens',
                text: 'Rarity ID simplifies buying tokens on various world popular marketplaces'
            }
        ]
    },
    secondCards: {
        title: 'Get perfect job',
        subtitle: 'Now it’s possible — getting a job that suits you the best! Here’s why:',
        cards: [
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 42H34" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M42 42H44" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M4 32.8V7.2C4 6.53726 4.53726 6 5.2 6H42.8C43.4628 6 44 6.53726 44 7.2V32.8C44 33.4628 43.4628 34 42.8 34H5.2C4.53726 34 4 33.4628 4 32.8Z"
                        stroke="black" strokeWidth={3}/>
                </svg>,
                title: 'possibility to work from anywhere in the world',
                text: 'Remote format of work using the Ratitygram platform allows you to choose exactly the place where the user feels more comfortable'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 40V38C4 30.268 10.268 24 18 24" stroke="black" strokeWidth={3} strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path
                        d="M31.608 24.6274C32.8916 23.218 35.1092 23.218 36.3928 24.6274C37.0416 25.3398 37.974 25.726 38.9364 25.681C40.8406 25.592 42.4088 27.1602 42.3198 29.0644C42.2748 30.0268 42.661 30.9592 43.3734 31.608C44.7828 32.8916 44.7828 35.1092 43.3734 36.3928C42.661 37.0416 42.2748 37.974 42.3198 38.9364C42.4088 40.8406 40.8406 42.4088 38.9364 42.3198C37.974 42.2748 37.0416 42.661 36.3928 43.3734C35.1092 44.7828 32.8916 44.7828 31.608 43.3734C30.9592 42.661 30.0268 42.2748 29.0644 42.3198C27.1602 42.4088 25.592 40.8406 25.681 38.9364C25.726 37.974 25.3398 37.0416 24.6274 36.3928C23.218 35.1092 23.218 32.8916 24.6274 31.608C25.3398 30.9592 25.726 30.0268 25.681 29.0644C25.592 27.1602 27.1602 25.592 29.0644 25.681C30.0268 25.726 30.9592 25.3398 31.608 24.6274Z"
                        stroke="black" strokeWidth={3}/>
                    <path d="M30.7266 34.0002L32.9086 36.182L37.2722 31.8184" stroke="black" strokeWidth={3}
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M18 24C22.4182 24 26 20.4182 26 16C26 11.5817 22.4182 8 18 8C13.5817 8 10 11.5817 10 16C10 20.4182 13.5817 24 18 24Z"
                        stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                </svg>,
                title: 'contains all the most necessary information',
                text: 'User’s resume, portfolio, labour book, income information and other documents are stored inside Rarity ID'
            }
        ]
    },
    banner: {

        title: 'Make life easier with rarity ID',
        subtitle: 'Using the Rarity ID can significantly simplifies a user\'s life, helping to pass registrations, verification and prove identity automatically. The feature is available for each NFTRarity owner',
        image: 'virtual_card.jpg',
        imagePosition: 'center 37%',
        mobileImage: 'virtual_card.jpg',
        mobileImagePosition: '-872px -206px',
        notice: true
    },
}