import './MyCollection.scss';
import ProjectBy from "../MainPage/ProjectBy/ProjectBy";
import Collection from "./Collection/Collection";
import NeedMoreNft from "./NeedMoreNft/NeedMoreNft";
import {useEffect, useState} from "react";
import { useSetRecoilState} from "recoil";
import {confirmedState} from "../MainPage/confirmedState";
import {useMoralis, useMoralisWeb3Api} from "react-moralis";

export default function MyCollection() {

    const setIsConfirmed = useSetRecoilState(confirmedState);
    const {isAuthenticated, user} = useMoralis();
    const Web3Api = useMoralisWeb3Api();

    const [nfts, setNfts] = useState([]);

    useEffect(()=> {
        fetchNFTsForContract();
        setIsConfirmed(false);
    }, []);

    const fetchNFTsForContract = async () => {
        if (isAuthenticated) {
            const options = {
                chain: "bsc",
                address: user.get("ethAddress"),
                token_address: "0xf995EFaBe838125f1320B4F537f1E1462fB11779",
            };
            const bscNFTs = await Web3Api.account.getNFTsForContract(options);
            const result = bscNFTs.result;

            const newArray = [];

            for (const nft of result) {
                const tokenUri = nft.token_uri;
                if (tokenUri.includes('https://nft.raritygram.io/nfts/')) {
                    console.log('tokenUri', tokenUri)

                    const response = await fetch(tokenUri)
                    const nft = await response.json();
                    newArray.push(nft)
                }
            }
            setNfts(newArray);
        }
    };

    return (
        <main className={'my-collection'}>
            <Collection array={nfts}/>
            {nfts.length > 0 && <NeedMoreNft/>}
            <ProjectBy/>
        </main>
    );
}