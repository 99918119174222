import Moralis from "moralis";
import {contractABI, contractAddress} from "./contract";
import Web3 from "web3"
const web3 = new Web3(Web3.givenProvider);

export const checkChainId = async () => {
    const chainId = Moralis.getChainId();
    console.log('chainId', chainId);
    if (chainId !== '0x38') {
        await Moralis.addNetwork('0x38', 'Binance Smart Chain', 'BNB', 'BNB', 'https://bsc-dataseed.binance.org', 'https://bscscan.com');
        await Moralis.switchNetwork('0x38')
    }
}

export const leftTokensInCollection = async (id) => {
    const contract = await new web3.eth.Contract(contractABI, contractAddress);
    const functions = await contract.methods
    return await functions.collectionSupply(id).call()
};