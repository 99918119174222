import './ArticleMainContent.scss';

export default function ArticleMainContent({array = []}) {

    const items = array.map(({h2, h3, p, ul = [], img, video}, index) => {

        const listItems = ul.map((item, index)=> {
            return <li key={index}>{item}</li>;
        })

        return (
            <div key={index}>
                {video &&
                    <iframe className={'article-main__video'} width="560" height="315" src={video}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                }
                {h2 && <h2 className="article-main__second-title">{h2}</h2>}
                {h3 && <h3 className="article-main__third-title">{h3}</h3>}
                {img && <img className="article-main__img" src={`/img/articles/${img}`} alt="Raritygram"/>}
                {p && <p className="article-main__paragraph">{p}</p>}
                {ul && <ul className="article-main__list">{listItems}</ul>}
            </div>
        );
    });

    return (
        <>
            {items}
        </>
    );
}