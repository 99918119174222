import './NeedMoreNft.scss';
import Button from "../../../components/Button/Button";

export default function NeedMoreNft() {
    return (
        <section className={'need-more'}>
            <div className="container">
                <div className="need-more__wrap flex">
                    Need more NFT?
                    <Button
                        to={'/mint'}
                        className={'button_purple'}
                    >
                        Mint
                    </Button>
                </div>
            </div>
        </section>
    );
}