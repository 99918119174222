export const mintServiceItems = [
    {
        svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 16C27.3137 16 30 13.3137 30 10C30 6.68629 27.3137 4 24 4C20.6863 4 18 6.68629 18 10C18 13.3137 20.6863 16 24 16Z" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24 44V16" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 24H4C4 29.3043 6.10714 34.3914 9.85786 38.1421C13.6086 41.8929 18.6957 44 24 44C29.3043 44 34.3914 41.8929 38.1421 38.1421C41.8929 34.3914 44 29.3043 44 24H38" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        stage: 'Сoming soon',
        title: 'RarityYacht',
        text: 'Rent or buy a yacht of your dreams in an NFT format and go sailing anywhere in the world.'
    },
    {
        svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 20H32" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 28H16" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M32 28H34" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 36H34M6 36V22.821C6 22.2794 6.11004 21.7432 6.32342 21.2454L10.961 10.4243C11.5913 8.95358 13.0375 8 14.6376 8H33.3624C34.9626 8 36.4086 8.95358 37.039 10.4243L41.6766 21.2454C41.89 21.7432 42 22.2794 42 22.821V36H6ZM6 36V40.8C6 41.4628 6.53726 42 7.2 42H12.8C13.4627 42 14 41.4628 14 40.8V36H6ZM6 36H14H6ZM42 36V40.8C42 41.4628 41.4628 42 40.8 42H35.2C34.5372 42 34 41.4628 34 40.8V36H42ZM42 36H34H42Z" stroke="#041527" strokeWidth={3}/>
        </svg>,
        stage: 'Сoming soon',
        title: 'RarityCars',
        text: 'Get desired cars and drive wherever you’d like to just in some clicks.'
    },
    {
        svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3246_4817)">
                <path d="M33.5283 10.435L26.9276 17.0358C26.635 17.3284 26.2123 17.4495 25.8092 17.3565L8.72062 13.4129C8.31738 13.32 7.89471 13.4412 7.60226 13.7337L6.33088 15.0051C5.77877 15.5572 5.89251 16.481 6.56199 16.8826L18.0683 23.7865C18.7378 24.1881 18.8515 25.1119 18.2994 25.664L14.1144 29.8489C13.8696 30.0937 13.5312 30.2209 13.186 30.1978L7.45774 29.8158C6.24235 29.7349 5.69332 31.3067 6.69481 31.9999L12.1358 35.7667C12.2543 35.8487 12.3573 35.9517 12.4393 36.0702L16.2062 41.5112C16.8993 42.5126 18.4711 41.9636 18.3902 40.7483L18.0082 35.02C17.9851 34.6748 18.1123 34.3364 18.3571 34.0916L22.542 29.9066C23.0941 29.3545 24.0179 29.4682 24.4195 30.1377L31.3234 41.644C31.7251 42.3135 32.6488 42.4272 33.2009 41.8751L34.4723 40.6037C34.7648 40.3113 34.886 39.8886 34.7931 39.4854L30.8495 22.3968C30.7565 21.9937 30.8776 21.571 31.1702 21.2784L37.771 14.6777C38.9425 13.5061 38.9425 11.6066 37.771 10.435C36.5994 9.26349 34.6999 9.26345 33.5283 10.435Z" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_3246_4817">
                    <rect width={48} height={48} fill="white"/>
                </clipPath>
            </defs>
        </svg>,
        stage: 'Сoming soon',
        title: 'RarityFly',
        text: 'Let your soul float in the skies on diverse kinds of aircrafts. Choose the one you prefer!'
    },
    {
        svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.9419 13.5434L37.1256 14.8018L37.9419 13.5434ZM20.7349 6.61793L19.9186 5.35949L20.7349 6.61793ZM7 36.5V21.933H4V36.5H7ZM10.8744 14.8018L21.5511 7.87637L19.9186 5.35949L9.2418 12.285L10.8744 14.8018ZM26.4489 7.87637L37.1256 14.8018L38.7582 12.285L28.0814 5.35949L26.4489 7.87637ZM41 21.933V36.5H44V21.933H41ZM36.5 41H11.5V44H36.5V41ZM41 36.5C41 38.9853 38.9853 41 36.5 41V44C40.6421 44 44 40.6421 44 36.5H41ZM37.1256 14.8018C39.5418 16.3691 41 19.0531 41 21.933H44C44 18.0367 42.0271 14.4053 38.7582 12.285L37.1256 14.8018ZM21.5511 7.87637C23.0408 6.91014 24.9593 6.91014 26.4489 7.87637L28.0814 5.35949C25.5988 3.7491 22.4012 3.7491 19.9186 5.35949L21.5511 7.87637ZM7 21.933C7 19.0531 8.45824 16.3691 10.8744 14.8018L9.2418 12.285C5.97292 14.4053 4 18.0367 4 21.933H7ZM4 36.5C4 40.6421 7.35787 44 11.5 44V41C9.01472 41 7 38.9853 7 36.5H4Z" fill="#041527" />
            <mask id="path-2-inside-1_3246_4825" fill="white">
                <path d="M16 32C16 28.134 19.134 25 23 25H25C28.866 25 32 28.134 32 32V44H16V32Z" />
            </mask>
            <path d="M32 44V47H35V44H32ZM16 44H13V47H16V44ZM23 28H25V22H23V28ZM29 32V44H35V32H29ZM32 41H16V47H32V41ZM19 44V32H13V44H19ZM25 28C27.2091 28 29 29.7909 29 32H35C35 26.4772 30.5228 22 25 22V28ZM23 22C17.4772 22 13 26.4772 13 32H19C19 29.7909 20.7909 28 23 28V22Z" fill="#041527" mask="url(#path-2-inside-1_3246_4825)" />
        </svg>,
        stage: 'Сoming soon',
        title: 'RarityRealty',
        text: 'Build, purchase, lease or rent any kind of estate and use it for your needs.'
    },
    {
        svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M42 17.7V14C42 9.58172 38.4183 6 34 6H30.3M42 30.3V34C42 38.4183 38.4183 42 34 42H30.3M17.7 42H14C9.58172 42 6 38.4183 6 34V30.3M6 17.7V14C6 9.58172 9.58172 6 14 6H17.7" stroke="#041527" strokeWidth={3} strokeLinecap="round"/>
            <path d="M44 24L4 24" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        stage: 'Сoming soon',
        substage: 'for holders',
        substageBackground: '#C1F537',
        substageColor: '#000000',
        title: 'RarityID',
        text: 'Receive a virtual document to simplify each market, business and legal processes.'
    },
    {
        svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 42L8.28572 6H39.7142L46 42H2Z" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 33H44" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 24H42" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 15H40" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24 6V42" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16 7L13 41" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M32 7L35 41" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        stage: 'Сoming soon',
        substage: 'special terms',
        substageBackground: 'radial-gradient(154.09% 114.47% at 104.5% 102.98%, #775BFF 0.01%, #2948FF 100%)',
        substageColor: '#ffffff',
        title: 'RarityMeta',
        text: 'Realise the wishes, if reality doesn’t meet your dreams, it’s possible in the metaverse.'
    }
];