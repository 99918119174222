import './MoreCard.scss';
import ItemDate from "../../../../components/ItemDate/ItemDate";
import {Link} from "react-router-dom";
import AuthorWrap from "../../../../components/AuthorWrap/AuthorWrap";

export default function MoreCard({to, date, time, title, desc, img, authorAvatar, authorName, authorPost, column = false}) {

    return (
        <li className={`devblog-devs__item devs-item ${column && 'devs-item_column'}`}>
            <Link to={`/devblog/${to}`} className="devs-item__link devs-item__inner flex">
                <div className="devs-item__content flex">
                    <div className="devs-item__desc-wrap">
                        <ItemDate date={date} time={time}/>
                        <h2 className="devs-item__title">
                            {title}
                        </h2>
                        <div className="devs-item__paragraph">
                            {desc}
                        </div>
                    </div>
                    <AuthorWrap authorAvatar={authorAvatar} authorName={authorName} authorPost={authorPost}/>
                </div>
                <div className="devs-item__img">
                    <img src={`/img/articles/${img}`} alt="#"/>
                </div>
            </Link>
        </li>
    );
}