export const presalevianftrarityset2 = {
    name: 'presalevianftrarityset2',
    type: 'news',
    date: '29.08.22',
    authorAvatar: 'avatar.png',
    authorName: 'Egor Philippov',
    authorPost: 'UX/UI designer',
    title: 'Announcement of RarityCoin pre-sale via NFTRarity set 2!',
    desc: <>
        <p>The first set RarityCoin pre-sale via NFTRarity was legendary! In 40 minutes, our users minted about $100,000 worth of NFTs! Today, we're happy to announce that the day is soon to come</p>
        <ul>
            <li>SAVE THE DATE: Tomorrow, August 29th, at 18.00 UTC, we'll host the second NFTRarity mint.</li>
        </ul>
        <p>You'll have one more chance to obtain Raritygram tokens at the best prices and become a beta tester of the first Web 3.0 social media platform ever! You'll be able to share your feedback with the developers and affect the project's improvement.</p>
        <p>On the 1 stage, you'll be able to activate bonuses from your NFTRarity traits: tokens, bonus tokens, metaverse lands, customisation of profile page, and many more exciting features. Get new cryptocurrency coins and become a Web 3.0 pioneer, exploring all the amazing features of the new Internet.</p>
    </>
};