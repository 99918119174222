import './OurPartners.scss';

export default function OurPartners() {

    const partnersImages = [
        {
            image: 'presidents.svg',
            href: 'https://presidents-nft.com'
        },
        {
            image: 'squid-game.svg',
            href: 'https://nftsquidgames.com'
        },
        {
            image: 'green-ocean.svg',
            href: ''
        },
        {
            image: 'themosquenft.svg',
            href: 'https://themosquenft.com'
        }
    ];

    const partners = partnersImages.map(({image, href}) => {

        return (
            href ? (
                <a href={href} className={'our-partners__partner flex'} key={image}>
                    <img src={`img/mainPage/our-partners/${image}`} alt="Partner"/>
                </a>
            ) : (
                <div className={'our-partners__partner flex'} key={image}>
                    <img src={`img/mainPage/our-partners/${image}`} alt="Partner"/>
                </div>
            )
        );
    });

    return (
        <section className={'our-partners'}>
            <div className="container">
                <h2>Our partners</h2>
                <div className="our-partners__partners flex">
                    {partners}
                </div>
            </div>
        </section>
    );
}