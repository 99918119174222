import './OurTeam.scss';
import Employee from "./Employee/Employee";
import {ourTeam} from "../../../data/ourTeam";
import Button from "../../../components/Button/Button";

export default function OurTeam() {

    const staff = ourTeam.map((item) => {
        return <Employee name={item.name} post={item.post} avatar={item.avatar} key={item.name}/>
    });

    return (
        <section className={'our-team'}>
            <div className="container">
                <div className="our-team__staff flex">
                    {staff}
                </div>
                <Button
                    href={'https://www.linkedin.com/company/86310325/admin/'}
                    className={'button_purple'}
                >
                    our team on LinkedIn
                </Button>
            </div>
        </section>
    );
}