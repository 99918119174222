import './PopUpMint.scss';
import Button from "../Button/Button";
import {traits} from "../../data/traits";

export default function PopUpMint(props) {


    const list = traits.map((trait)=> {

        return (
          <li className={'trait'} key={trait}>
              {trait}
          </li>
        );
    })

    return (
        <div onClick={(e)=> e.stopPropagation()} className={'popup-mint flex'}>
            <div className="popup-mint__content">
                <div className={'popup-mint__name'}>Mint is live</div>
                <div className="popup-mint__title">
                    NFTRarity an asset with infinite utility
                </div>
                <ul>
                    {list}
                </ul>
                <div className="popup-mint__buttons-wrap flex">
                    <Button
                        to={'/mint'}
                        className={'button_purple'}
                    >
                        Mint now
                    </Button>
                    <Button
                        to={'/mint'}
                        className={'popup-mint__button-about'}
                    >
                        About NFTRarity
                    </Button>
                </div>
            </div>
            <video className="popup-mint__video" autoPlay muted loop playsInline>
                <source src={`img/mintPage/videos/Diamond.mp4`} type="video/mp4"/>
            </video>
            <img onClick={props.onClick} src="img/popup-cross.svg" alt="Cross"/>
        </div>
    );
}