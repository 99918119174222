import './FirstCards.scss';
import MainCard from "../../../components/MainCard/MainCard";
import {useRef} from "react";
import Slider from "react-slick";

export default function FirstCards({title, cards = []}) {

    const SliderRef = useRef(null);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        appendDots: dots => (
            <div className={'slider__nav-wrap'}>
                <ul>{dots}</ul>
            </div>
        )
    };

    const cardList = cards.map((item)=> {
        return (
            <MainCard className={'first-cards__card'} item={item} key={item.title}/>
        );
    });

    const sliderCards = cards.map((item)=> {
        return (
            <div className="slider__item" key={item.title}>
                <MainCard className={'first-cards__card'} item={item}/>
            </div>
        );
    });

    return (
      <section className={'first-cards'}>
          <div className="container">
              <h2 className={'first-cards__title'}>
                  {title}
              </h2>
              <div className="first-cards__cards">
                  {cardList}
              </div>
              <div className="first-cards__slider">
                  <Slider ref={SliderRef} {...settings}>
                      {sliderCards}
                  </Slider>
              </div>
          </div>
      </section>
    );
}