import {theonesetisover} from "./theonesetisover";
import {charitycollectiongreenocean} from "./charitycollectiongreenocean";
import {whitelistisfinallyout} from "./whitelistisfinallyout";
import {ipadair} from "./ipadair";
import {thewhitepaperisalreadypublished} from "./thewhitepaperisalreadypublished";
import {wanttogetyouripadpro} from "./wanttogetyouripadpro";
import {presalevianftrarityset2} from "./presalevianftrarityset2";
import {septemberUpdates} from "./septemberUpdates";
import {septemberUpdates2} from "./septemberUpdates2";
import {septemberUpdates3} from "./septemberUpdates3";
import {septemberUpdates4} from "./septemberUpdates4";
import {septemberUpdates5} from "./septemberUpdates5";

export const news = [
    // septemberUpdates5,
    // septemberUpdates4,
    // septemberUpdates3,
    // septemberUpdates2,
    // septemberUpdates,
    presalevianftrarityset2,
    wanttogetyouripadpro,
    theonesetisover,
    charitycollectiongreenocean,
    whitelistisfinallyout,
    ipadair,
    thewhitepaperisalreadypublished
];