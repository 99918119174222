import './FirstScreen.scss';
import Button from "../../../components/Button/Button";
import cn from "classnames";

export default function FirstScreen({item}) {

    return (
        <section
            className={'first-screen'}
            style={window.innerWidth >= 576 ?
                {
                    backgroundImage: `url("../img/opportunitiesPage/first-screen/${item.mainImage}")`,
                    backgroundSize: item.mainImageSize ? `${item.mainImageSize}` : 'cover',
                    backgroundPosition: `${item.mainImagePosition}`,
                } :
                {
                    backgroundImage: `url("../img/opportunitiesPage/first-screen/${item.mainImage}")`,
                    backgroundSize: item.mainImageSize ? `${item.mainImageSize}` : 'cover',
                    backgroundPosition: `${item.mobileMainImagePosition}`,
                }
            }
        >
            <div className="container">
                <div className="first-screen__content">
                    <h1>{item.mainTitle}</h1>
                    <p>{item.subtitle}</p>
                    <Button
                        href={'https://raritygram.com/'}
                        className={'first-screen__button'}>
                        Soon
                    </Button>
                </div>
                {item.rightContent &&
                    <div className={cn("first-screen__right-wrap", { 'first-screen__right-wrap_model': item.model })}>
                        {item.rightContent}
                    </div>
                }
            </div>
        </section>
    );
}