export const novemberUpdates = {
    name: 'novemberUpdates',
    type: 'blog',
    date: 'NOV 27/2022',
    time: '3 min',
    authorAvatar: 'alex.png',
    authorName: 'Alex',
    authorPost: 'Lead developer',
    mainImage: 'devblog_post1.jpg',
    title: 'Brand-new ideas and designs to make your project work: what’s new for Rarirtygram?',
    shortDesc: 'Developing a crypto project becomes even easier: you have the ability to create a business page, and your project will also be visible on Launchpad. Here is what has been added to Raritygram in the past 2 months.',
    content: [
        {
            h2: 'The Raritygram provides new features to help projects grow!',
            p: <>
                We have designed and implemented a unique feature that will help you tell the story of your project: business accounts Your project's business account is a great solution for attracting investment and recruiting your first followers!<br/><br/>
                Simply by creating a business account, you gain more attention and respect from the community by projecting a consistent image.<br/><br/>
                On the business page, you can add some widgets that will reveal some details about you: the roadmap, the project trailer, your team, and your partners.<br/><br/>
                Roadmap allows you to schedule the company's plans for the near future or show plans for future development. Remember that the more information you provide about your plans, the more people will be interested in them. Meeting all your deadlines on the roadmap will create a favorable atmosphere around your project!<br/><br/>
                The video tab will allow you to share content with development, improving the technical part of your project. You can also use this feature to improve the visual component of your content.<br/><br/>
                It's just as important to talk about your team. Fortunately, on your business account, you can list each member of your project and showcase their experience and qualifications. The more open the team members are, the more users will support your ideas.<br/><br/>
                Don't forget about the "Partners" tab either. To gain more credibility, post information about projects that support your idea.<br/><br/>
                Check it out now and keep learning about new Raritygram features.
            </>
        },
        {
            h2: 'The launchpad is becoming more functional and extensive; hurry up to see all the updates!',
            p: <>
                Now, the most popular projects are displayed on a separate sheet. This will help users stay on top of the current trends in our projects.<br/><br/>
                Users also have the ability to sort projects by two criteria: most viewed and new projects. This makes it easier for users to navigate on the launchpad and find new projects to participate in.<br/><br/>
                The search function "Discover" searches for projects with filters for networks, number of subscribers, views, and novelty.<br/><br/>
                Also note the appearance of the tab "IDO coming," which contains projects that will soon hold their IDOs. Do not miss the opportunity to enter the project at an early stage of its creation.
            </>
        }
    ]
};