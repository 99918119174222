export const nftCards = [
    {
        name: 'Diamond',
        maxTokens: 100,
        price: '30',
        value: 25800000000000000000
    },
    {
        name: 'Sapphire',
        maxTokens: 200,
        price: '3',
        value: 2580000000000000000
    },
    {
        name: 'Ruby',
        maxTokens: 900,
        price: '0.8',
        value: 688000000000000000
    },
    {
        name: 'Emerald',
        maxTokens: 1300,
        price: '0.3',
        value: 258000000000000000
    }
];