import './SecondCards.scss';
import MainCard from "../../../components/MainCard/MainCard";
import cn from "classnames";

export default function SecondCards({title, subtitle, cards = []}) {

    const cardList = cards.map((item)=> {
        return (
            <MainCard className={cn('second-cards__card', { 'second-cards__card_three-columns': cards.length > 2 })} item={item} key={item.title}/>
        );
    });

    return (
        <section className={'second-cards'}>
            <div className="container">
                <h2 className={'second-cards__title'}>
                    {title}
                </h2>
                { subtitle ?
                    <p className={'second-cards__subtitle'}>{subtitle}</p>
                    : null
                }
                <div className={"second-cards__cards"}>
                    {cardList}
                </div>
            </div>
        </section>
    );
}