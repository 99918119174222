import './Tofu.scss';

export default function Tofu() {
    return (
      <div className={'tofu'}>
          <div className="tofu__inner flex">
              <div className="tofu__watch">
                  watch
              </div>
              <div className="tofu__text">
                  We’re listed and verifed on TOFU
              </div>
              <img src="img/mainPage/logo-tofu.png" alt="Tofu"/>
          </div>
      </div>
    );
}