// import {ReactComponent as Icon} from 'img/opportunitiesPage/first-cards/arrows.svg';

export const vrmuseum = {
    name: 'vrmuseum',
    mainTitle: 'Share your creations via vr museum',
    subtitle: 'On Raritygram each owner of an NFT collection can create their own VR gallery, which will have access to the metaverse. Any user can go to the museum and familiarise themselves with the collection, with or without VR glasses.',
    mainImage: 'main-vr.jpg',
    mainImagePosition: 'center bottom',
    mainImageSize: 'auto',
    mobileMainImagePosition: '58% bottom',
    firstCards: {
        title: 'Take vr museum advantages',
        cards: [
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M36 42C38.2092 42 40 40.2092 40 38C40 35.7908 38.2092 34 36 34C33.7908 34 32 35.7908 32 38C32 40.2092 33.7908 42 36 42Z" stroke="black" strokeWidth="{3}" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14Z" stroke="black" strokeWidth="{3}" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M36 34V14C36 14 36 10 32 10H26" stroke="black" strokeWidth="{3}" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 14V34C12 34 12 38 16 38H22" stroke="black" strokeWidth="{3}" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M30.001 15L25.001 10L30.001 5" stroke="black" strokeWidth="{3}" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17 33L22 38L17 43" stroke="black" strokeWidth="{3}" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                ,
                title: 'Connection to other services',
                text: 'Each collection can be demonstrated on RarityMeta and Raritygram'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.20106 14.6659C6.61527 15.2517 6.61527 16.2014 7.20106 16.7872C7.78685 17.373 8.73659 17.373 9.32238 16.7872L7.20106 14.6659ZM22.1313 3.97832C22.7171 3.39254 22.7171 2.44279 22.1313 1.857C21.5455 1.27122 20.5957 1.27122 20.01 1.857L22.1313 3.97832ZM9.32238 16.7872L22.1313 3.97832L20.01 1.857L7.20106 14.6659L9.32238 16.7872Z" fill="#041527" />
                    <path d="M25.1307 32.5975C24.545 33.1833 24.545 34.1331 25.1307 34.7189C25.7165 35.3046 26.6663 35.3046 27.2521 34.7189L25.1307 32.5975ZM40.061 21.91C40.6468 21.3242 40.6468 20.3744 40.061 19.7886C39.4752 19.2029 38.5254 19.2029 37.9396 19.7886L40.061 21.91ZM27.2521 34.7189L40.061 21.91L37.9396 19.7886L25.1307 32.5975L27.2521 34.7189Z" fill="#041527" />
                    <path d="M20 40.5C19.1716 40.5 18.5 41.1716 18.5 42C18.5 42.8284 19.1716 43.5 20 43.5V40.5ZM43 43.5C43.8284 43.5 44.5 42.8284 44.5 42C44.5 41.1716 43.8284 40.5 43 40.5V43.5ZM20 43.5L43 43.5V40.5L20 40.5V43.5Z" fill="#041527" />
                    <path d="M11.8829 13.8124C11.2971 13.2266 10.3474 13.2266 9.76161 13.8124C9.17582 14.3982 9.17582 15.3479 9.76161 15.9337L11.8829 13.8124ZM26.8401 33.0122C27.4259 33.598 28.3757 33.598 28.9615 33.0122C29.5472 32.4265 29.5472 31.4767 28.9615 30.8909L26.8401 33.0122ZM9.76161 15.9337L26.8401 33.0122L28.9615 30.8909L11.8829 13.8124L9.76161 15.9337Z" fill="#041527" />
                    <path d="M20.422 5.27137C19.8362 4.68558 18.8865 4.68558 18.3007 5.27137C17.7149 5.85716 17.7149 6.8069 18.3007 7.39269L20.422 5.27137ZM35.3792 24.4712C35.965 25.057 36.9147 25.057 37.5005 24.4712C38.0863 23.8854 38.0863 22.9357 37.5005 22.3499L35.3792 24.4712ZM18.3007 7.39269L35.3792 24.4712L37.5005 22.3499L20.422 5.27137L18.3007 7.39269Z" fill="#041527" />
                    <path d="M30.67 14.2228C31.2558 13.637 31.2558 12.6872 30.67 12.1014C30.0842 11.5157 29.1345 11.5157 28.5487 12.1014L30.67 14.2228ZM26.8409 13.8093C26.2551 14.3951 26.2551 15.3448 26.8409 15.9306C27.4266 16.5164 28.3764 16.5164 28.9622 15.9306L26.8409 13.8093ZM28.5487 12.1014L26.8409 13.8093L28.9622 15.9306L30.67 14.2228L28.5487 12.1014Z" fill="#041527" />
                    <path d="M20.422 24.4728C21.0078 23.887 21.0078 22.9372 20.422 22.3514C19.8362 21.7657 18.8865 21.7657 18.3007 22.3514L20.422 24.4728ZM2.92999 37.7221C2.3442 38.3079 2.3442 39.2577 2.92998 39.8434C3.51577 40.4292 4.46552 40.4292 5.05131 39.8434L2.92999 37.7221ZM18.3007 22.3514L2.92999 37.7221L5.05131 39.8434L20.422 24.4728L18.3007 22.3514Z" fill="#041527" />
                </svg>,
                title: 'Auctions holding',
                text: 'Having one’s own gallery gives possibility of holding actions and inviting users to participate'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M42 7.2V40.8C42 41.4628 41.4628 42 40.8 42H7.2C6.53726 42 6 41.4628 6 40.8V7.2C6 6.53726 6.53726 6 7.2 6H40.8C41.4628 6 42 6.53726 42 7.2Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M20 32L28 16" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: 'No additions required',
                text: 'Service has its own firmware, no additional development will be required'
            }
        ]
    },
    secondCards: {
        title: 'Get all available features',
        cards: [
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M44.0058 6V14.9941C44.0058 15.5496 43.5554 16 42.9998 16C42.6 16 42.2402 15.7621 42.068 15.4012C38.8526 8.65896 31.9732 4 24.0058 4C13.635 4 5.10793 11.8934 4.10449 22" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M34 20V30C34 32.2092 32.2092 34 30 34H18C15.7909 34 14 32.2092 14 30V20C14 17.7909 15.7909 16 18 16H30C32.209 16 34 17.7909 34 20Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M24 22V16" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.10156 42V33.006C4.10156 32.4504 4.55194 32 5.10748 32C5.50732 32 5.86714 32.238 6.03926 32.5988C9.25474 39.341 16.1341 44 24.1015 44C34.4723 44 42.9993 36.1066 44.0029 26" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: 'Turn any object into NFT',
                text: 'Users can digitise and bring to the museum any object, it can be either a painting or a car, a statue, a vase and whatever a soul desires'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.20106 14.6659C6.61527 15.2517 6.61527 16.2014 7.20106 16.7872C7.78685 17.373 8.73659 17.373 9.32238 16.7872L7.20106 14.6659ZM22.1313 3.97832C22.7171 3.39254 22.7171 2.44279 22.1313 1.857C21.5455 1.27122 20.5957 1.27122 20.01 1.857L22.1313 3.97832ZM9.32238 16.7872L22.1313 3.97832L20.01 1.857L7.20106 14.6659L9.32238 16.7872Z" fill="black" />
                    <path d="M25.1307 32.5975C24.545 33.1833 24.545 34.1331 25.1307 34.7189C25.7165 35.3046 26.6663 35.3046 27.2521 34.7189L25.1307 32.5975ZM40.061 21.91C40.6468 21.3242 40.6468 20.3744 40.061 19.7886C39.4752 19.2029 38.5254 19.2029 37.9396 19.7886L40.061 21.91ZM27.2521 34.7189L40.061 21.91L37.9396 19.7886L25.1307 32.5975L27.2521 34.7189Z" fill="black" />
                    <path d="M20 40.5C19.1716 40.5 18.5 41.1716 18.5 42C18.5 42.8284 19.1716 43.5 20 43.5V40.5ZM43 43.5C43.8284 43.5 44.5 42.8284 44.5 42C44.5 41.1716 43.8284 40.5 43 40.5V43.5ZM20 43.5L43 43.5V40.5L20 40.5V43.5Z" fill="black" />
                    <path d="M11.8829 13.8124C11.2971 13.2266 10.3474 13.2266 9.76161 13.8124C9.17582 14.3982 9.17582 15.3479 9.76161 15.9337L11.8829 13.8124ZM26.8401 33.0122C27.4259 33.598 28.3757 33.598 28.9615 33.0122C29.5472 32.4265 29.5472 31.4767 28.9615 30.8909L26.8401 33.0122ZM9.76161 15.9337L26.8401 33.0122L28.9615 30.8909L11.8829 13.8124L9.76161 15.9337Z" fill="black" />
                    <path d="M20.422 5.27137C19.8362 4.68558 18.8865 4.68558 18.3007 5.27137C17.7149 5.85716 17.7149 6.8069 18.3007 7.39269L20.422 5.27137ZM35.3792 24.4712C35.965 25.057 36.9147 25.057 37.5005 24.4712C38.0863 23.8854 38.0863 22.9357 37.5005 22.3499L35.3792 24.4712ZM18.3007 7.39269L35.3792 24.4712L37.5005 22.3499L20.422 5.27137L18.3007 7.39269Z" fill="black" />
                    <path d="M30.67 14.2228C31.2558 13.637 31.2558 12.6872 30.67 12.1014C30.0842 11.5157 29.1345 11.5157 28.5487 12.1014L30.67 14.2228ZM26.8409 13.8093C26.2551 14.3951 26.2551 15.3448 26.8409 15.9306C27.4266 16.5164 28.3764 16.5164 28.9622 15.9306L26.8409 13.8093ZM28.5487 12.1014L26.8409 13.8093L28.9622 15.9306L30.67 14.2228L28.5487 12.1014Z" fill="black" />
                    <path d="M20.422 24.4728C21.0078 23.887 21.0078 22.9372 20.422 22.3514C19.8362 21.7657 18.8865 21.7657 18.3007 22.3514L20.422 24.4728ZM2.92999 37.7221C2.3442 38.3079 2.3442 39.2577 2.92998 39.8434C3.51577 40.4292 4.46552 40.4292 5.05131 39.8434L2.92999 37.7221ZM18.3007 22.3514L2.92999 37.7221L5.05131 39.8434L20.422 24.4728L18.3007 22.3514Z" fill="black" />
                    <path d="M33.5 7.5H42.5" stroke="black" strokeWidth={3} strokeLinecap="round" />
                    <path d="M38 3L38 12" stroke="black" strokeWidth={3} strokeLinecap="round" />
                </svg>,
                title: 'Create own auction',
                text: 'Having one’s own gallery gives possibility of holding actions for existing collections and inviting users to participate'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 40H44" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 8H38" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6 32.0202L6.02 31.998" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M42.02 32.0202L42 31.998" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8 24.0202L8.02 23.998" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M40.02 24.0202L40 23.998" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 16.0203L10.02 15.998" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M38.02 16.0203L38 15.998" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M24 14V34M24 14L21 17M24 14L27 17M24 34L18 28M24 34L30 28" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: 'Edit room template',
                text: 'For user’s convenience there are some prepared templates of rooms that can be customized to be appropriate for the existing collection'
            }
        ]
    },
    banner: {
        title: 'Start using vr museum',
        subtitle: 'VR museum function is available for every user registered on Ratitygram — make the world see your creations and feel its social and cultural significance',
        image: 'museum.jpg',
        imagePosition: 'left center',
        mobileImage: 'museum.jpg',
        mobileImagePosition: 'left -460px',
        notice: true
    },
}