import './MainMintCard.scss';
import cn from "classnames";
import {useRecoilState} from "recoil";
import {amountNftsState} from "./amountNftsState";

export default function MainMintCard(props) {

    const [amount, setAmount] = useRecoilState(amountNftsState);

    return (
        <div style={{pointerEvents: props.disabled ? 'none' : 'auto'}} className={cn('main-mint-card', {'main-mint-card_active': props.active})}>
            <div onClick={props.onClick} className={'main-mint-card__wrapper'}>
                <div className="main-mint-card__inner flex">
                    <div className="main-mint-card__wrap">
                        <div className="main-mint-card__title">
                            {props.name}

                        </div>
                        <div className="main-mint-card__left">
                            {props.left} left
                        </div>
                    </div>
                    <div className="main-mint-card__price">
                        {props.price} BNB
                    </div>
                </div>
            </div>
            <div
                className={
                    cn("main-mint-card__amount flex", {
                        "main-mint-card__amount_disabled": props.disabled,
                        'main-mint-card__amount_minimum': amount === 1,
                        'main-mint-card__amount_maximum': amount === 20,
                    })
                }
            >
                <img onClick={() => amount > 1 && setAmount(amount - 1)} src="img/mainPage/minus.svg" alt="Minus"/>
                {amount}
                <img onClick={() => amount < 20 && setAmount(amount + 1)} src="img/mainPage/plus.svg" alt="Plus"/>
            </div>
        </div>
    );
}