import './Modal.scss';
import {useEffect} from "react";
import cn from "classnames";

const stopScrollClass = 'stop-scroll';

export default function Modal(props) {

    // const {pathname, hash} = useLocation();
    //
    // useEffect(() => {
    //     props.onClose();
    // }, [pathname, hash])

    useEffect(() => {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

        if (props.isOpened) {
            document.body.classList.add(stopScrollClass);
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            // headerRef.current.style.paddingRight = `${scrollbarWidth}px`

        } else {
            setTimeout(() => {
                document.body.classList.remove(stopScrollClass);
                document.body.style.paddingRight = `0`;
                // headerRef.current.style.paddingRight = `0`

            }, 300)
        }

    }, [props.isOpened])

    useEffect(() => {
        const onEscape = ({key}) => {
            if (key === 'Escape') {
                props.onClose()
            }
        }

        document.addEventListener('keydown', onEscape);
        return () => document.removeEventListener('keydown', onEscape);
    }, [])

    return (
        <div className={cn('modals', {'modals_opened': props.isOpened})} onClick={() => props.onClose()}>
            <div className="modals__inner flex">
                {props.children}
            </div>
        </div>
    );
}