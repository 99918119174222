import {vrmuseum} from "./vrmuseum";
import {virtualCard} from "./virtualCard";
import {virtualLife} from "./virtualLife";
import {voting} from "./voting";
import {rent} from "./rent";
import {government} from "./government";

export const services = [
    rent,
    vrmuseum,
    virtualCard,
    virtualLife,
    government,
    voting
];