import './MintNow.scss';
import Button from "../Button/Button";
import {traits} from "../../data/traits";
import cn from "classnames";

export default function MintNow(props) {


    const list = traits.slice(0,3).map((trait)=> {

        return (
            <li className={'trait'} key={trait}>
                {trait}
            </li>
        );
    })


    return (
        <div className={cn('mint-now', {'mint-now_opened': props.isOpened})}>
            <div className="mint-now__inner flex">
                <div className="mint-now__text-wrap flex">
                    <div className="mint-now__now">
                        Mint now
                    </div>
                    <div className="mint-now__text">
                        NFTRarity an asset with infinite utility
                    </div>
                </div>
                <svg width={677} height={187} viewBox="0 0 677 187" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M386.421 44.4488L4.27352 0.941927C1.23376 0.595855 -0.699912 4.10569 1.21678 6.49027L217.484 275.552C218.124 276.347 218.074 277.487 217.332 278.189C199.149 295.38 151.611 323.433 136.415 406.878C115.866 519.709 195.294 591.988 236.391 612.503L674.104 800.557C675.774 801.275 677.264 799.238 676.075 797.864L422.647 504.901C421.709 503.817 422.19 502.125 423.549 501.671C520.931 469.171 588.745 381.061 588.745 286.493C588.745 125.135 463.61 52.6884 386.421 44.4488Z" fill="#041527" />
                </svg>

                <ul>
                    {list}
                </ul>

                <Button
                    to={'/mint'}
                    className={'button_purple'}
                >
                    Mint now
                </Button>
                <img onClick={props.onClick} src="img/popup-cross.svg" alt="Cross"/>
            </div>
        </div>
    );
}