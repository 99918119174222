export const rent = {
    name: 'rent',
    mainTitle: 'Rarity rent whatever you need',
    subtitle: 'Become a sailor with the Rarity Yacht feature. Get the yacht that suits you the best and start your voyage to any place in the world!',
    mainImage: 'rent-main.jpg',
    mainImagePosition: 'center center',
    mobileMainImagePosition: 'center center',
    firstCards: {
        title: 'Convient conditions and the best options',
        cards: [
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 44H34" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4 34V8C4 5.79086 5.79086 4 8 4H40C42.2092 4 44 5.79086 44 8V34C44 36.2092 42.2092 38 40 38H8C5.79086 38 4 36.2092 4 34Z" stroke="black" strokeWidth={3} />
                    <path d="M24.9698 12.2425L31.0906 13.7727C31.6248 13.9063 32.0018 14.3929 31.987 14.9433C31.6348 27.9993 23.9996 29.9999 23.9996 29.9999C23.9996 29.9999 16.3643 27.9993 16.0121 14.9433C15.9973 14.3929 16.3743 13.9063 16.9085 13.7727L23.0294 12.2425C23.6664 12.0832 24.3328 12.0832 24.9698 12.2425Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: '100% honest deals',
                text: 'The process of getting a yacht, renting or selling it is always providing secure transactions that guarantee the safety of personal data'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 36H21H28" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14 28H15H16" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14 20H17H20" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14 4H33L42 13V38" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6 41V13C6 11.3431 7.34314 10 9 10H28.503C28.8212 10 29.1264 10.1264 29.3514 10.3515L35.6486 16.6485C35.8736 16.8736 36 17.1788 36 17.4971V41C36 42.6568 34.6568 44 33 44H9C7.34314 44 6 42.6568 6 41Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M28 16.8V10.7071C28 10.3166 28.3166 10 28.7072 10C28.8946 10 29.0744 10.0745 29.2072 10.2071L35.7928 16.7929C35.9256 16.9255 36 17.1054 36 17.2929C36 17.6834 35.6834 18 35.2928 18H29.2C28.5372 18 28 17.4627 28 16.8Z" fill="black" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: 'Latest blockchain technologies',
                text: 'Support for smart contracts, including templates available for each user and simplifies the process of transactions.'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 44C35.0456 44 44 35.0456 44 24C44 12.9543 35.0456 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0456 12.9543 44 24 44Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 25L16 29L14 36L16 42" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M34 41L33 36L28 34V27L34 25L43 26" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M38 11L37 14L30 15V21L35 19H39L43 21" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 21L10 17L15 16L19 10L17 6" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: 'Travelling through the water',
                text: 'Choose a boat that suits you the best and start exploring the world travelling on water — it’s possible to go anywhere in the world\n'
            }
        ]
    },
    yacht: {
        firstCards: {
            title: 'Suitable yacht is waiting for you',
            cards: [
                {
                    svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.7966 39H3.2802C3.22395 39 3.17363 38.965 3.15401 38.9123C1.06936 33.3106 4.91356 27.2679 10.8708 26.7823L44.6961 24.0248C44.8205 24.0146 44.9025 24.1515 44.8349 24.2564L38.8864 33.4959C36.6776 36.9267 32.8769 39 28.7966 39Z" stroke="black" strokeWidth="2.61468" strokeLinecap="round" />
                        <path d="M38 24L31.2599 16.8339C29.559 15.0255 27.1867 14 24.7041 14H10.8716C10.3902 14 10 14.3902 10 14.8716V21.8431" stroke="black" strokeWidth="2.61468" strokeLinecap="round" />
                        <path d="M33 30.6927C32.278 30.6927 31.6927 31.278 31.6927 32C31.6927 32.722 32.278 33.3073 33 33.3073L33 30.6927ZM40 30.6927L33 30.6927L33 33.3073L40 33.3073L40 30.6927Z" fill="black" />
                        <path d="M22 18.6927C21.278 18.6927 20.6927 19.278 20.6927 20C20.6927 20.722 21.278 21.3073 22 21.3073L22 18.6927ZM34 18.6927L22 18.6927L22 21.3073L34 21.3073L34 18.6927Z" fill="black" />
                        <circle cx="15.5" cy="32.5" r="1.5" fill="black" />
                        <circle cx="11.5" cy="32.5" r="1.5" fill="black" />
                        <path d="M13 14V10C13 9.44772 13.4477 9 14 9H19.9879C20.2687 9 20.5365 9.11802 20.7259 9.32523L25 14" stroke="black" strokeWidth="2.83486" />
                        <path d="M41 3V15M41 15L36 10.5789M41 15L46 10.5789" stroke="black" strokeWidth="2.83486" strokeLinecap="round" />
                    </svg>,
                    title: 'Buy yachts',
                    text: 'Now there’s no need to drool on a luxury millionaire’s yacht, because it\'s possible to buy it on Raritygram and use for one’s own purposes'
                },
                {
                    svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.7966 39H3.2802C3.22395 39 3.17363 38.965 3.15401 38.9123C1.06936 33.3106 4.91356 27.2679 10.8708 26.7823L44.6961 24.0248C44.8205 24.0146 44.9025 24.1515 44.8349 24.2564L38.8864 33.4959C36.6776 36.9267 32.8769 39 28.7966 39Z" stroke="black" strokeWidth="2.61468" strokeLinecap="round" />
                        <path d="M38 24L31.2599 16.8339C29.559 15.0255 27.1867 14 24.7041 14H10.8716C10.3902 14 10 14.3902 10 14.8716V21.8431" stroke="black" strokeWidth="2.61468" strokeLinecap="round" />
                        <path d="M33 30.6927C32.278 30.6927 31.6927 31.278 31.6927 32C31.6927 32.722 32.278 33.3073 33 33.3073L33 30.6927ZM40 30.6927L33 30.6927L33 33.3073L40 33.3073L40 30.6927Z" fill="black" />
                        <path d="M22 18.6927C21.278 18.6927 20.6927 19.278 20.6927 20C20.6927 20.722 21.278 21.3073 22 21.3073L22 18.6927ZM34 18.6927L22 18.6927L22 21.3073L34 21.3073L34 18.6927Z" fill="black" />
                        <circle cx="15.5" cy="32.5" r="1.5" fill="black" />
                        <circle cx="11.5" cy="32.5" r="1.5" fill="black" />
                        <path d="M13 14V10C13 9.44772 13.4477 9 14 9H19.9879C20.2687 9 20.5365 9.11802 20.7259 9.32523L25 14" stroke="black" strokeWidth="2.83486" />
                        <path d="M39 16C42.866 16 46 12.866 46 9C46 5.13401 42.866 2 39 2C35.134 2 32 5.13401 32 9" stroke="black" strokeWidth="2.83" strokeLinecap="round" />
                        <path d="M38 10V7" stroke="black" strokeWidth="2.5" strokeLinecap="round" />
                        <path d="M38 10L41 10" stroke="black" strokeWidth="2.5" strokeLinecap="round" />
                    </svg>,
                    title: 'Rent yachts',
                    text: 'Not sure if you like it or need a boat for a limited period of time? Choose a term and rent a yacht — check if it’s the one and enjoy your water journey'
                },
                {
                    svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.7966 39H3.2802C3.22395 39 3.17363 38.965 3.15401 38.9123C1.06936 33.3106 4.91356 27.2679 10.8708 26.7823L44.6961 24.0248C44.8205 24.0146 44.9025 24.1515 44.8349 24.2564L38.8864 33.4959C36.6776 36.9267 32.8769 39 28.7966 39Z" stroke="black" strokeWidth="2.61468" strokeLinecap="round" />
                        <path d="M38 24L31.2599 16.8339C29.559 15.0255 27.1867 14 24.7041 14H10.8716C10.3902 14 10 14.3902 10 14.8716V21.8431" stroke="black" strokeWidth="2.61468" strokeLinecap="round" />
                        <path d="M33 30.6927C32.278 30.6927 31.6927 31.278 31.6927 32C31.6927 32.722 32.278 33.3073 33 33.3073L33 30.6927ZM40 30.6927L33 30.6927L33 33.3073L40 33.3073L40 30.6927Z" fill="black" />
                        <path d="M22 18.6927C21.278 18.6927 20.6927 19.278 20.6927 20C20.6927 20.722 21.278 21.3073 22 21.3073L22 18.6927ZM34 18.6927L22 18.6927L22 21.3073L34 21.3073L34 18.6927Z" fill="black" />
                        <circle cx="15.5" cy="32.5" r="1.5" fill="black" />
                        <circle cx="11.5" cy="32.5" r="1.5" fill="black" />
                        <path d="M13 14V10C13 9.44772 13.4477 9 14 9H19.9879C20.2687 9 20.5365 9.11802 20.7259 9.32523L25 14" stroke="black" strokeWidth="2.83486" />
                        <path d="M41 15L41 3M41 3L46 7.42105M41 3L36 7.42105" stroke="black" strokeWidth="2.83486" strokeLinecap="round" />
                    </svg>,
                    title: 'Sell or rent out',
                    text: 'Own a boat and don\'t use it often? Try to rent it out or sell it to a worthy buyer. Let the property work for you and make a profit'
                }
            ]
        },
        banner: {
            dark: true,
            title: 'Use rarity yacht service',
            subtitle: 'The service can be purchased directly by upgrading your card for a year or for life. Get an additional subscription pass through a blockchain deal and use it on Ratitygram to get even more features.',
            image: 'yacht.jpg',
            imagePosition: 'left center',
            mobileImage: 'yacht.jpg',
            mobileImagePosition: '-525px -460px',
        },
    },
    cars: {
        firstCards: {
            title: 'Purchase a dream',
            cards: [
                {
                    svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M41 3V15M41 15L36 10.5789M41 15L46 10.5789" stroke="black" strokeWidth="2.83486" strokeLinecap="round" />
                        <path d="M15.4424 22.7334H28.4186" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.8223 29.2227H15.4443" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M28.4199 29.2227H30.042" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.33398 35.7084V25.0201C7.33398 24.5808 7.42323 24.146 7.59628 23.7422L11.3574 14.9662C11.8686 13.7734 13.0415 13 14.3392 13H29.5253C30.8231 13 31.9958 13.7734 32.5071 14.9662L36.2683 23.7422C36.4413 24.146 36.5306 24.5808 36.5306 25.0201V35.7084M7.33398 35.7084V39.6013C7.33398 40.1389 7.76971 40.5745 8.3072 40.5745H12.8489C13.3864 40.5745 13.8221 40.1389 13.8221 39.6013V35.7084M7.33398 35.7084H13.8221M36.5306 35.7084V39.6013C36.5306 40.1389 36.0949 40.5745 35.5573 40.5745H31.0156C30.4781 40.5745 30.0424 40.1389 30.0424 39.6013V35.7084M36.5306 35.7084H30.0424M13.8221 35.7084H30.0424" stroke="black" strokeWidth={3} />
                    </svg>,
                    title: 'Buy cars',
                    text: 'Have several favourite car brands? Buy the one you like the most or get all of them — on Raritygram it’s possible to get any car'
                },
                {
                    svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M39 16C42.866 16 46 12.866 46 9C46 5.13401 42.866 2 39 2C35.134 2 32 5.13401 32 9" stroke="black" strokeWidth="2.83" strokeLinecap="round" />
                        <path d="M38 10V7" stroke="black" strokeWidth="2.5" strokeLinecap="round" />
                        <path d="M38 10L41 10" stroke="black" strokeWidth="2.5" strokeLinecap="round" />
                        <path d="M15.4424 22.7334H28.4186" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.8223 29.2227H15.4443" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M28.4199 29.2227H30.042" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.33398 35.7084V25.0201C7.33398 24.5808 7.42323 24.146 7.59628 23.7422L11.3574 14.9662C11.8686 13.7734 13.0415 13 14.3392 13H29.5253C30.8231 13 31.9958 13.7734 32.5071 14.9662L36.2683 23.7422C36.4413 24.146 36.5306 24.5808 36.5306 25.0201V35.7084M7.33398 35.7084V39.6013C7.33398 40.1389 7.76971 40.5745 8.3072 40.5745H12.8489C13.3864 40.5745 13.8221 40.1389 13.8221 39.6013V35.7084M7.33398 35.7084H13.8221M36.5306 35.7084V39.6013C36.5306 40.1389 36.0949 40.5745 35.5573 40.5745H31.0156C30.4781 40.5745 30.0424 40.1389 30.0424 39.6013V35.7084M36.5306 35.7084H30.0424M13.8221 35.7084H30.0424" stroke="black" strokeWidth={3} />
                    </svg>,
                    title: 'Rent cars',
                    text: 'If you want to travel around the world, but are not sure that you need to own a car like that - just rent it and start your journey'
                },
                {
                    svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M41 15L41 3M41 3L46 7.42105M41 3L36 7.42105" stroke="black" strokeWidth="2.83486" strokeLinecap="round" />
                        <path d="M15.4424 22.7334H28.4186" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.8223 29.2227H15.4443" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M28.4199 29.2227H30.042" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.33398 35.7084V25.0201C7.33398 24.5808 7.42323 24.146 7.59628 23.7422L11.3574 14.9662C11.8686 13.7734 13.0415 13 14.3392 13H29.5253C30.8231 13 31.9958 13.7734 32.5071 14.9662L36.2683 23.7422C36.4413 24.146 36.5306 24.5808 36.5306 25.0201V35.7084M7.33398 35.7084V39.6013C7.33398 40.1389 7.76971 40.5745 8.3072 40.5745H12.8489C13.3864 40.5745 13.8221 40.1389 13.8221 39.6013V35.7084M7.33398 35.7084H13.8221M36.5306 35.7084V39.6013C36.5306 40.1389 36.0949 40.5745 35.5573 40.5745H31.0156C30.4781 40.5745 30.0424 40.1389 30.0424 39.6013V35.7084M36.5306 35.7084H30.0424M13.8221 35.7084H30.0424" stroke="black" strokeWidth={3} />
                    </svg>,
                    title: 'Sell or rent out',
                    text: 'If the user won\'t need several cars at once or just doesn’t want to use any of them, he can sell them or rent them out. Let the vehicle benefit others!'
                }
            ]
        },
        banner: {
            title: 'Get cars on raritygram',
            subtitle: 'Buy a card, upgrade it for a year or for life and start using! Make deals, get the car’s ownership and enjoy all the advantages of driving around the world. Each user can additionally get a pass which is represented as a subscription and make deals on favourable terms.',
            image: 'car.jpg',
            imagePosition: 'left top',
            mobileImage: 'car.jpg',
            mobileImagePosition: '-670px top',
        },
    },
    sky: {
        firstCards: {
            title: 'Conquer the sky on the aircraft',
            cards: [
                {
                    svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M41 3V15M41 15L36 10.5789M41 15L46 10.5789" stroke="black" strokeWidth="2.83486" strokeLinecap="round" />
                        <path d="M21.5003 10.5V18.2791C21.5003 18.6239 21.3227 18.9444 21.0303 19.1271L8.63699 26.8728C8.34461 27.0557 8.16699 27.3762 8.16699 27.7208V29.2192C8.16699 29.8698 8.77838 30.3472 9.40953 30.1893L20.2578 27.4773C20.889 27.3195 21.5003 27.7968 21.5003 28.4475V33.3795C21.5003 33.668 21.3758 33.9423 21.1588 34.1322L17.5583 37.2825C16.7945 37.951 17.3971 39.2007 18.3957 39.019L23.8215 38.0325C23.9397 38.011 24.061 38.011 24.1792 38.0325L29.605 39.019C30.6035 39.2007 31.2062 37.951 30.4423 37.2825L26.8418 34.1322C26.6248 33.9423 26.5003 33.668 26.5003 33.3795V28.4475C26.5003 27.7968 27.1117 27.3195 27.7428 27.4773L38.5912 30.1893C39.2223 30.3472 39.8337 29.8698 39.8337 29.2192V27.7208C39.8337 27.3762 39.656 27.0557 39.3637 26.8728L26.9703 19.1271C26.678 18.9444 26.5003 18.6239 26.5003 18.2791V10.5C26.5003 9.11928 25.381 8 24.0003 8C22.6197 8 21.5003 9.11928 21.5003 10.5Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>,
                    title: 'Buy aircrafts',
                    text: 'Purchase a desired aircraft to get your destination point with luxury and comfort, whether it\'s business meetings or vacation'
                },
                {
                    svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M39 16C42.866 16 46 12.866 46 9C46 5.13401 42.866 2 39 2C35.134 2 32 5.13401 32 9" stroke="black" strokeWidth="2.83" strokeLinecap="round" />
                        <path d="M38 10V7" stroke="black" strokeWidth="2.5" strokeLinecap="round" />
                        <path d="M38 10L41 10" stroke="black" strokeWidth="2.5" strokeLinecap="round" />
                        <path d="M21.5003 10.5V18.2791C21.5003 18.6239 21.3227 18.9444 21.0303 19.1271L8.63699 26.8728C8.34461 27.0557 8.16699 27.3762 8.16699 27.7208V29.2192C8.16699 29.8698 8.77838 30.3472 9.40953 30.1893L20.2578 27.4773C20.889 27.3195 21.5003 27.7968 21.5003 28.4475V33.3795C21.5003 33.668 21.3758 33.9423 21.1588 34.1322L17.5583 37.2825C16.7945 37.951 17.3971 39.2007 18.3957 39.019L23.8215 38.0325C23.9397 38.011 24.061 38.011 24.1792 38.0325L29.605 39.019C30.6035 39.2007 31.2062 37.951 30.4423 37.2825L26.8418 34.1322C26.6248 33.9423 26.5003 33.668 26.5003 33.3795V28.4475C26.5003 27.7968 27.1117 27.3195 27.7428 27.4773L38.5912 30.1893C39.2223 30.3472 39.8337 29.8698 39.8337 29.2192V27.7208C39.8337 27.3762 39.656 27.0557 39.3637 26.8728L26.9703 19.1271C26.678 18.9444 26.5003 18.6239 26.5003 18.2791V10.5C26.5003 9.11928 25.381 8 24.0003 8C22.6197 8 21.5003 9.11928 21.5003 10.5Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>,
                    title: 'Rent aircrafts',
                    text: 'Try to rent a whole plane to travel alone or with friends. Get the vibe and be on the same air wave flying through the endless sky'
                },
                {
                    svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M41 15L41 3M41 3L46 7.42105M41 3L36 7.42105" stroke="black" strokeWidth="2.83486" strokeLinecap="round" />
                        <path d="M21.5003 10.5V18.2791C21.5003 18.6239 21.3227 18.9444 21.0303 19.1271L8.63699 26.8728C8.34461 27.0557 8.16699 27.3762 8.16699 27.7208V29.2192C8.16699 29.8698 8.77838 30.3472 9.40953 30.1893L20.2578 27.4773C20.889 27.3195 21.5003 27.7968 21.5003 28.4475V33.3795C21.5003 33.668 21.3758 33.9423 21.1588 34.1322L17.5583 37.2825C16.7945 37.951 17.3971 39.2007 18.3957 39.019L23.8215 38.0325C23.9397 38.011 24.061 38.011 24.1792 38.0325L29.605 39.019C30.6035 39.2007 31.2062 37.951 30.4423 37.2825L26.8418 34.1322C26.6248 33.9423 26.5003 33.668 26.5003 33.3795V28.4475C26.5003 27.7968 27.1117 27.3195 27.7428 27.4773L38.5912 30.1893C39.2223 30.3472 39.8337 29.8698 39.8337 29.2192V27.7208C39.8337 27.3762 39.656 27.0557 39.3637 26.8728L26.9703 19.1271C26.678 18.9444 26.5003 18.6239 26.5003 18.2791V10.5C26.5003 9.11928 25.381 8 24.0003 8C22.6197 8 21.5003 9.11928 21.5003 10.5Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>,
                    title: 'Sell or rent out',
                    text: 'Make transactions with buyers, agree on the price and terms of sale or lease if you own any type of aircraft. Get income thanks to the property'
                }
            ]
        },
        banner: {
            dark: true,
            title: 'Purchase aircrafts on raritygram',
            subtitle: 'Each Raritygram user needs to buy a card and upgrade it. The duration period can be for a year or for life. While making deals, an additional option — Rarity Pass — may be useful. It’s a subscription card allowing getting planes, helicopters and other aircraft types.',
            image: 'plane.jpg',
            imagePosition: 'left top',
            mobileImage: 'plane.jpg',
            mobileImagePosition: '-640px top',
        },
    },
    estate: {
        firstCards: {
            title: 'Estate anywgere in the world',
            cards: [
                {
                    svg: <svg width={49} height={54} viewBox="0 0 49 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M42 3V15M42 15L37 10.5789M42 15L47 10.5789" stroke="black" strokeWidth="2.83486" strokeLinecap="round" />
                        <path d="M6 25L24 14L42 25" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M38 29V41.8C38 42.4628 37.4628 43 36.8 43H11.2C10.5373 43 10 42.4628 10 41.8V29" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>,
                    title: 'Buy estate',
                    text: 'Find a desired house or flat and buy it, it doesn\'t matter where it’s located. Make dreams come true!'
                },
                {
                    svg: <svg width={48} height={54} viewBox="0 0 48 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M39 16C42.866 16 46 12.866 46 9C46 5.13401 42.866 2 39 2C35.134 2 32 5.13401 32 9" stroke="black" strokeWidth="2.83" strokeLinecap="round" />
                        <path d="M38 10V7" stroke="black" strokeWidth="2.5" strokeLinecap="round" />
                        <path d="M38 10L41 10" stroke="black" strokeWidth="2.5" strokeLinecap="round" />
                        <path d="M6 25L24 14L42 25" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M38 29V41.8C38 42.4628 37.4628 43 36.8 43H11.2C10.5373 43 10 42.4628 10 41.8V29" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>,
                    title: 'Rent estate',
                    text: 'Rent an apartment, an office or other rooms and buildings for any purpose and use it to achieve chosen goals.'
                },
                {
                    svg: <svg width={48} height={53} viewBox="0 0 48 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M41 15L41 3M41 3L46 7.42105M41 3L36 7.42105" stroke="black" strokeWidth="2.83486" strokeLinecap="round" />
                        <path d="M6 24L24 13L42 24" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M38 28V40.8C38 41.4628 37.4628 42 36.8 42H11.2C10.5373 42 10 41.4628 10 40.8V28" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>,
                    title: 'Sell or rent out',
                    text: 'Take profit of the property that you already have: sell or rent it out to other users, don’t let your property stand idle.'
                }
            ]
        },
        banner: {
            dark: true,
            title: 'Get estate',
            subtitle: 'To purchase the service, users need to upgrade their cards for a year or for life. It can be made directly on Raritygram. And also every client can get additional Rarity pass with subscription functionalities and get even more possibilities.',
            image: 'estate.jpg',
            imagePosition: 'left top',
            mobileImage: 'estate.jpg',
            mobileImagePosition: '-640px -125px',
        },
    }
}