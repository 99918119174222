import './DevBlogPage.scss';
import {useEffect, useMemo, useState} from "react";
import DevBlogDevItem from "./DevBlogDevItem/DevBlogDevItem";
import DevBlogNewsItem from "./DevBlogNewsItem/DevBlogNewsItem";
import ProjectBy from "../MainPage/ProjectBy/ProjectBy";
import {Link, useParams} from "react-router-dom";
import {news} from "../../content/news";
import cn from 'classnames';
import {articles} from "../../content/blog";
// import axios from "axios";

let defaultItemsInView = 3;

export default function DevBlogPage() {
    const params = useParams();
    const [searchValue, setSearchValue] = useState('');
    const [itemsInView, setItemsInView] = useState(defaultItemsInView);

    // const [newsArray, setNewsArray] = useState([]);

    const content = params.name === 'news' ? news : articles;
    const [items, setItems] = useState(content.slice(0, itemsInView));

    // useEffect(()=> {
    //     getAllNews();
    // },[]);

    useEffect(() => {
        setSearchValue('');
        setItemsInView(defaultItemsInView);
        setItems(content.slice(0, defaultItemsInView));
    }, [params.name]);

    // Search
    const onSearchChange = (e) => {
        setSearchValue(e.target.value);

        if (e.target.value.length >= 1) {
            setItems(content.filter(item => {
                const title = item.title.toLowerCase().replace(' ', '');
                return title.includes(e.target.value.toLowerCase().trim());
            }));
        } else {
            setItems(content.slice(0, itemsInView))
        }
    };

    const onLoadMoreClick = () => {
        setItemsInView(itemsInView + defaultItemsInView);
        setItems(content.slice(0, itemsInView + defaultItemsInView));
    };

    const blogList = useMemo(() => {
        return items.map(item => (
            <DevBlogDevItem item={item} key={item.name}/>
        ));
    }, [items]);

    const newsList = useMemo(()=> {
        return items.map(({name, date, title, desc})=> (
            <DevBlogNewsItem key={name} date={date} title={title} desc={desc}/>
        ));
    }, [items]);

    // const newsList = useMemo(()=> {
    //     return newsArray.map(({date, title, desc})=> (
    //         <DevBlogNewsItem key={title + desc} date={date} title={title} desc={desc}/>
    //     ));
    // }, [newsArray]);


    // const getAllNews = async () => {
    //     const response = await axios(process.env.REACT_APP_PUBLIC_NEWS_DATABASE)
    //     const array = await response.data.data
    //     const newArr = [];
    //
    //     for (const arrayElement of array) {
    //         const newsElem = {};
    //
    //         newsElem.date = arrayElement.attributes.date
    //         newsElem.title = arrayElement.attributes.title
    //         newsElem.desc = arrayElement.attributes.description
    //
    //         newArr.push(newsElem)
    //     }
    //
    //     setNewsArray(newArr)
    // };


    return (
        <main>
            <section className="devblog section">
                <div className="container">
                    <Link to={'/'} className="devblog__mobile-img">
                        <img src="/img/devblog/logo-black.svg" alt="Raritygram"/>
                    </Link>
                    <h1 className="devblog__title">DevBlog Raritygram:<br/>all about our work in the crypto world</h1>
                    <div className="devblog__navbar navbar-devblog flex">
                        <div className="navbar-devblog__tabs-wrap">
                            <ul className="navbar-devblog__tabs flex">
                                <li className={cn('navbar-devblog__tab', { 'navbar-devblog__tab_active': params.name === 'news' })}>
                                    <Link to={'/blog/news'}>
                                        <div className="navbar-devblog__tab-inner">
                                            news
                                        </div>
                                    </Link>
                                </li>
                                <li className={cn('navbar-devblog__tab', { 'navbar-devblog__tab_active': params.name === 'devblog' })}>
                                    <Link to={'/blog/devblog'}>
                                        <div className="navbar-devblog__tab-inner">
                                            devblog
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="navbar-devblog__search devblog-search">
                            <input
                                value={searchValue}
                                onChange={onSearchChange}
                                type="text"
                                placeholder="search"
                                className="devblog-search__input"
                            />
                            <svg className="devblog-search__loop" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.55295 17.105C10.4506 17.1046 12.2936 16.4694 13.7884 15.3004L18.4883 20L20 18.4883L15.3002 13.7888C16.4698 12.2938 17.1054 10.4505 17.1059 8.55249C17.1059 3.83686 13.2688 0 8.55295 0C3.83707 0 0 3.83686 0 8.55249C0 13.2681 3.83707 17.105 8.55295 17.105ZM8.55295 2.13812C12.0907 2.13812 14.9677 5.01497 14.9677 8.55249C14.9677 12.09 12.0907 14.9669 8.55295 14.9669C5.01523 14.9669 2.13824 12.09 2.13824 8.55249C2.13824 5.01497 5.01523 2.13812 8.55295 2.13812Z" fill="#041527"/>
                            </svg>
                        </div>
                    </div>
                    <div className="devblog__content-wrap">
                        <div className={cn('devblog__tab-content', { 'devblog__tab-content_active': params.name === 'news' })}>
                            <ul className="devblog__news devblog-news">
                                {newsList}
                            </ul>
                        </div>
                        <div className={cn('devblog__tab-content', { 'devblog__tab-content_active': params.name === 'devblog' })}>
                            <ul className="devblog__devs devblog-devs">
                                {blogList}
                            </ul>
                        </div>
                        <div
                            className={cn('devblog__news-load-button', { hide: itemsInView >= content.length })}
                            onClick={onLoadMoreClick}
                        >
                            Load more
                        </div>
                    </div>
                </div>
            </section>
            <ProjectBy/>
        </main>
    );
}