import './MintPage.scss';
import {useEffect} from "react";
import ProjectBy from "../MainPage/ProjectBy/ProjectBy";
import Socials from "../MainPage/Socials/Socials";
import Grade from "./Grade/Grade";
import Mint from "./Mint/Mint";
import CardsSection from "./CardsSection/CardsSection";
import MintServices from "./MintServices/MintServices";
import Forest from "./Forest/Forest";

const anchorHeight = 96;
const srcWhite = 'img/mainPage/logo-raritygram-white2.svg';
const srcDefault = 'img/mainPage/logo-raritygram.svg';

export default function MintPage() {

    useEffect(() => {
        const header = document.querySelector('.header');
        const cards = document.querySelector('.cards-section');
        const services = document.querySelector('.mint-services');
        const logo = document.querySelector('.header__logo img');

        function changeHeaderStyle() {
            let scrollY = window.pageYOffset;
            const headerOffset = header.getBoundingClientRect().top; // offset from top window
            const headerHeight = header.clientHeight;
            const cardsOffset = cards.offsetTop;
            const cardsHeight = cards.clientHeight;
            const servicesOffset = services.offsetTop;

            if (scrollY > cardsOffset - (headerHeight / 2) - headerOffset && scrollY < cardsOffset + cardsHeight + anchorHeight - (headerHeight / 2) - headerOffset) {
                header.classList.remove('header-mint-page');
                logo.src = srcDefault;

            } else if (scrollY >= servicesOffset - (headerHeight / 2) + headerOffset - anchorHeight)  {
                header.classList.remove('header-mint-page');
                logo.src = srcDefault;

            } else {
                header.classList.add('header-mint-page');
                logo.src = srcWhite;
            }
        }

        window.addEventListener('scroll', changeHeaderStyle);

        return () => window.removeEventListener('scroll', changeHeaderStyle);
    }, []);

    return (
        <main>
            <Mint/>
            <CardsSection/>
            <Grade/>
            <MintServices/>
            <Forest/>
            <Socials/>
            <ProjectBy/>
        </main>
    );
}