import './Everyone.scss';
import MainCard from "../../../components/MainCard/MainCard";

export default function Everyone() {

    const cards = [
        {
            svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width={52} height={52} rx={8} fill="#775BFF" fillOpacity="0.1"/>
                <path d="M13.75 40V38.25C13.75 31.4845 19.2345 26 26 26C32.7655 26 38.25 31.4845 38.25 38.25V40" stroke="#775BFF" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M26 26C29.8659 26 33 22.8659 33 19C33 15.134 29.8659 12 26 12C22.134 12 19 15.134 19 19C19 22.8659 22.134 26 26 26Z" stroke="#775BFF" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            </svg>,
            svgMarginBottom: '16',
            stage: 'stage 0',
            title: 'Users',
            text: 'Connect with others, write your own blog, and explore the unknown'
        },
        {
            svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width={52} height={52} rx={8} fill="#4FD283" fillOpacity="0.1"/>
                <path d="M39.906 21.9787C39.7666 22.37 39.55 22.7292 39.2692 23.0352C38.9885 23.3412 38.6491 23.5879 38.2714 23.7605C36.2607 24.6606 34.5525 26.1218 33.352 27.969C32.1517 29.8161 31.5099 31.9704 31.5039 34.1733C31.5063 34.9417 31.583 35.7081 31.7327 36.4618C31.8326 36.9225 31.8392 37.3985 31.7522 37.8617C31.6651 38.3248 31.4862 38.766 31.226 39.159C31.0058 39.5029 30.7161 39.797 30.3755 40.0223C30.0348 40.2474 29.6507 40.3989 29.2481 40.4667C28.3748 40.6308 27.4884 40.7129 26.5999 40.7119C24.6288 40.7129 22.6776 40.3176 20.8621 39.5498C19.0467 38.782 17.4041 37.6574 16.0318 36.2423C14.6595 34.8273 13.5855 33.1511 12.8737 31.313C12.1619 29.4748 11.8267 27.5124 11.888 25.5423C12.0148 21.8689 13.5047 18.3742 16.0672 15.7392C18.6297 13.1041 22.0814 11.5174 25.7499 11.2881H26.5836C29.3228 11.2888 32.0075 12.0542 34.3353 13.4983C36.663 14.9423 38.5414 17.0075 39.7589 19.4614C40.1336 20.2378 40.1865 21.1308 39.906 21.9461V21.9787Z" stroke="#48BF77" strokeWidth={3}/>
                <path d="M19.457 32.5549L19.4731 32.5371" stroke="#48BF77" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16.1875 26.0158L16.2035 25.998" stroke="#48BF77" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M19.457 19.4778L19.4731 19.46" stroke="#48BF77" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M25.9961 16.2082L26.0121 16.1904" stroke="#48BF77" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M32.5342 19.4778L32.5502 19.46" stroke="#48BF77" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            </svg>,
            svgMarginBottom: '16',
            stage: 'stage 1',
            title: 'Artists',
            text: 'Share your best works and gather likeminded users around you'
        },
        {
            svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width={52} height={52} rx={8} fill="#2948FF" fillOpacity="0.1"/>
                <path d="M11.917 39.1448V12.8559C11.917 12.3373 12.3373 11.917 12.8559 11.917H39.1448C39.6633 11.917 40.0837 12.3373 40.0837 12.8559V39.1448C40.0837 39.6633 39.6633 40.0837 39.1448 40.0837H12.8559C12.3373 40.0837 11.917 39.6633 11.917 39.1448Z" stroke="#2948FF" strokeWidth={3}/>
                <path d="M30.6946 20.5235C29.6227 19.4517 27.735 18.7061 26.0001 18.6594M21.3057 30.6948C22.3142 32.0395 24.1893 32.8064 26.0001 32.8715M26.0001 18.6594C23.936 18.6039 22.0881 19.5377 22.0881 22.0884C22.0881 26.7828 30.6946 24.4356 30.6946 29.13C30.6946 31.8074 28.404 32.9579 26.0001 32.8715M26.0001 18.6594V15.8291M26.0001 32.8715V36.1717" stroke="#2948FF" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            </svg>,
            svgMarginBottom: '16',
            stage: 'stage 1',
            title: 'Businesses',
            text: 'Post project updates, share your roadmap of development, and engage with your followers'
        }
    ];

    const cardList = cards.map((item)=> {
       return(
           <MainCard item={item} className={'everyone__card'} key={item.title}/>
       );
    });

    return (
      <section className={'everyone'}>
          <div className="container">
                <h2 className="everyone__title">
                    Raritygram is for everyone
                </h2>
                <div className="everyone__cards flex">
                    {cardList}
                </div>
          </div>
      </section>
    );
}