import './Access.scss';
import Button from "../../../components/Button/Button";

export default function Access() {
    return (
        <section className={'access'}>
            <div className="container">
                <div className="access__inner">
                    <video className="access__video" autoPlay muted loop playsInline>
                        <source src="img/mintPage/videos/mint-back.mp4" type="video/mp4"/>
                    </video>
                    <h2 className="access__title">
                        Get access to raritygram.com
                    </h2>
                    <div className="access__list access-list flex">
                        <div className="access-list__item flex">
                            <div className="access-list__icon flex">
                                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.3333 15.9997H23.2C23.6419 15.9997 24 16.3578 24 16.7997V25.8663C24 26.3082 23.6419 26.6663 23.2 26.6663H8.8C8.35817 26.6663 8 26.3082 8 25.8663V16.7997C8 16.3578 8.35817 15.9997 8.8 15.9997H10.6667H21.3333ZM21.3333 15.9997V10.6663C21.3333 8.88857 20.2667 5.33301 16 5.33301C11.7333 5.33301 10.6667 8.88857 10.6667 10.6663" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className="access-list__name flex">
                                access to beta test
                            </div>
                            <div className="access-list__advanced flex">
                                <div>+6 platform bonuses</div>
                                <div>+2 bonuses for tokens</div>
                            </div>
                        </div>
                        <div className="access-list__item flex">
                            <div className="access-list__icon flex">
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 10H12H16" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8 14H10H12" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.8214 2.48697 15.5291 3.33782 17L2.5 21.5L7 20.6622C8.47087 21.513 10.1786 22 12 22Z" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className="access-list__name flex">
                                chat with developers
                            </div>
                        </div>
                    </div>
                    <Button
                        to={'/mint'}
                        className={'button_purple access__button'}>
                        Buy NFT
                    </Button>
                </div>
            </div>
        </section>
    );
}