import './NftCard.scss';

export default function NftCard(props) {

    return (
        <div className={'nft-card'}>
            <div className="nft-card__image">
                <video style={props.name.includes('Sapphire_') ? {maxHeight: '366px'} : {maxHeight: '540px'}} autoPlay muted loop playsInline>
                    <source src={props.image} type="video/mp4"/>
                </video>
                <div className="nft-card__id flex">
                    <div>{props.id}</div>
                    TokenID
                </div>
            </div>
            <ul>
                <li>RarityScore {props.score}</li>
                <li>RarityRank {props.rank}</li>
            </ul>
            <h3>{props.name}</h3>
            <p>{props.desc}</p>
            <div className="nft-card__hash-wrap flex">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width={24} height={24} rx={12} fill="#FAFAFA"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.35254 5L12.7051 7.34368V8.70067L11.5027 9.24177V8.06184L8.35254 6.36559L5.20236 8.06184V11.1088L8.08064 12.7878L15.283 9.04257L19.6307 11.3836V15.8393L15.5667 18.21L11.5027 15.8393L11.5147 14.1252L12.7051 13.5V15.1487L15.5667 16.818L18.4283 15.1487V12.1018L15.2732 10.4029L8.04731 14.1604L4 11.7994V7.34368L8.35254 5Z" fill="#909299" stroke="#909299" strokeWidth="0.3" strokeLinecap="square"/>
                </svg>
                <div className="nft-card__hash">
                    0xf995EFaBe838125f1320B4F537f1E1462fB11779
                </div>
            </div>
        </div>
    );
}