import './RoadmapItem.scss';
import cn from "classnames";

export default function RoadmapItem({item, list}) {

    const listItems = list.map((item)=> {
       return(
           <li key={item}>{item}</li>
       );
    });

    return (
      <div className={cn('roadmap-item', { 'roadmap-item_active': item.active})}>
        <div className="roadmap-item__inner">
            <h3 className="roadmap-item__stage">{item.stage}</h3>
            <div className="roadmap-item__progress roadmap-progress">
                <div className="roadmap-progress__wrap flex">
                    <div className="roadmap-progress__name">design</div>
                    <div className="roadmap-progress__percent">{item.design}%</div>
                </div>
                <div className="roadmap-progress__bar">
                    <div className={'roadmap-progress__progress roadmap-progress__progress_green'} style={{width: `${item.design}%`}}/>
                </div>
            </div>
            <div className="roadmap-item__progress roadmap-progress">
                <div className="roadmap-progress__wrap flex">
                    <div className="roadmap-progress__name">development</div>
                    <div className="roadmap-progress__percent">{item.development}%</div>
                </div>
                <div className="roadmap-progress__bar">
                    <div className={'roadmap-progress__progress roadmap-progress__progress_blue'} style={{width: `${item.development}%`}}/>
                </div>
            </div>
            <ul>
                {listItems}
            </ul>
        </div>
      </div>
    );
}