export const government = {
    name: 'government',
    mainTitle: 'Service for legal and financial organisations',
    subtitle: 'Conduct key commodity exchange, tax, judicial and many other legal and financial processes within Raritygram while maintaining a significant part of the budget',
    mainImage: 'financial-main.jpg',
    mainImagePosition: 'center center',
    mobileMainImagePosition: 'center center',
    firstCards: {
        title: 'Feel secured with rarity goverment',
        cards: [
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 12H34" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14 18H34" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18 34H30" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6 24H5.2C4.53726 24 4 24.5372 4 25.2V42.8C4 43.4628 4.53726 44 5.2 44H42.8C43.4628 44 44 43.4628 44 42.8V25.2C44 24.5372 43.4628 24 42.8 24H42M6 24V5.2C6 4.53726 6.53726 4 7.2 4H40.8C41.4628 4 42 4.53726 42 5.2V24M6 24H42" stroke="black" strokeWidth={3} />
                </svg>,
                title: 'Paperless turnover',
                text: 'All processes will go through smart contracts, which saves users from having to dig through a pile of papers'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 23L23 29L33 19" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.99979 36.0001L6.26051 9.82515C6.11271 8.79057 6.78757 7.82503 7.80775 7.59833L23.132 4.19291C23.7036 4.06591 24.296 4.06591 24.8676 4.19291L40.1918 7.59833C41.212 7.82503 41.8868 8.79057 41.739 9.82513L37.9998 36.0001C37.8584 36.9901 36.9998 43.0001 23.9998 43.0001C10.9998 43.0001 10.1412 36.9901 9.99979 36.0001Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: '100% safety',
                text: 'Thanks to Rarity Government all processes are honest, secure and transparent'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29 17C27.4386 16.5952 25.2688 16 23.5294 16C14.9527 16 8 21.3352 8 27.9166C8 31.6986 10.2959 35.069 13.8765 37.2522L12.9064 40.4518C12.6727 41.2224 13.2494 42 14.0547 42H17.5829C17.8427 42 18.0955 41.9158 18.3032 41.7598L21.0924 39.6666H25.9662L28.7554 41.7598C28.9632 41.9158 29.216 42 29.4758 42H33.004C33.8092 42 34.386 41.2224 34.1522 40.4518L33.1822 37.2522C35.3154 35.9516 36.9926 34.2294 38 32.25" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M29 17L38 14L37.832 21.2566L42 23V30L38.1482 32" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M31 26C30.4478 26 30 25.5522 30 25C30 24.4478 30.4478 24 31 24C31.5522 24 32 24.4478 32 25C32 25.5522 31.5522 26 31 26Z" fill="black" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4 20C4 20 4 24.8 8 26" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M25.6016 15.5059C25.8596 14.7626 26 13.9627 26 13.1294C26 9.19196 22.866 6 19 6C15.134 6 12 9.19196 12 13.1294C12 15.0128 12.717 16.7255 13.8881 18" stroke="black" strokeWidth={3} strokeLinejoin="round" />
                </svg>,
                title: 'saving budget',
                text: 'Making deals and transactions online, users save not only time, but also money resources'
            }
        ]
    },
    secondCards: {
        title: 'What does rarity goverment include?',
        cards: [
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 8H12C9.79086 8 8 9.79086 8 12V40C8 42.2092 9.79086 44 12 44H24" stroke="black" strokeWidth={3} strokeLinecap="round" />
                    <path d="M31 8H36C38.2092 8 40 9.79086 40 12V30" stroke="black" strokeWidth={3} strokeLinecap="round" />
                    <path d="M16 12.8V9C16 8.44772 16.4477 8 17 8C17.5523 8 18.0084 7.55208 18.103 7.00796C18.3994 5.30342 19.548 2 24 2C28.452 2 29.6006 5.30342 29.897 7.00796C29.9916 7.55208 30.4478 8 31 8C31.5522 8 32 8.44772 32 9V12.8C32 13.4627 31.4628 14 30.8 14H17.2C16.5373 14 16 13.4627 16 12.8Z" stroke="black" strokeWidth={3} strokeLinecap="round" />
                    <path d="M31 41L35 45L45 35" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
                </svg>,
                title: 'property registers',
                text: 'Remote format of work using the Ratitygram platform allows you to choose exactly the place where the user feels more comfortable'
            },
            {
                svg: <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.8 44H7.2C6.53726 44 6 43.4627 6 42.8V35.2C6 34.5373 6.53726 34 7.2 34H18.8C19.4627 34 20 34.5373 20 35.2V42.8C20 43.4627 19.4627 44 18.8 44Z" stroke="black" strokeWidth={3} />
                    <path d="M29.8 14H18.2C17.5373 14 17 13.4627 17 12.8V5.2C17 4.53726 17.5373 4 18.2 4H29.8C30.4627 4 31 4.53726 31 5.2V12.8C31 13.4627 30.4627 14 29.8 14Z" stroke="black" strokeWidth={3} />
                    <path d="M40.8 44H29.2C28.5373 44 28 43.4627 28 42.8V35.2C28 34.5373 28.5373 34 29.2 34H40.8C41.4627 34 42 34.5373 42 35.2V42.8C42 43.4627 41.4627 44 40.8 44Z" stroke="black" strokeWidth={3} />
                    <path d="M13 34V27C13 24.7908 14.7909 23 17 23H31C33.2092 23 35 24.7908 35 27V34" stroke="black" strokeWidth={3} />
                    <path d="M24 23V14" stroke="black" strokeWidth={3} />
                </svg>,
                title: 'multicultural infrastructure',
                text: 'The infrastructure is being developed and deployed in different countries and can be relevant for small economies or private cities.'
            }
        ]
    },
    banner: {
        title: 'Available for every user',
        subtitle: 'If the jurisdiction of the company or organisation allows the use of the proposed format of work, all users can freely use the services of the Rarity Government. NFTRarity card owners will be offered special conditions',
        image: 'fin-banner.jpg',
        imagePosition: 'left -540px',
        mobileImage: 'fin-banner.jpg',
        mobileImagePosition: 'left -460px',
    }
};