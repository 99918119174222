import './CardsSection.scss';

export default function CardsSection() {
    return (
        <section className="cards-section section">
            <div className="container">
                <ul className="cards-section__list flex">
                    <li className="cards-section__item">
                        <div className="cards-section__card-inner card-inner card-inner__high card-inner_lock">
                            <h3 className="card-inner__title">Early access</h3>
                            <div className="card-inner__subtitle">
                                You will be among the first ones to try our platform’s new functions
                            </div>
                            <img src="/img/mintPage/lock.png" alt="Lock"/>
                        </div>
                    </li>
                    <li className="cards-section__item cards-section__item_double">
                        <div className="cards-section__card-inner card-inner card-inner__low card-inner_t-shirt">
                            <h4 className="card-inner__title">character customizing</h4>
                            <div className="card-inner__subtitle">
                                Choose from a variety of clothes items and accessories to make your
                                character unique
                            </div>
                            <img src="/img/mintPage/t-shirt.png" alt="T-shirt"/>
                        </div>
                        <div className="cards-section__card-inner card-inner card-inner__low card-inner_snow">
                            <h4 className="card-inner__title">Unfreeze your assets faster</h4>
                            <div className="card-inner__subtitle">
                                You’ll be able to get your assets unfrozen 45 days earlier than others
                            </div>
                            <img src="/img/mintPage/snow.png" alt="Snow"/>
                        </div>
                    </li>
                    <li className="cards-section__item cards-section__item_double">
                        <div className="cards-section__card-inner card-inner card-inner__low card-inner_page">
                            <h4 className="card-inner__title">page customization</h4>
                            <div className="card-inner__subtitle">
                                Change page’s borders and background, use GIFs and Emoji to make your
                                experience
                                special
                            </div>
                            <img src="/img/mintPage/page.png" alt="Page"/>
                        </div>
                        <div className="cards-section__card-inner card-inner card-inner__low card-inner_coin">
                            <h4 className="card-inner__title">mooore coins</h4>
                            <div className="card-inner__subtitle">
                                Get a bonus and increase the amount of your coins by up to 20%
                            </div>
                            <img src="/img/mintPage/raritygram-coin.png" alt="Coin"/>
                        </div>
                    </li>
                    <li className="cards-section__item">
                        <div className="cards-section__card-inner card-inner card-inner__high card-inner_secret">
                            <h3 className="card-inner__title">secret features</h3>
                            <div className="card-inner__subtitle">
                                Special bonuses that will soon be revealed
                            </div>
                            <img src="/img/mintPage/secret.png" alt="Secret"/>
                        </div>
                    </li>

                    <li className="cards-section__item cards-section__item_double">
                        <div className="cards-section__card-inner card-inner card-inner__low card-inner_stars">
                            <h4 className="card-inner__title">bonus stars</h4>
                            <div className="card-inner__subtitle">
                                You’ll be able to get your assets unfrozen 45 days earlier than others
                            </div>
                            <img src="/img/mintPage/stars.png" alt="Page"/>
                        </div>
                    </li>

                    <li className="cards-section__item cards-section__item_double">
                        <div className="cards-section__card-inner card-inner card-inner__low card-inner_page">
                            <h4 className="card-inner__title">Lands in metaverse</h4>
                            <div className="card-inner__subtitle">
                                Get a bonus and increase the amount of your coins by up to 20%
                            </div>
                            <img src="/img/mintPage/lands.png" alt="Page"/>
                        </div>
                    </li>


                </ul>
            </div>
        </section>
    );
}