import './GradeTrait.scss';
import Tooltip from "../../../../components/Tooltip/Tooltip";
import {useState} from "react";

export default function GradeTrait({item}) {

    const [isShowed, setIsShowed] = useState(false);

    return (
        <li
            onMouseEnter={() => setIsShowed(true)}
            onMouseLeave={() => setIsShowed(false)}
            className="grade-item__item flex"
            key={item.name}
        >
            {item.tooltip ?
                (
                    <Tooltip isShowed={isShowed}/>
                ) : null
            }
            <div className="grade-item__item-inner flex">
                <img src={`img/mintPage/grade/${item.icon}`} alt="Icon" className="grade-item__item-icon"/>
                <div className="grade-item__item-content">
                    <div className="grade-item__item-title">
                        {item.name}
                    </div>
                    {item.desc ? (
                        <div className="grade-item__item-desc">
                            {item.desc}
                        </div>
                    ) : null}
                </div>
            </div>

            {item.lockUp ? (
                <div className="grade-item__item-inner grade-item__item-inner_last flex">
                    <img src={`img/mintPage/grade/${item.lockUpIcon}`} alt="Icon"
                         className="grade-item__item-icon"/>
                    <div className="grade-item__item-content">
                        <div className="grade-item__item-title">
                            {item.lockUp}
                        </div>
                    </div>
                </div>
            ) : null}
        </li>
    );
}