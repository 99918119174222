import './Meta.scss';
import MainCard from "../../../components/MainCard/MainCard";
import {meta} from "./metaItems";
import Slider from "react-slick";
import {useRef} from "react";

export default function Meta() {

    const SliderRef = useRef(null);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        appendDots: dots => (
            <div className={'slider__nav-wrap'}>
                <ul>{dots}</ul>
            </div>
        )
    };

    const metaList = meta.map((item)=> {
        return (
            <MainCard className={'meta__card'} item={item} button={item.button} key={item.title}/>
        );
    });

    const metaSliderList = meta.map((item)=> {
        return (
            <div className="slider__item" key={item.title}>
                <MainCard className={'meta__card'} item={item} button={item.button}/>
            </div>
        );
    });


    return (
        <section className={'meta'}>
            <div className="container">
                <h2 className="meta__title">
                    Raritygram Meta
                </h2>
                <ul className={'meta__list'}>
                    {metaList}
                </ul>
                <div className="meta__slider">
                    <Slider ref={SliderRef} {...settings}>
                        {metaSliderList}
                    </Slider>
                </div>
            </div>
        </section>
    );

}