import './Button.scss';
import {Link} from "react-router-dom";
import cn from "classnames";

export default function Button(props) {

    return (
        <>
            {props.to ? (
                <Link
                    onClick={props.onClick}
                    to={props.to}
                    className={cn('button', {[props.className]: props.className})}
                >
                    {props.children}
                    {props.icon ?
                        <img src={props.icon} alt="arrow"/> :
                        null
                    }
                </Link>
            ) : (
                <a
                    onClick={props.onClick}
                    href={props.href}
                    target="_blank"
                    rel={'noreferrer'}
                    className={cn('button', {[props.className]: props.className})}
                >
                    {props.children}
                    {props.icon ?
                        <img src={props.icon} alt="arrow"/> :
                        null
                    }
                </a>
                )
            }
        </>
    );
}