import './ItemDate.scss';

export default function ItemDate({date, color, time}) {
    return (
        <div className="devs-item__date item-date flex">
            {date}
            <span className="devs-item__time">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 14.25C11.4518 14.25 14.25 11.4518 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75C4.54822 1.75 1.75 4.54822 1.75 8C1.75 11.4518 4.54822 14.25 8 14.25Z"
                          stroke={color ? color : '#8D949D'} strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M8.25 4.75V8.25L5.75 10.25" stroke={color ? color : '#8D949D'} strokeWidth="1.5"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                {time}
            </span>
        </div>
    );
}