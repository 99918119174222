import './Blockchain.scss';
import MainCard from "../../../components/MainCard/MainCard";
import {blockchain} from "./blockchainItems";
import Slider from "react-slick";
import {useRef} from "react";

export default function Blockchain() {

    const SliderRef = useRef(null);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        appendDots: dots => (
            <div className={'slider__nav-wrap'}>
                <ul>{dots}</ul>
            </div>
        )
    };

    const blockchainList = blockchain.map((item)=> {
        return (
            <MainCard className={'blockchain__card'} item={item} button={item.button} key={item.title}/>
        );
    });

    const blockchainSliderList = blockchain.map((item)=> {
        return (
            <div className="slider__item" key={item.title}>
                <MainCard className={'blockchain__card'} item={item} button={item.button} key={item.title}/>
            </div>
        );
    });

    return (
        <section className="blockchain section">
            <div className="container">

                <div className="blockchain__head flex">
                    <div className="blockchain__title-wrap">
                        <h2 className="blockchain__title">Blockchain</h2>
                        <p className="blockchain__subtitle">
                            The fastest and most secure blockchain to date
                        </p>
                    </div>
                    <div className="blockchain__offers-wrap flex">
                        <ul className="flex">
                            <li className="blockchain__offers-item check-item">
                                <div className="blockchain__offers-name">
                                    Minimum commission within the system
                                </div>
                                Invest funds from $1
                            </li>
                            <li className="blockchain__offers-item check-item">
                                <div className="blockchain__offers-name">
                                    Reincarnation of current social institutions
                                </div>
                                Use registries, legal proceedings, utility bills, etc. in a new way
                            </li>
                        </ul>
                        <ul className="flex">
                            <li className="blockchain__offers-item check-item">
                                <div className="blockchain__offers-name">
                                    Smart contracts as agreements
                                </div>
                                Create fast and secure agreements
                            </li>
                            <li className="blockchain__offers-item check-item">
                                <div className="blockchain__offers-name">
                                    Cheap, secure, and fast
                                </div>
                                The most demanded features all in one blockchain
                            </li>
                        </ul>
                    </div>
                </div>

                <ul className={'blockchain__list'}>
                    {blockchainList}
                </ul>

                <div className="blockchain__slider">
                    <Slider ref={SliderRef} {...settings}>
                        {blockchainSliderList}
                    </Slider>
                </div>

                <div className="blockchain__button">
                    <div className="blockchain__button_inner flex">
                        <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z" stroke="#041527" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12.5 6V12L16.5 14" stroke="#041527" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Blockchain introduced in stage 3
                    </div>
                </div>

            </div>
        </section>
    );
}