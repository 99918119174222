import './MainPage.scss';
import ProjectBy from "./ProjectBy/ProjectBy";
import Socials from "./Socials/Socials";
import Hero from "./Hero/Hero";
import Metaverse from "./Metaverse/Metaverse";
import Token from "./Token/Token";
import Blockchain from "./Blockchain/Blockchain";
import RoadMap from "./RoadMap/RoadMap";
import NewLevel from "./NewLevel/NewLevel";
import Everyone from "./Everyone/Everyone";
import Access from "./Access/Access";
import Questions from "./Questions/Questions";
import Ecosystem from "./Ecosystem/Ecosystem";
import Identity from "./Identity/Identity";
import Meta from "./Meta/Meta";
import MainFeatures from "./MainFeatures/MainFeatures";
import Crosschain from "./Crosschain/Crosschain";
import OurTeam from "./OurTeam/OurTeam";
import OurPartners from "./OurPartners/OurPartners";
import WriteAbout from "./WriteAbout/WriteAbout";
import Modal from "../../components/Modal/Modal";
import {useState} from "react";
import PopUpMint from "../../components/PopUpMint/PopUpMint";
import MintNow from "../../components/MintNow/MintNow";
import Videos from "./Videos/Videos";

export default function MainPage() {

    const [isPopupMintOpen, setIsPopupMintOpen] = useState(false);
    const [isMintNowOpen, setIsMintNowOpen] = useState(false);

    // useEffect(()=> {
    //
    //     const showBottomPopup = () => {
    //         let scrollY = window.pageYOffset;
    //         console.log('scrollY',scrollY)
    //
    //         if (scrollY >= 1500) {
    //             setIsMintNowOpen(true)
    //         }
    //     }
    //
    //     window.addEventListener('scroll', showBottomPopup)
    //
    //     return () => window.removeEventListener('scroll', showBottomPopup);
    // },[]);

    return (
        <main>
            <Modal isOpened={isPopupMintOpen} onClose={() => setIsPopupMintOpen(false)}>
                <PopUpMint onClick={() => setIsPopupMintOpen(false)}/>
            </Modal>
            <MintNow isOpened={isMintNowOpen} onClick={() => setIsMintNowOpen(false)}/>
            <Hero/>
            {/*<Raffle/>*/}
            <NewLevel/>
            <Everyone/>
            <MainFeatures/>
            <Videos/>
            <Access/>
            <RoadMap/>
            <Questions/>
            <Crosschain/>
            <Ecosystem/>
            <Identity/>
            <Meta/>
            <Metaverse/>
            <Token/>
            <Blockchain/>
            <OurPartners/>
            <WriteAbout/>
            <Socials/>
            <OurTeam/>
            <ProjectBy/>
        </main>
    );
}