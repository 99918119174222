import './ArticlePage.scss';
import ProjectBy from "../MainPage/ProjectBy/ProjectBy";
import MoreAbout from "./MoreAbout/MoreAbout";
import {Link, useParams} from "react-router-dom";
import {articles} from "../../content/blog";
import ItemDate from "../../components/ItemDate/ItemDate";
import AuthorWrap from "../../components/AuthorWrap/AuthorWrap";
import ArticleMainContent from "./ArticleMainContent/ArticleMainContent";

export default function ArticlePage() {

    const params = useParams();
    const article = articles.find(article => article.name === params.name);

    return (
        <main>
            <section className="article-head section flex">
                <img src="/img/articles/article-first-back.jpg" alt="#" className="article-head__background-img"/>
                <div className="container flex">
                    <div className="article-main__title-wrap flex">
                        <h1 className="article-main__title">
                            {article.title}
                        </h1>
                        <p className="article-main__subtitle">
                            {article.shortDesc}
                        </p>
                    </div>
                    <div className="article-head__date">
                        <ItemDate date={article.date} time={article.time}/>
                    </div>
                    <div className="article-head__wrap flex">
                        <div className="article-head__arrow-back">
                            <Link to={"/blog/devblog"}>
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12L12 18M18.5 12H6H18.5ZM6 12L12 6L6 12Z" stroke="#041527" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </Link>
                        </div>
                        <AuthorWrap
                            authorAvatar={article.authorAvatar}
                            authorName={article.authorName}
                            authorPost={article.authorPost}
                        />
                        <div className="article-main__share-button flex">
                            share
                            <div className="article-main__share-icon">
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 13V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V13" stroke="#041527" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12 3L15.5 6.5M12 15V3V15ZM12 3L8.5 6.5L12 3Z" stroke="#041527" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="article-main section">
                <div className="container">
                    <ArticleMainContent array={article.content} />
                </div>
            </section>

            <MoreAbout cards={articles.slice(1, 4)}/>
            <ProjectBy/>
        </main>
    );
}