import './NewLevel.scss';
import {useState} from "react";
import cn from "classnames";

export default function NewLevel() {

    const [windowWidth] = useState(window.innerWidth);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const tabNames = ['Feed', 'User profile'];
    const imagesSrc = ['rari-main.png', 'rari-profile.png'];
    const imagesSrcMobile = ['rari-main-mobile.png', 'rari-profile-mobile.png'];

    const onTabClick = (e) => {
        let index = [...e.target.parentElement.children].indexOf(e.target);
        setActiveTabIndex(index - 1)
    };


    const tabList = tabNames.map((item, index)=> {
       return (
           <div
               className={cn("new-level__tab flex", { 'new-level__tab_active': activeTabIndex === index })}
               onClick={onTabClick}
               key={item}>{item}
           </div>
       );
    });

    const imageList = (windowWidth >= 576 ? imagesSrc : imagesSrcMobile).map((item, index)=> {
       return (
           <img
               className={cn('new-level__image', { 'new-level__image_active': activeTabIndex === index })}
               src={`img/mainPage/newLevel/${item}`}
               alt="Raritygram.com"
               key={item}
           />
       );
    });

    return (
      <section className={'new-level'}>
          <div className="container">
              <h2 className="new-level__title">
                  Raritygram is the next level of social media
              </h2>
              <p className="new-level__subtitle">
                  We’ve created a place where you can safely express your ideas and explore your potential in blockchain, GameFi, DeFi, and NFT development — try it out
              </p>
              <div className="new-level__preview">
                  <div className="new-level__tabs flex">
                    <div
                        className="new-level__tab-back"
                        style={activeTabIndex === 0 ? {top: '6px', left: '6px'} : {top: '6px', left: 'calc(50% + 6px)'}}
                    />
                      {tabList}
                  </div>
                  <div className="new-level__images">
                      {imageList}
                  </div>
              </div>
          </div>
      </section>
    );
}