import './QuestionsItem.scss';

export default function QuestionsItem({item}) {
    return (
        <li className={'questions-item flex'}>
            <div className="questions-item__icon">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="4" fill="#2948FF" fillOpacity="0.1"/>
                    <path d="M14.56 18.768C14.528 18.64 14.512 18.52 14.512 18.408C14.512 18.28 14.512 18.152 14.512 18.024C14.512 17.544 14.608 17.104 14.8 16.704C15.008 16.304 15.256 15.928 15.544 15.576C15.848 15.224 16.176 14.896 16.528 14.592C16.88 14.272 17.2 13.952 17.488 13.632C17.792 13.296 18.048 12.952 18.256 12.6C18.464 12.232 18.576 11.832 18.592 11.4C18.592 10.68 18.368 10.152 17.92 9.816C17.472 9.48 16.84 9.312 16.024 9.312C15.72 9.312 15.424 9.352 15.136 9.432C14.848 9.496 14.584 9.6 14.344 9.744C14.104 9.888 13.904 10.08 13.744 10.32C13.584 10.56 13.48 10.848 13.432 11.184H10.768C10.8 10.56 10.944 9.984 11.2 9.456C11.456 8.912 11.808 8.448 12.256 8.064C12.72 7.68 13.28 7.376 13.936 7.152C14.592 6.928 15.344 6.816 16.192 6.816C16.864 6.816 17.512 6.896 18.136 7.056C18.76 7.216 19.304 7.472 19.768 7.824C20.248 8.16 20.624 8.6 20.896 9.144C21.184 9.688 21.328 10.344 21.328 11.112C21.328 11.832 21.2 12.472 20.944 13.032C20.688 13.576 20.32 14.104 19.84 14.616C19.552 14.936 19.248 15.24 18.928 15.528C18.608 15.816 18.312 16.12 18.04 16.44C17.784 16.744 17.568 17.072 17.392 17.424C17.232 17.776 17.152 18.168 17.152 18.6V18.768H14.56ZM15.88 20.712C16.424 20.712 16.848 20.88 17.152 21.216C17.472 21.536 17.632 21.96 17.632 22.488C17.632 23 17.472 23.416 17.152 23.736C16.848 24.04 16.424 24.192 15.88 24.192C15.336 24.192 14.904 24.04 14.584 23.736C14.264 23.432 14.104 23.016 14.104 22.488C14.104 21.96 14.264 21.536 14.584 21.216C14.904 20.88 15.336 20.712 15.88 20.712Z" fill="#2948FF"/>
                </svg>
            </div>
            <div className="questions-item__content">
                <h3>{item.title}</h3>
                <p>{item.text}</p>
            </div>
        </li>
    );
}