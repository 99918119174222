import ButtonLink from "../../../components/Button/ButtonLink";
import Button from "../../../components/Button/Button";

export const ecosystem = [
    {
        svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={52} height={52} rx={8} fill="#EBF1F5"/>
            <path d="M31.2363 36.5H36.4863M41.7363 36.5H36.4863M36.4863 36.5V31.25M36.4863 36.5V41.75" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26.7212 23.1001L11.8721 37.9493L15.5844 41.6616M26.7212 23.1001C29.4549 25.8337 33.8871 25.8337 36.6208 23.1001C39.3545 20.3665 39.3545 15.9343 36.6208 13.2006C33.8871 10.467 29.4549 10.467 26.7212 13.2006C23.9876 15.9343 23.9876 20.3665 26.7212 23.1001Z" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.8223 33L20.5346 36.7123" stroke="black" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        stage: 'stage 2',
        title: 'Rarity Rent',
        text: 'Buy and sell real estate, cars, yachts, and jets all around the world with crypto.',
        button: <Button to={'/services/rent'} className={'button-gray'}>More</Button>
    },
    {
        svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={52} height={52} rx={8} fill="#EBF1F5" />
            <path d="M10.25 40.25C9.42157 40.25 8.75 40.9216 8.75 41.75C8.75 42.5784 9.42157 43.25 10.25 43.25V40.25ZM41.75 43.25C42.5784 43.25 43.25 42.5784 43.25 41.75C43.25 40.9216 42.5784 40.25 41.75 40.25V43.25ZM10.25 43.25H41.75V40.25H10.25V43.25Z" fill="#0C2A3E" />
            <path d="M13.5625 41.75C13.5625 42.5784 14.2341 43.25 15.0625 43.25C15.8909 43.25 16.5625 42.5784 16.5625 41.75H13.5625ZM35.4375 41.75C35.4375 42.5784 36.1091 43.25 36.9375 43.25C37.7659 43.25 38.4375 42.5784 38.4375 41.75H35.4375ZM28.2361 11.368L27.5652 12.7097L28.2361 11.368ZM16.5625 41.75V25.6875H13.5625V41.75H16.5625ZM14.0625 23.1875H12.25V26.1875H14.0625V23.1875ZM11.75 22.6875V19.3611H8.75V22.6875H11.75ZM12.0264 18.9139L24.4348 12.7097L23.0931 10.0264L10.6848 16.2306L12.0264 18.9139ZM27.5652 12.7097L39.9736 18.9139L41.3152 16.2306L28.9069 10.0264L27.5652 12.7097ZM40.25 19.3611V22.6875H43.25V19.3611H40.25ZM39.75 23.1875H37.9375V26.1875H39.75V23.1875ZM35.4375 25.6875V41.75H38.4375V25.6875H35.4375ZM37.9375 23.1875C36.5568 23.1875 35.4375 24.3068 35.4375 25.6875H38.4375C38.4375 25.9636 38.2136 26.1875 37.9375 26.1875V23.1875ZM40.25 22.6875C40.25 22.9636 40.0261 23.1875 39.75 23.1875V26.1875C41.683 26.1875 43.25 24.6205 43.25 22.6875H40.25ZM39.9736 18.9139C40.143 18.9986 40.25 19.1717 40.25 19.3611H43.25C43.25 18.0354 42.501 16.8234 41.3152 16.2306L39.9736 18.9139ZM24.4348 12.7097C25.4201 12.217 26.5799 12.217 27.5652 12.7097L28.9069 10.0264C27.077 9.11143 24.923 9.11143 23.0931 10.0264L24.4348 12.7097ZM11.75 19.3611C11.75 19.1717 11.857 18.9986 12.0264 18.9139L10.6848 16.2306C9.49901 16.8234 8.75 18.0354 8.75 19.3611H11.75ZM12.25 23.1875C11.9739 23.1875 11.75 22.9636 11.75 22.6875H8.75C8.75 24.6205 10.317 26.1875 12.25 26.1875V23.1875ZM16.5625 25.6875C16.5625 24.3068 15.4432 23.1875 14.0625 23.1875V26.1875C13.7864 26.1875 13.5625 25.9636 13.5625 25.6875H16.5625Z" fill="#0C2A3E" />
            <path d="M22.5 41.75V30.375" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" />
            <path d="M37.375 24.6875L30.375 24.6875" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" />
            <path d="M29.5 41.75V30.375" stroke="#0C2A3E" strokeWidth={3} strokeLinecap="round" />
        </svg>,
        stage: 'stage 2',
        title: 'Rarity Government',
        text: 'This feature allows citizens to interact with their government duties safely through smart contracts.',
        button: <Button to={'/services/government'} className={'button-gray'}>More</Button>
    },
    {
        svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={52} height={52} rx={8} fill="#EBF1F5"/>
            <rect x={9} y={9} width={34} height={34} rx="8.5" stroke="#041527" strokeWidth={3}/>
            <path d="M19.7559 25.4502L24.2187 29.9131L33.1445 20.9874" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        stage: 'stage 2',
        title: 'Rarity Vote',
        text: 'With RarityID, you’ll be able to safely keep your personal data on the blockchain and use it as a form of identification.',
        button: <Button to={'/services/voting'} className={'button-gray'}>More</Button>
    },
    {
        svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={52} height={52} rx={8} fill="#EBF1F5"/>
            <path d="M24.25 19.875L22.15 20.75L9.11551 26.6748C8.74066 26.8451 8.5 27.2189 8.5 27.6307V27.8694C8.5 28.2811 8.74066 28.6549 9.11551 28.8252L24.5517 35.8417C25.4719 36.2599 26.5281 36.2599 27.4483 35.8417L42.8845 28.8252C43.2594 28.6549 43.5 28.2811 43.5 27.8694V27.6307C43.5 27.2189 43.2594 26.8451 42.8845 26.6748L29.85 20.75L27.75 19.875" stroke="#041527" strokeWidth={3}/>
            <path d="M43.5 27.75V34.946C43.5 35.3593 43.2576 35.734 42.8809 35.9036L27.4362 42.8537C26.5229 43.2646 25.4771 43.2646 24.5638 42.8537L9.11912 35.9036C8.74232 35.734 8.5 35.3593 8.5 34.946V27.75" stroke="#041527" strokeWidth={3}/>
            <path d="M26 19C23.1004 19 20.75 16.6495 20.75 13.75C20.75 10.8505 23.1004 8.5 26 8.5C28.8996 8.5 31.25 10.8505 31.25 13.75C31.25 16.6495 28.8996 19 26 19Z" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24.25 19V27.75C24.25 28.7165 25.0335 29.5 26 29.5C26.9665 29.5 27.75 28.7165 27.75 27.75V19" stroke="#041527" strokeWidth={3}/>
            <path d="M33 27.75H34.75" stroke="#041527" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        stage: 'stage 2',
        title: 'Rarity Games',
        text: 'Play existing games or add your own, invite your friends, and compete in fun challenges with the GameFi feature of Raritygram.'
    },
    {
        svg: <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={52} height={52} rx={8} fill="#EBF1F5" />
            <path d="M43.182 21.3873L40.2175 11.0115C40.0887 10.5608 39.6768 10.25 39.2079 10.25H32.125L32.9568 20.2316C32.9841 20.5583 33.1615 20.8533 33.4431 21.0212C34.1244 21.4272 35.4591 22.1797 36.5 22.5C38.2776 23.0469 40.8764 22.8497 42.3564 22.6677C42.9719 22.5921 43.3525 21.9836 43.182 21.3873Z" stroke="#0C2A3E" strokeWidth={3} />
            <path d="M29.4998 22.5C30.493 22.1944 31.7537 21.4953 32.4589 21.0791C32.7947 20.881 32.9795 20.5068 32.9472 20.1183L32.1248 10.25H19.8748L19.0525 20.1183C19.0201 20.5068 19.205 20.881 19.5407 21.0791C20.246 21.4953 21.5067 22.1944 22.4998 22.5C25.1126 23.3039 26.8871 23.3039 29.4998 22.5Z" stroke="#0C2A3E" strokeWidth={3} />
            <path d="M11.7826 11.0115L8.8181 21.3873C8.64774 21.9836 9.02835 22.5921 9.64383 22.6677C11.1237 22.8497 13.7226 23.0469 15.5002 22.5C16.541 22.1797 17.8758 21.4272 18.5571 21.0212C18.8386 20.8533 19.0162 20.5583 19.0434 20.2316L19.8752 10.25H12.7922C12.3234 10.25 11.9114 10.5608 11.7826 11.0115Z" stroke="#0C2A3E" strokeWidth={3} />
            <path d="M10.25 22.5V38.25C10.25 40.183 11.817 41.75 13.75 41.75H38.25C40.183 41.75 41.75 40.183 41.75 38.25V22.5" stroke="#0C2A3E" strokeWidth={3} />
        </svg>,
        stage: 'stage 1',
        title: 'Marketplace',
        text: 'Feel the unity of the physical and the spiritual. Raritygram integrates social media functionality, metaverse omnipotence, latest digital art technologies, and other features of the platform.'
    }
];