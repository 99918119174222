import './HowToMintButton.scss';

export default function HowToMintButton() {
    return (
        <a href={'https://www.youtube.com/watch?v=l5U4DnhqBnw'} className="how-to-mint-button flex">
            <div className="how-to-mint-button__icon flex">
                <svg width={12} height={14} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2223 6.2302C11.8149 6.57233 11.8149 7.42767 11.2223 7.7698L1.889 13.1584C1.2964 13.5005 0.555663 13.0729 0.555663 12.3886L0.555664 1.6114C0.555664 0.927129 1.2964 0.499463 1.889 0.841597L11.2223 6.2302Z" fill="#2948FF" />
                </svg>
            </div>
            How to mint NFT?
        </a>
    );
}