import './Questions.scss';
import QuestionsItem from "./QuestionsItem/QuestionsItem";

export default function Questions() {

    const questions = [
        {
            title: 'Do I need to buy an NFTRarity?',
            text: 'To get early access to the beta-features, buy a unique NFTRarity and get bonuses, tokens, and early adopter features.'
        },
        {
            title: 'What makes Raritygram unique?',
            text: 'Raritygram is the first real social media built on the blockchain, making it secure, anonymous, and decentralized. Also, no spam, bots, or cheaters.'
        },
        {
            title: 'Can I access Raritygram if I do not own an NFTRarity?',
            text: 'During stage 0, only NFTRarity owners can create profiles, publish posts, and share their assets. Without an NFTRarity, users will have access to the feed filled with top collections. Stage 1 will allow everyone to create profiles.'
        }
    ];

    const questionList = questions.map((item)=> {
       return (
         <QuestionsItem item={item} key={item.title}/>
       );
    });

    return (
        <section className={'questions'}>
            <div className="container">
                <div className="questions__wrap flex">
                    <h2 className="questions__title">
                        Frequently Asked Questions
                    </h2>
                    <ul className="questions__list">
                        {questionList}
                    </ul>
                </div>
            </div>
        </section>
    );
}