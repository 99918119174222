import './Metaverse.scss';
import Button from "../../../components/Button/Button";
import {Suspense} from "react";

export default function Metaverse() {

    return (
        <section id={'metaverse'} className="welcome-section about-universe section">
            <div className="container">
                <div className="about-universe__block">
                    {/*                <img src="assets/img/mainPage/oversize-logo.svg" alt="Logo" class="about-universe__back-logo">*/}
                    <div className="about-universe__row flex">

                        <div className="about-universe__model-wrap-outer">
                            <div className="about-universe__model-wrap flex">
                                <svg className="about-universe__back-logo" width={408} height={483}
                                     viewBox="0 0 408 483" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M232.98 26.8298L3.05011 0.687869C0.828242 0.435253 -0.584206 3.00148 0.817892 4.7435L130.727 166.148C131.195 166.729 131.153 167.569 130.607 168.079C119.492 178.47 91.0079 195.487 81.87 245.596C69.45 313.702 117.458 357.33 142.298 369.713L405.995 482.853C407.216 483.376 408.304 481.888 407.434 480.883L255.128 305.055C254.442 304.263 254.793 303.026 255.786 302.692C314.451 282.976 355.269 229.894 355.269 172.93C355.269 75.5328 279.635 31.8033 232.98 26.8298Z"
                                        fill="url(#paint0_radial_3625_5431)"/>
                                    <defs>
                                        <radialGradient id="paint0_radial_3625_5431" cx={0} cy={0}
                                                        r={1} gradientUnits="userSpaceOnUse"
                                                        gradientTransform="translate(373.354 484.392) rotate(-128.984) scale(673.012 791.872)">
                                            <stop stopColor="#C9F731"/>
                                            <stop offset={1} stopColor="#35CA94"/>
                                        </radialGradient>
                                    </defs>
                                </svg>
                                <div className="model">
                                    <Suspense fallback={null}>
                                        {/*  model  */}
                                        <model-viewer
                                            className="model-viewer"
                                            src={'/model/nadya1.gltf'}
                                            alt="Nadia"
                                            camera-controls
                                            auto-rotate
                                            shadow-intensity="1"
                                            camera-target="0m 1m 0m"
                                        >
                                        </model-viewer>
                                    </Suspense>
                                    {/*<model-viewer src={nadiaSrc} alt="Nadia" auto-rotate camera-controls ar-modes="webxr"></model-viewer>*/}
                                </div>
                                <div className="about-universe__model-intro flex">
                                    <div className="about-universe__model-desc flex">
                                        Nadia
                                    </div>
                                    <div className="model-text">
                                        Video director of Raritygram
                                    </div>
                                </div>
                                {/*<img src="img/mainPage/meta-universe/Ksenia-avatar.png" alt="Ksenia" className="about-universe__avatar"/>*/}
                            </div>
                        </div>
                        <div className="about-universe__content">
                            <div className="content">
                                <h2 className="section__title about-universe__title">
                                    Metaverse
                                </h2>
                                <div className="about-universe__desc">
                                    The Raritygram metaverse is the place which brings real world assets into the
                                    virtual space and develops them further. Here, users can create their own avatars,
                                    ID cards to express their identity, live a fulfilled social life and make use of any
                                    digital object created on the platform.<br/><br/>
                                    Take the opportunity to create a unique digital avatar that will look just like you!
                                    Get a chance to communicate with other users, express your emotions and express
                                    yourself in a way that you’ve always wanted to.
                                </div>
                                <Button
                                    icon={'img/mainPage/meta-universe/triangle.svg'}
                                    href={'https://www.youtube.com/watch?v=x3gF0R-0y1A'}
                                    className={'button_purple about-universe__button'}>
                                    Metaverse demo
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}