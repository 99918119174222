import './DevBlogNewsItem.scss';

export default function DevBlogNewsItem({date, title, desc}) {
    return (
        <li className="devblog-news__item news-item">
            <div className="news-item__inner">
                <div className="news-item__date item-date">
                    {date}
                </div>
                <h2 className="news-item__title">
                    {title}
                </h2>
                <div className="news-item__paragraph">
                    {desc}
                </div>
            </div>
        </li>
    );
}