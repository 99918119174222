import './Forest.scss';

export default function Forest() {
    return (
      <section className={'forest'}>
          <div className="container">
              <div className="forest__banner forest-banner flex">
                  <div className="forest-banner__content">
                      <img src="img/mintPage/green-ocean-logo.svg" alt="Green ocean"/>
                      <h2>GO help forest(F.E.)</h2>
                      <p>mint 2 NFTRarity and get 1 NFT Green ocean for free</p>
                  </div>
                  <img src="img/mintPage/go-cards.png" alt="Green ocean cards"/>
              </div>
          </div>
      </section>
    );
}