import './MainMint.scss';
import {useEffect, useState} from "react";
import Button from "../Button/Button";
import MainMintCard from "./MainMintCard/MainMintCard";
import HowToMintButton from "../HowToMintButton/HowToMintButton";
import cn from "classnames";
import {useMoralis, useMoralisWeb3Api} from "react-moralis";
import {contractABI, contractAddress} from "../../contract";
import {useRecoilState, useRecoilValue} from "recoil";
import {currentBalanceState} from "../../balance";
import Moralis from "moralis";
// import {useWeb3Enabled} from "../../hooks/useWeb3Enabled";
import {confirmedState} from "../../pages/MainPage/confirmedState";
import {getCurrentStage} from "../../data/stages";
import {nftCards} from "../../data/nfts";
import {amountNftsState} from "./MainMintCard/amountNftsState";
import Web3 from "web3"
import {checkChainId, leftTokensInCollection} from "../../functions";
import axios from "axios";
import Counter from "../Counter/Counter";
import MetamaskButton from "../MetamaskButton/MetamaskButton";
import Tofu from "../Tofu/Tofu";

const web3 = new Web3(Web3.givenProvider);
// const web3 = new Web3('https://bsc.getblock.io/mainnet/?api_key=8608ea79-3f89-45df-b4a5-648f2cd8e3ee');
// const maxTokens = 2800;

export default function MainMint() {

    const Web3Api = useMoralisWeb3Api();
    const {isAuthenticated, user, isWeb3Enabled, enableWeb3, chainId} = useMoralis();
    // const isWeb3Enabled = useWeb3Enabled();
    const [currentBalance, setCurrentBalance] = useRecoilState(currentBalanceState);
    const [loading, setLoading] = useState(false);
    const [isConfirmed, setIsConfirmed] = useRecoilState(confirmedState);
    const [mintCollectionId, setMintCollectionId] = useState(0);
    const [currentMintValue, setCurrentMintValue] = useState(nftCards[0].value);
    const amountNfts = useRecoilValue(amountNftsState)
    const [currentMint, setCurrentMint] = useState(null);
    const [totalMintedBnb, setTotalMintedBnb] = useState(null);
    const noMoney = (currentMintValue * amountNfts) > currentBalance;
    const [tokensLeft, setTokensLeft] = useState(null);
    const [stage, setStage] = useState(null);
    const [maxTokensBnb, setMaxTokensBnb] = useState(null);
    const [bnbLeft, setBnbLeft] = useState(null);
    const currentMintPercent = Math.floor((totalMintedBnb * 100) / maxTokensBnb);


    useEffect(() => {

        if (window.ethereum && window.ethereum.isMetaMask) {
            console.log('isMetaMask true')
        } else {
            console.log('isMetaMask nope')

        }
        getMintedResultFromApi("https://nft.raritygram.io/api")
        getBnbLeftFromApi("https://nft.raritygram.io/api/leftbnb")
    }, []);


    const getMintedResultFromApi = async (url) => {
        try {
            const res = await axios.get(url);
            const resultStr = res.data.toString();
            const resultArr = resultStr.split(' ');

            setTotalMintedBnb(Math.floor(Number(resultArr[0]) * 100) / 100)
            setCurrentMint(resultArr[1])
        } catch (e) {
            console.log(e);
        }
    };

    const getBnbLeftFromApi = async (url) => {
        try {
            const leftBnb = await axios.get(url);
            const leftBnbStr = leftBnb.data.toString();
            const leftBnbArr = leftBnbStr.split(' ');

            setStage(leftBnbArr[0])
            setBnbLeft(Math.floor(Number(leftBnbArr[1]) * 100) / 100)
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const f = async () => {
            if (!isWeb3Enabled) await enableWeb3()
            await getNativeBalance();
        }
        f();

        if (isWeb3Enabled) {

            getRemainingNfts()

            Promise.all([
                leftTokensInCollection(0),
                leftTokensInCollection(1),
                leftTokensInCollection(2),
                leftTokensInCollection(3)
            ]).then(tokensMintedArr => {
                const tokensLeft = tokensMintedArr.map((tokensMinted, index) => {
                    return nftCards[index].maxTokens - tokensMinted;
                });

                // setTotalMintedBnb(((tokensMintedArr[0] * 22.5) + (tokensMintedArr[1] * 2.25) + (tokensMintedArr[2] * 0.6) + (tokensMintedArr[3] * 0.225)).toFixed(1))
                setTokensLeft(tokensLeft)
            })
        }

    }, [isWeb3Enabled, chainId]);

    useEffect(() => {
        if (isWeb3Enabled) {
            const f = async () => {
                if (!isWeb3Enabled) await enableWeb3()
                await getNativeBalance();
                await getRemainingNfts()
            }
            f();
        }
    }, [amountNfts])

    useEffect(() => {
        const stage = getCurrentStage(totalMintedBnb)
        // setStage(stage.stage)
        setMaxTokensBnb(stage.maxBnb)
    }, [totalMintedBnb])

    useEffect(() => {
        setIsConfirmed(false);
    }, [mintCollectionId]);

    const onMainMintButton = async (e) => {
        e.preventDefault();
        try {
            const transaction = await mintNft(user.get("ethAddress"), amountNfts, mintCollectionId, currentMintValue);
            setLoading(true)
            const check = await checkTransaction(transaction)
            if (check.status) {
                console.log('transaction', check.transaction)
            } else {
                console.log('transaction', check.transaction)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    };

    const mintNft = async (address, amount, collectionId) => {
        return await Moralis.executeFunction({
            contractAddress: contractAddress,
            functionName: "mint",
            abi: contractABI,
            msgValue: currentMintValue * amount,
            params: {
                _to: address,
                _amount: amount,
                _collectionId: collectionId
            }
        });
    };

    const checkTransaction = async (transaction) => {
        const check = await transaction.wait(3);
        if (check.status) {
            setIsConfirmed(true);
            return {
                status: true,
                transaction: check.transactionHash
            }
        } else return {
            status: false,
            transaction: check.transactionHash
        }
    };

    // ______

    const getRemainingNfts = async () => {
        const contract = await new web3.eth.Contract(contractABI, contractAddress);
        const functions = await contract.methods
        const result = await functions.totalSupply().call()
        return setCurrentMint(result)
    }

    // const leftTokensInCollection = async (id) => {
    //     const contract = await new web3.eth.Contract(contractABI, contractAddress);
    //     const functions = await contract.methods
    //     return await functions.collectionSupply(id).call()
    // };

    // __________

    const getNativeBalance = async () => {
        await checkChainId()
        const balance = await Web3Api.account.getNativeBalance({chain: process.env.REACT_APP_PUBLIC_CHAIN});
        const balanceNumber = Number(balance.balance)
        setCurrentBalance(balanceNumber)
    };

    const mintList = tokensLeft && nftCards.map(({name, maxTokens, price, value}, index) => {
        const onMintCardClick = async () => {
            await getNativeBalance();
            setMintCollectionId(index);
            setCurrentMintValue(value);
        };
        return (
            <MainMintCard
                onClick={onMintCardClick}
                active={mintCollectionId === index}
                name={name}
                left={tokensLeft[index]}
                price={price}
                disabled={loading}
                key={name}
            />
        );
    });

    return (
        <div className={'main-mint__wrapper'}>
            <Button href={'https://tofunft.com/ru/collection/nft-rarity/items'} className={'tofu-button'}>
                <Tofu/>
            </Button>

            <div className={'main-mint'}>

                {/*<div className="main-mint__counter-wrap">*/}
                {/*    <div className="main-mint__counter_inner flex">*/}
                {/*        <Counter/>*/}
                {/*        <p>left until remaining NFTs will be burned</p>*/}
                {/*    </div>*/}
                {/*    */}
                {/*</div>*/}

                <div className="main-mint__title-wrap flex">
                    <h3 className="main-mint__title">Mint is closed</h3>
                    <div className="main-mint__notice">
                        Thanks to all the users who participated in the mint. Stay tuned!
                    </div>
                </div>

                <div className="main-mint__progress mint-progress">
                    <div className="mint-progress__stage">
                        Set {stage}
                    </div>

                    <div className="mint-progress__title flex">
                        Sold out
                        {/*{bnbLeft}*/}
                        {/*<img src="img/bnb.png" alt="Bnb"/>*/}
                        {/*left*/}
                    </div>

                    <div className="mint-progress__progress-bar-wrap">
                        <div className="mint-progress__bar">
                            <div style={{width: `100%`}} className="mint-progress__progress-line"/>
                            {/*<div style={{width: `${currentMintPercent}%`}} className="mint-progress__progress-line"/>*/}
                        </div>
                        <div className="mint-progress__numbers flex">
                            {/*<div className="mint-progress__max">{maxTokensBnb} BNB</div>*/}
                            <div style={{left: `100%`}} className="mint-progress__current-minted">
                                {/*<div style={{left: `${currentMintPercent}%`}} className="mint-progress__current-minted">*/}
                                600<br/>BNB
                                {/*{totalMintedBnb}<br/>BNB*/}
                            </div>
                        </div>
                    </div>
                </div>
                {isAuthenticated ? (
                    <>
                        <div className="mint-progress__cards-wrap">
                            <div className="mint-progress__cards mint-cards flex">
                                {mintList}
                            </div>
                        </div>

                        <div className="mint-progress__discount">WHITELIST DISCOUNT 14%</div>

                        {isConfirmed ? (
                            <Button
                                to={'/my-collection'}
                                className={cn('button_purple main-mint__mint-button', {
                                    'main-mint__mint-button_disabled': !isWeb3Enabled
                                })}
                                icon={'img/mainPage/check.svg'}
                            >
                                Confirmed
                            </Button>
                        ) : (
                            <Button
                                href={'#'}
                                className={cn('button_purple main-mint__mint-button main-mint__mint-button_disabled')}
                            >
                                Soon
                            </Button>

                            // <Button
                            //     href={'#'}
                            //     onClick={onMainMintButton}
                            //     className={cn('button_purple main-mint__mint-button', {
                            //         'main-mint__mint-button_disabled': noMoney || !isWeb3Enabled,
                            //         'main-mint__mint-button_pending': loading
                            //     })}
                            // >
                            //     {noMoney ? ('Not enough money, fill up your wallet') : (loading ? 'Pending' : 'Mint')}
                            //     {loading ? <div className={'pending-bar'}/> : null}
                            // </Button>
                        )}
                    </>
                ) : (
                    // <Button
                    //     to={'/connect'}
                    //     className={'button-connect'}
                    // >
                    //     <div className={'button-connect__inner'}>
                    //         Connect wallet
                    //     </div>
                    // </Button>
                    <MetamaskButton/>
                )}

                <HowToMintButton/>
            </div>
        </div>

    );
}