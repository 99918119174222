import './App.scss';
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import {RecoilRoot} from "recoil";
import MainPage from "./pages/MainPage/MainPage";
import MintPage from "./pages/MintPage/MintPage";
import DevBlogPage from "./pages/DevBlogPage/DevBlogPage";
import ArticlePage from "./pages/ArticlePage/ArticlePage";
import Header from "./components/layouts/Header/Header";
import Footer from "./components/layouts/Footer/Footer";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import OpportunitiesPage from "./pages/OpportunitiesPage/OpportunitiesPage";
import MyCollection from "./pages/MyCollection/MyCollection";
import {useMoralis} from "react-moralis"
import {useEffect} from "react";
import {checkChainId} from "./functions";
import ConnectPage from "./pages/ConnectPage/ConnectPage";

function App() {

    const {isAuthenticated, chainId, enableWeb3, isWeb3Enabled} = useMoralis();

    useEffect(()=> {
        enableWeb3();
    },[])

    useEffect(()=> {
        if (isWeb3Enabled) {
        checkChainId()
        }
    },[chainId, isWeb3Enabled])

    return (
        <BrowserRouter>
            <RecoilRoot>
                <ScrollToTop/>
                <Header/>
                <Routes>
                    <Route exact path="/" element={<MainPage/>}/>
                    <Route path="/connect" element={<ConnectPage/>}/>
                    <Route path="/mint" element={<MintPage/>}/>
                    {isAuthenticated ?
                        <Route path="/my-collection" element={<MyCollection/>}/> :
                        <Route path={'/my-collection'} element={<Navigate to={'/'}/>}/>}
                    <Route path="/blog/:name" element={<DevBlogPage/>}/>
                    <Route path="/devblog/:name" element={<ArticlePage/>}/>
                    <Route path="/services/:name" element={<OpportunitiesPage/>}/>
                    <Route path="*" element={<ErrorPage/>}/>
                </Routes>
                <Footer/>
            </RecoilRoot>
        </BrowserRouter>
    );
}

export default App;