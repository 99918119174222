import './Token.scss';

export default function Token() {

    return(
        <section id={'token'} className="token-section section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="token-section__content content">
                            <div className="token-section__features content flex">
                                <div className="token-section__img">
                                    <img src="img/mainPage/token/rari-token.svg" alt="Raritygram token"/>
                                </div>
                                <div className="token-section__content-wrap">
                                    <h2 className="token-section__title section__title">Raritygram token</h2>
                                    <p>
                                        An essential part of our ecosystem, the Rarity token gives users a variety of opportunities for personal use.
                                    </p>
                                    <ul className="check-items">
                                        <li>
                                            <div className="check-item">
                                                Incorporated into the Raritygram internal payment system
                                            </div>
                                        </li>
                                        <li>
                                            <div className="check-item">
                                                Used for exchange operations
                                            </div>
                                        </li>
                                        <li>
                                            <div className="check-item">
                                                Used to pay for commissions and expenses on the platform and beyond
                                            </div>
                                        </li>
                                        <li>
                                            <div className="check-item">
                                                Essential in the process of transferring ownership of NFTs
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}